import React, { useContext, useEffect, useState, useRef } from 'react';

import assignmentContext from '../contexts/assignment-context';

import {ReactComponent as VerticalBar} from '../image/verticalBar.svg';

import UserList from './UserList';
import CheckBoxButton from './CheckBoxButton';

import axios from 'axios';

import { BACKEND } from '../../../../Constants';

// react-bootstrap
import { Spinner } from 'react-bootstrap';
// ClickEventHandler
import { onLineClickHandler, onButtonClickHandler } from './OnClickHandler';

const AssigmentList = (props) => {
  const observerTarget = useRef(null);
  const assignCtx = useContext(assignmentContext);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  // const [lineColor, setLineColor] = useState([]);
  const [waiting, setWaiting] = useState(false);
  const [completion, setCompletion] = useState(false);
  const [fetchedLineNumber, setFetchedLineNumber] = useState(0);

  const selectedColor = 'rgba(85, 85, 85, 0.2)';
  
  const getAssignmentInfo = (project_no, filename) => {
    axios.get(`${BACKEND.SERVER}/assignment/${project_no}/${filename}/fetching-${fetchedLineNumber}`
    ).then((response) => {
      setWaiting(true);
      if (response.data) {
        const payload = response.data;
        assignCtx.extendItem(payload);
        setFetchedLineNumber(fetchedLineNumber + payload.assignment.length);
        setWaiting(false);
      } else {
        setCompletion(true);
      }
    })
  }

  const eliminateStyleTag = (text) => {
    return text.replace(/<[^>]+>/g, '').replace(/(\r\n|\n|\r)/gm, '').replace(/&nbsp;/g, ' ')
  };

  // in case of line click
  const onLineClick = (event, key) => {
    const tooltipShow = onLineClickHandler(event, key, assignCtx);
    setIsButtonClicked(tooltipShow); 
  };
  const onButtonClick = (event, key) => {
    const tooltipShow = onButtonClickHandler(event, key, assignCtx);
    setIsButtonClicked(tooltipShow);
  };

  const onIntersection = ([entry], observer) => {
    if (!waiting && entry.isIntersecting && props.project_no) {
      setWaiting(true);
      !completion && getAssignmentInfo(props.project_no, props.filename)
    }
  };

  useEffect(() => {
    setWaiting(false);
    setCompletion(false);
    if (props.project_no) {
      assignCtx.initItems();
    }
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection, {threshold:0.5})
    observerTarget.current && observer.observe(observerTarget.current);

    return () => observer && observer.disconnect();
  }, [onIntersection])


  // console.log(waiting)
  // console.log(fetchedLineNumber)
  // console.log(assignCtx)

  const SetList = ((props) => {
    let sequence = 1;
    return (
      <tbody>
        {props.items.length !== 0 && props.items.map((item, index) => 
          (item.sentence !== '<p><br></p>' && item.sentence != '') &&    
          <React.Fragment key = {index}>
            <tr 
              style={{
                'backgroundColor': assignCtx.selected[index] === 0 
                  ? null
                  : selectedColor}
                }
              onClick={(event) => (props.onLineClick(event, index))}
            >
              <td>
                <CheckBoxButton 
                  name = 'clickableButton'
                  number = {index}
                  selected = {assignCtx.selected[index]}
                  onButtonClick = {(event) => {
                    props.onButtonClick(event, index)}}
                  isButtonClicked = {isButtonClicked}
                />
              </td>
              <td name = 'clickableTD'>
                {sequence++}
              </td>
              <td name = 'clickableTD'>
                {eliminateStyleTag(item.sentence)}
              </td>
              <td 
               name = 'clickableTD'
               className='p-0'>
                {/* <bar
                  style = {{
                    'backgroundColor': 
                      assignCtx.teamMembers.find(
                      member => member.no == item.assignUser.no
                      ).color,
                    'opacity':0.8,
                    'padding':'0.1rem'}}>
                  
                </bar> */}
                <VerticalBar 
                  width={30}
                  height={60}
                  viewBox='0 0 20 20'
                  fill = {assignCtx.teamMembers.find(
                    member => member.no == item.assignUser.no
                    ).color}
                  
                />
              </td>
              <td name = 'clickableTD'>
                <UserList 
                  lineIndex = {index}
                  assignUser = {item.assignUser}
                  filename = {props.filename}
                />
              </td>
            </tr>
          </React.Fragment>
          )}
          {!completion && <tr ref = {observerTarget}>
            <td colSpan='5' className="text-center">
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>}
      </tbody>
    )
  });
  
  return (
    <SetList 
      items = {assignCtx.items}
      onLineClick = {onLineClick}
      onButtonClick = {onButtonClick}
      filename = {props.filename}
    />
  )
};

export default AssigmentList;


