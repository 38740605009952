import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BACKEND } from "../../Constants";

import LetteredAvatar from 'react-lettered-avatar';
import AuthContext from "../../store/auth-context";

import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Media,
    Row,
    UncontrolledDropdown
} from "reactstrap";

import {
    Heart, MessageSquare, MinusCircle, Smile,
    Briefcase,
    Home,
    MapPin,
    MoreHorizontal
} from "react-feather";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
    faFacebook,
    faInstagram,
    faLinkedin,
    faTwitter
} from "@fortawesome/free-brands-svg-icons";

import avatar1 from "../../assets/img/avatars/avatar.jpg";
import avatar2 from "../../assets/img/avatars/avatar-2.jpg";
import avatar4 from "../../assets/img/avatars/avatar-4.jpg";
import avatar5 from "../../assets/img/avatars/avatar-5.jpg";

import unsplash1 from "../../assets/img/photos/unsplash-1.jpg";
import unsplash2 from "../../assets/img/photos/unsplash-2.jpg";

const Activities = ({ token, username, notifications, setNotifications, updateNotifications }) => {

    const [hide, setHide] = useState(false);

    const handleMore = (past) => {
        const data = {
            member: username,
            past: past,
        };
        axios
            .post(BACKEND.SERVER + "/notification", data, {
                headers: {
                    "Authorization": `JWT ${token}`
                }
            })
            .then((res) => {
                if (res.statusText !== "OK") {
                    alert(res.data.message);
                } else if (res.statusText === "OK") {
                    setNotifications((prev) => [...prev, ...res.data])
                    updateNotifications();
                    if (res.data.length < 10)
                        setHide(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });

    };
    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5" className="mb-0">
                    내가 받은 댓글 및 추천 내역
      </CardTitle>
            </CardHeader>
            <CardBody>
                {notifications && notifications.map((item, key) => {
                    let icon = <MessageSquare size={36} className="text-success pr-2" />;
                    let title = '댓글 알림';
                    let type = '댓글을 쓰셨습니다.';
                    if (item.comment == 'Love') {
                        icon = <Heart size={36} className="text-danger pr-2" />;
                        title = '추천 알림';
                        type = '추천을 하셨습니다.';
                    } else if (item.comment == 'kick out') {
                        icon = <MinusCircle size={36} className="text-danger pr-2" />;
                        title = '프로젝트 퇴장';
                        type = "님이 회원님을 프로젝트에서 내보냈습니다.";
                    } else if (item.comment == 'invite') {
                        icon = <Smile size={36} className="text-success pr-2" />;
                        title = '프로젝트 초대';
                        type = "님이 회원님을 프로젝트에 초대했습니다."
                    }
                    else if (item.thread !== 'A')
                        icon = <MessageSquare size={36} className="text-info pr-2" />;
                    return (
                        <React.Fragment key={key}><Media>
                            {icon}
                            <Media body>
                                <small className="float-right text-navy">{item.created_datetime}</small>
                                {(item.thread !== 'A' && item.thread !== '0' && item.thread !== 'pjt' && item.thread !== 'dlt') && (item.member_name + "님이 회원님의 댓글에 댓글을 쓰셨습니다.")}
                                {(item.thread === 'A' || item.thread === '0') && (item.member_name + "님이 회원님의 글에 " + type)}
                                {item.thread === 'pjt' && (item.member_name + type)}
                                {item.thread === 'dlt' && (item.member_name + type)}
                                <br />
                                <small className="text-muted">
                                    {item.ref == 0 && <Badge color="success" className="mr-1 my-1">
                                        new
      </Badge>}
                                    {item.ref == 1 && "(" + item.read_datetime + " 확인)"}</small>
                                {" "}{item.thread !== 'dlt' && item.thread !== 'pjt' && <small className="text-muted"><Link to={`/support/${item.query}`}>바로 가기</Link></small>}
                                {" "}{item.thread === 'pjt' && <small className="text-muted"><Link to={`/projects/edit/${item.query}`}>바로 가기</Link></small>}
                                <br />
                            </Media>
                        </Media>

                            <hr />
                        </React.Fragment>)
                })}
                {!hide && <Button color="primary" block onClick={() => { handleMore(notifications.length) }}>
                    더 보기
      </Button>}
            </CardBody>
        </Card>
    );
}

const Notification = () => {
    const [notifications, setNotifications] = useState([]);
    const authCtx = useContext(AuthContext);
    const token = authCtx.token;
    const username = authCtx.useremail;
    const socket = authCtx.socket;

    const updateNotifications = () => {
        axios
            .post(BACKEND.SERVER + "/notifications", data, {
                headers: {
                    "Authorization": `JWT ${token}`
                }
            })
            .then((res) => {
                if (res.statusText !== "OK") {
                    alert(res.data.message);
                } else if (res.statusText === "OK") {
                    authCtx.setNotifications(res.data);
                    localStorage.setItem("notifications", JSON.stringify(res.data));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    const data = {
        member: username
    };

    useEffect(() => {
        axios
            .post(BACKEND.SERVER + "/notification", data, {
                headers: {
                    "Authorization": `JWT ${token}`
                }
            })
            .then((res) => {
                if (res.statusText !== "OK") {
                    alert(res.data.message);
                } else if (res.statusText === "OK") {
                    setNotifications(res.data);
                    updateNotifications();
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }, [])

    useEffect(() => {
        if (socket) {
            socket.on("getAlert", (data) => {
                //  console.log(data);
                setNotifications((prev) => [data, ...prev])
                updateNotifications();
            });
        }
    }, [socket]);

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">알림</h1>
            <Row>
                <Col md="8" xl="9">
                    <Activities token={token} username={username} notifications={notifications} setNotifications={setNotifications} updateNotifications={updateNotifications} />
                </Col>
            </Row>
        </Container>
    )
};

export default Notification;
