import React, { useContext } from 'react';
// import { useSelector } from 'react-redux';

import EditorDataContext from '../../contexts/EditorDataContext';

import { setDiffTextStyle } from '../TMCheckList'
// react-bootstrap
import {Table, Button, Modal} from 'react-bootstrap';

const TmxMatchList = (props) => {
  const edCtx = useContext(EditorDataContext);
  const tmxMatch = edCtx.tmxMatch[props.index]
  // const tmxMatch = 
  //   useSelector((state) => state.editorData.tmxMatch)[props.index]


  return (
    <Table striped bordered hover className = 'm-0'>
      <thead>
        <tr>
          <th className='p-1'>#</th>
          <th className='p-1'>원문</th>
          <th className='p-1'>번역문</th>
        </tr>
      </thead>
      <tbody>
        {tmxMatch && tmxMatch.map((item, index) => (
          <tr
            id = {index}
            key = {index}
          >
            <td className='p-1'>
              {index}
            </td>
            <td className='b-0 p-1 align-top'>
              {setDiffTextStyle(item.diff_text[0])}
            </td>
            <td className='b-0 p-1 align-top'>
              {item.tmx_target[0]}
            </td>

          </tr>
        ))}

      </tbody>
    </Table>
  )
};

export default TmxMatchList;