import React from "react";

import axios from "axios";

// react-bootstrap
import { Button } from 'react-bootstrap';

// file downloader
import fileDownload from "js-file-download";
//Wrapper
// import Wrapper from '../helpers/Wrapper'

import { BACKEND } from "../../../Constants";

const Download = (props) => {
    const baseURL = `${BACKEND.SERVER}/download/`
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const owner_id = userInfo ? userInfo.userUid : 'nonMember';

    const fileNameExt = props.file.split('.').length !== 1
        ? props.file.split('.')
        : `${props.file}.`.split('.');
    const ext = fileNameExt.pop();

    const onClickHandler = async (event) => {
        await props.save(event, ext
        ).then(() => {
            axios.get(
                `${baseURL}${props.project_no}/${owner_id}/${props.file}`,
                { responseType: 'blob' }
            ).then((response) => {
                if (response.status === 200) {
                    if (['tmx', 'xlsx'].includes(ext)) {
                        fileDownload(response.data, `${props.file}`)
                    }
                    else if (ext === 'pdf') {
                        fileDownload(response.data, `${props.file}-translated.docx`)
                    }
                    else {
                        fileDownload(response.data, `${props.file}-translated.${ext}`)
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
        })
        // axios.get(
        //     `${baseURL}${props.project_no}/${owner_id}/${props.file}`,
        //     {responseType: 'blob'}
        //     ).then((response) => {
        //         if (response.status === 200) {
        //             fileDownload(response.data, `${props.file}-translated.${ext}`)
        //         } else {
        //             props.save(event, onClickHandler)
        //         }
        //     }).catch((error) => {
        //         console.log(error)
        //     })
    }

    return (
        <Button
            children='파일 다운로드'
            type='button'
            variant="primary"
            size='sm'
            className='m-1'
            // style = {{backgroundColor: 'gainsboro'}}
            disabled={props.disabled}

            onClick={onClickHandler}
        />
    )

    // return (
    //     <a href={`${baseURL}${props.project_no}/${owner_id}/${props.file}`} download >
    //         <Button
    //             children = '파일 다운로드'
    //             type = 'button'
    //             variant = 'light'
    //             size = 'sm'
    //         />
    //     </a>
    // )
};

export default Download;