import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Footer = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    return (
        <footer className="footer">
            <Container className="footer__container" fluid>
                <Row className="text-muted ps-20 footer__top">
                    <Col xs="6">
                        <div className="" >
                            <h3>질문이 있으신가요?</h3>
                            <h2>1533-6880</h2>
                            <div>
                                토, 일요일/공휴일 휴무<br />
                            오전 09시 ~ 오후 5시 (점심 11시 30분 ~ 12시 30분)
                        </div>
                        </div>
                    </Col>
                    <Col xs={isMobile ? "3" : "2"}>
                        <b className="p__title" >플랫폼소개</b>
                        <Link to="/docs/policy" style={{ textDecorationLine: "none" }}>
                            <p className="p__link text-muted m-1">
                                이용약관
                        </p>
                        </Link>
                        <Link to="/docs/personal" style={{ textDecorationLine: "none" }}>
                            <p className="p__link text-muted m-1">
                                개인정보처리방침
                        </p>
                        </Link>
                    </Col>
                    <Col xs={isMobile ? "3" : "2"}>
                        <b className="p__title" >고객센터</b>
                        <Link to="/docs/introduction" style={{ textDecorationLine: "none" }}>
                            <p className="p__link text-muted m-1">
                                도움말
                            </p>
                        </Link>
                        <Link to={
                            {
                                pathname: '/chats',
                                member_name: "씽캣",
                                member_email: "thinkcat@thinkcat.tools"
                            }}
                            style={{ textDecorationLine: "none" }}>
                            <p className="p__link text-muted m-1">
                                1:1 문의
                            </p>
                        </Link>
                        <Link to="/support"
                            style={{ textDecorationLine: "none" }}>
                            <p className="p__link text-muted m-1">
                                게시판
                            </p>
                        </Link>
                    </Col>
                </Row>
                <Row className="text-muted footer__bottom">
                    <Col xs="12" className="text-left">
                        <hr />
                        <div className="footer__copy" style={{ fontColor: "lightgray" }}>
                            사업자등록번호: 120-86-49219 / 통신판매업신고 : 제 2022-서울구로-1016호
                        <br /> (주)애니파이브 / 대표이사: 김기종 / 서울특별시 구로구 디지털로 288 1603호(대륭포스트타워1차) / 대표번호: 1533-6880 / 이메일:  thinkcat@thinkcat.tools
                        {/* <br />Copyright ⓒ ANYFIVE. All Rights Reserved. */}
                            <br />&copy; {new Date().getFullYear()} -{" "}
                            <span href="/" className="text-muted">
                                thinkcat.tools by ANYFIVE
                        </span>
                        </div>

                    </Col>
                </Row>
            </Container >
        </footer>
    )
};

export default Footer;
