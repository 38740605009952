import React from 'react';

import { useParams } from 'react-router-dom';
import { Card, Table } from 'react-bootstrap'
// context
import AssignmentProvider from '../contexts/AssignmentProvider';
// part
import AssigmentList from './Assignment.List';
import Header from './Header';
// image
import {ReactComponent as CheckmarkImage} from '../image/checkmark.svg';

const AssignmentPage = (props) => {
  const { project_no, filename } = useParams();
  const AssignmentTable = (props) => (
    <Card style={{userSelect:'none'}}>
      <Header 
        project_no = {props.proejct_no}
        filename = {props.filename}
      />
      <Table striped hover draggable = 'false'>
        <thead>
          <tr>
            <th>
              <CheckmarkImage 
                width='18'
                height = '18'
                viewBox='0 0 20 30'
                fill='#000000'
                />
            </th>
            <th>#</th>
            <th>문장</th>
            <th></th>
            <th>할당</th>
          </tr>
        </thead>
        <AssigmentList
          project_no = {props.project_no}
          filename = {props.filename}
        />
      </Table>
    </Card>
  );

  return (
    <React.Fragment>
      <AssignmentProvider>
        <AssignmentTable
          project_no = {project_no}
          filename = {filename}
        />
      </AssignmentProvider>
    </React.Fragment>
  )
};

export default AssignmentPage;