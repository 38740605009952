import React, { useRef, useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import { BACKEND } from "../../Constants";

import AuthContext from "../../store/auth-context";
import CommentList from "./CommentList";

import { Input, Button, Container, CardBody, CardHeader, Row, Col, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faCaretDown, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons"; //"@fortawesome/free-regular-svg-icons"; 

import { Edit2, Trash } from "react-feather";
import utils from '../projects/DocumentTab/utils/functions';
import fileDownload from 'file-saver';

function createRoomName(id1, id2) {
    if (id1 > id2) {
        // swap two values
        let temp = id2;
        id2 = id1;
        id1 = temp;
    }
    return id1 + '|' + id2;
}

const Read = ({ history }) => {
    const params = useParams();
    const query = params.query;
    const inputRef = useRef();

    const authCtx = useContext(AuthContext);
    const socket = authCtx.socket;
    const isLoggedIn = authCtx.isLoggedIn;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    console.log("userInfo", userInfo)
    const thename = isLoggedIn ? userInfo.userName : null;
    const theemail = isLoggedIn ? userInfo.userEmail : null;
    const token = authCtx.token;

    const [title, setTitle] = useState("");
    const [comment, setComment] = useState("");
    const [commentList, setCommentList] = useState([]);
    const [isError, setError] = useState(false);


    const commentChange = (data) => {
        if (!isLoggedIn) alert('먼저 로그인해주세요!');
        else setComment(data)
    };

    const handleLove = () => {
        let room = null;
        if (title.member_email == theemail) {
            alert("자신의 글은 추천할 수 없습니다.");
        } else {
            room = createRoomName(title.member_email, theemail);
            const data = {
                "no": query,
            };
            Axios
                .post(BACKEND.SERVER + "/love", data, {
                    headers: {
                        "Authorization": `JWT ${token}`
                    }
                })
                .then((res) => {
                    setComment("");
                    if (res.statusText !== "OK") {
                        alert(res.data.message);
                    } else if (res.statusText === "OK") {
                        setTitle(prevState => ({
                            ...prevState,
                            loves: res.data
                        }));
                        alert("추천됐습니다!");
                        var date = new Date();
                        date = date.getFullYear() + '-' +
                            ('00' + (date.getMonth() + 1)).slice(-2) + '-' +
                            ('00' + date.getDate()).slice(-2) + ' ' +
                            ('00' + date.getHours()).slice(-2) + ':' +
                            ('00' + date.getMinutes()).slice(-2) + ':' +
                            ('00' + date.getSeconds()).slice(-2);
                        let newcomment = {
                            "member_email": theemail,
                            "member_name": thename,
                            "comment": 'Love',
                            "thread": 0,
                            "ref": 0,
                            "query": query,
                            "created_datetime": date,
                            "room": room,
                        }
                        socket.emit("send_comment", newcomment);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let room = null;
        if (title.member_email != theemail) {
            room = createRoomName(title.member_email, theemail);
        }

        var the_comment = comment.trim();
        if (the_comment === "") {
            setError(true);
            alert('내용을 입력하세요');
            inputRef.current.focus();
        }
        else {
            // console.log(editor.current.getContents())
            const data = {
                "comment": comment,
                "official_number": title.official_number,
            };

            Axios
                .post(BACKEND.SERVER + "/comment/" + query, data, {
                    headers: {
                        "Authorization": `JWT ${token}`
                    }
                })
                .then((res) => {
                    setComment("");
                    if (res.statusText !== "OK") {
                        alert(res.data.message);
                    } else if (res.statusText === "OK") {
                        var added = res.data;
                        var date = new Date();
                        date = date.getFullYear() + '-' +
                            ('00' + (date.getMonth() + 1)).slice(-2) + '-' +
                            ('00' + date.getDate()).slice(-2) + ' ' +
                            ('00' + date.getHours()).slice(-2) + ':' +
                            ('00' + date.getMinutes()).slice(-2) + ':' +
                            ('00' + date.getSeconds()).slice(-2);
                        let newcomment = {
                            "member_email": theemail,
                            "member_name": thename,
                            "comment": added.comment,
                            "thread": 'A',
                            "ref": 0,
                            "no": added.no,
                            "fid": added.fid,
                            "pid": added.pid,
                            "query": query,
                            "created_datetime": date,
                            "room": room,
                        }
                        setCommentList(commentList => [...commentList, newcomment]);
                        if (title.member_email != theemail) {
                            socket.emit("send_comment", newcomment);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };


    useEffect(() => {

        Axios.get(BACKEND.SERVER + "/support/" + query).then((response) => {
            const gets = response.data;
            setTitle(gets);
        });

        Axios.get(BACKEND.SERVER + "/comments/" + query).then((response) => {
            const gets = response.data;
            if (gets[0].total_records > 0)
                setCommentList(gets);
        });
    }, [query]);

    const form_Classes = isError ? "form-control invalid" : "form-control";

    const stylep = { cursor: "pointer" };

    const [isOpenFile, setIsOpenFile] = useState(false);
    const openFileList = () => {
        setIsOpenFile(!isOpenFile)
    }


    const DownloadFile = async (event, no, filename) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("no", no);
        formData.append("filename", filename)

        let url = "/download-file"
        // return receive(
        //     client(
        //         url,
        //         {
        //             method: 'POST',
        //             responseType: 'blob',
        //         }
        //     )
        // )
        Axios.post(BACKEND.SERVER + url, formData, {
            headers: {
                "Authorization": `JWT ${token}`
            },
        })
            .then(res => {
                const blob = new Blob([res.data])
                const fileUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank';
                // link.style.display = 'none';

                // const injectFilename = (res: ApiResponse) => {
                //     const disposition = res.headers['content-disposition']; // 이름 담아오기
                //     const fileName = decodeURI(
                //         disposition
                //             .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
                //             .replace(/['"]/g, '')
                //     );
                //     return fileName;
                // };

                // link.download = injectFilename(res);
                link.download = filename

                document.body.appendChild(link);
                link.click();
                link.remove();

                // window.URL.revokeObjectURL(fileUrl)
            })
            .catch(err => {
                console.log(err.data)
            })
    }
    // const LoadFile = (event, file_name) => {
    //     let reader = new FileReader();

    //     reader.readAsText(file_name) // 읽을 파일 넣기

    //     reader.onload = () => {
    //         // onload는 비동기이므로 주의 필요
    //         console.log(reader.result)
    //     }
    // }
    // const SaveFile = async (event, filename, filepath) => {
    //     const res = await CustomFetch('/fileDownload', {
    //         responseType: 'blob',
    //         method: `POST`, headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     }, {
    //         "_id": noticeId,
    //         filename,
    //         filepath
    //     });
    //     fileDownload(await (await new Response(res.body)).blob(), filename);

    // }
    // const SaveFileToZip = (event, file) => {
    //     let zip = new JSZip();

    //     attachFile.forEach(element => {
    //         zip.file(element.name, element, { binary: true })
    //     })
    // }


    return (
        <Container fluid className="p-0">
            <h1 className="h3 mt-3 mb-4 text-center">{title.title}</h1>

            <Card>
                <CardHeader>
                    {/* <span className="float-right"><Link to={`/manages/${title.member_no}`}>{title.title}</Link></span> */}
                </CardHeader>
                <CardBody className="m-sm-0 m-md-0">
                    <Row>
                        <Col md="3">
                            <div className="text-muted">글쓴이</div>
                            <strong>{title.member_name}</strong>
                        </Col>
                        <Col md="3" className="text-md-right">
                            <div className="text-muted">날짜</div>
                            <strong>{title.created_datetime}</strong>
                        </Col>
                        <Col md="3" className="text-md-right">
                            <div className="text-muted">추천/조회</div>
                            <strong>{title.loves}/{title.ref}</strong>
                        </Col>
                        <Col md="3">
                            <Edit2 style={stylep} className="mr-4 float-right align-middle" size={18}
                                onClick={() => {
                                    if (!isLoggedIn) alert('먼저 로그인해주세요!');
                                    else if (title.member_email == theemail) {
                                        history.push("/form/edit/" + query);
                                    } else {
                                        alert("자신이 글만 수정할 수 있습니다");
                                    }
                                }} />
                            <Trash style={stylep} className="mr-4 float-right align-middle" size={18}
                                onClick={() => {
                                    if (!isLoggedIn) alert('먼저 로그인해주세요!');
                                    else if (title.member_email == theemail) {
                                        history.push("/form/delete/" + query);
                                    } else {
                                        alert("자신이 글만 삭제할 수 있습니다");
                                    }
                                }} />
                        </Col>
                    </Row>

                    <hr className="my-4" />
                    <div className="float-right mr-3">
                        <div onClick={openFileList} style={theemail === "thinkcat@thinkcat.tools" && title && title.filename ? { cursor: "pointer", position: "relative" } : { curosr: "default" }}>
                            {theemail === "thinkcat@thinkcat.tools" && title && title.filename && <FontAwesomeIcon icon={faLockOpen} />}
                            &nbsp;<span className="reviewAddDesc">첨부파일</span>&nbsp;
                            {theemail === "thinkcat@thinkcat.tools" ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faLock} />}
                            {isOpenFile && theemail === "thinkcat@thinkcat.tools" && title && title.filename &&
                                // <div style={{ backgroundColor: "white", position: "absolute", right: "10px", padding: "5px" }}>
                                //     <form onSubmit={(e) => DownloadFile(e, title.no, title.filename)}>
                                //         <input type="hidden" value={title.no} />
                                //         <input type="hidden" value={title.filename} />
                                //         <button type="submit" style={{ backgroundColor: "transparent", border: "none" }}>{title.filename}&nbsp;({utils.humanFileSize(title.filesize)})</button>
                                //     </form>
                                // </div>
                                <div onClick={(e) => DownloadFile(e, title.no, title.filename)} style={{ backgroundColor: "white", position: "absolute", right: "10px", padding: "5px" }}>
                                    {title.filename}&nbsp;({utils.humanFileSize(title.filesize)}
                                </div>
                            }

                        </div>
                    </div>
                    <blockquote>
                        <div dangerouslySetInnerHTML={{ __html: title.comment }} />
                    </blockquote>
                </CardBody>
            </Card>
            {/* <div>
                <small className="mb-2">*.docx, *,doc, *.pdf, *.ppt, *.srt, *.smi, *.txt, *.png 파일만 지원됩니다.</small>
            </div> */}
            <div className="text-center">
                <Button onClick={handleLove} size="sm" color="danger" className="mb-4">
                    <FontAwesomeIcon icon={faHeart} fixedWidth /> 좋아요
          </Button></div>

            <Row>
                <Col lg="12">
                    {commentList.length > 0 && <CommentList setCommentList={setCommentList} list={commentList} query={query} />}
                    <Card>
                        <CardBody>
                            <Input value={comment} type="textarea" key="editor1" className={form_Classes} ref={inputRef} name="comment1"
                                placeholder="댓글을 입력하세요."
                                onChange={(e) => {
                                    if (!isLoggedIn) alert('먼저 로그인해주세요!');
                                    else {
                                        // console.log(commentList);
                                        setComment(e.target.value)
                                    }
                                }}
                                autoFocus
                            />
                            <Button type="submit" color="primary" className="mr-2 mt-2 float-right"
                                onClick={handleSubmit}
                            >
                                등록
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>


        </Container>
    );
};

export default Read;
