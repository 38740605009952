import React from "react";

import { Button, Card, CardBody, CardHeader, Container, Table } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";

// import avatar3 from "../../assets/img/avatars/avatar-3.jpg";

import { tableData, tableColumns } from "./glossaries.js";

// const ProfileDetails = () => (
//     <Card>
//         <CardHeader>
//             <Button color="primary" className="float-left mt-n1">
//                 <FontAwesomeIcon icon={faPlus} /> 용어집 생성
//             </Button>

//             {/* <CardTitle tag="h5" className="mb-0">
//                 Profile Details
//             </CardTitle> */}
//         </CardHeader>

//         <CardBody>
//             <Table className="mb-0">
//                 <thead>
//                     <tr>
//                         <th> </th>
//                         <th>이름</th>
//                         <th>언어</th>
//                         <th>용어 개수</th>
//                         <th>메모</th>
//                         <th>최근 수정일</th>
//                         <th>최근 수정자</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     <tr>
//                         <td>
//                             <img src={avatar3} width="32" height="32" className="rounded-circle my-n1" alt="Avatar" />
//                         </td>
//                         <td>Pandemic</td>
//                         <td>영어, 한국어</td>
//                         <td>5</td>
//                         <td>COVID-19 관련 문서에서 만들어진 용어집입니다.</td>
//                         <td>2021-07-25</td>
//                         <td>오시연</td>
//                     </tr>
//                 </tbody>
//             </Table>
//         </CardBody>
//     </Card>
// );
const RowSelectionTable = () => {
    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        bgColor: "#f8f9fa",
    };

    return (
        <Card>
            <CardHeader>
                <Button color="primary" className="float-left mt-n1">
                    <FontAwesomeIcon icon={faPlus} /> 용어집 생성
                </Button>
                <FontAwesomeIcon icon={faTrash} pull="right" size="lg" />
                <FontAwesomeIcon icon={faPen} pull="right" size="lg" transform="left-6" />
            </CardHeader>
            <CardBody>
                <BootstrapTable
                    bootstrap4
                    bordered={false}
                    keyField="name"
                    data={tableData}
                    columns={tableColumns}
                    selectRow={selectRow}
                />
            </CardBody>
        </Card>
    );
};

const Profile = () => (
    <Container fluid className="p-0">
        <h1 className="h3 mb-3">용어집</h1>

        <RowSelectionTable />
        {/* <ProfileDetails />
        <Row>
            <Col md="4" xl="3"></Col>
            <Col md="8" xl="9">
                <Activities />
            </Col>
        </Row> */}
    </Container>
);

export default Profile;
