import React from "react";

const GlossaryContext = React.createContext({
    // index: 0, //번호
    // file: // 파일 오브젝트
    // filename: '', //파일이름
    // uploadDate: '', // 업로드일
    
    items: [],
    searchKeyword: '',

    setItems: (items) => {},
    addItem: (item) => {},
    removeItem: (index) => {},
    search: (keyword) => {},
    
    
})

export default GlossaryContext;