
// in case of line click
const onLineClickHandler = (event, key, assignCtx) => {
  const selectedItem = assignCtx.selected;
  if (event.target.getAttribute('name') === 'clickableTD') {
    if (event.ctrlKey) {
      const selected = selectedItem[key]
      selectedItem.fill(
        selected === 1 ?0 :1, key, key+1);
      assignCtx.selectItem(selectedItem);
    }
    else if (event.shiftKey) {
      const minSelectedKey = selectedItem.findIndex(
        item => item === 1
      )
      const maxSelectedKey = selectedItem.lastIndexOf(1);

      const validKeys = assignCtx.items.map((item, index) =>  
        item.sentence !== '<p><br></p>' ? item.index : ''
        ).filter(String)
      var selectedKeys = []
      if (minSelectedKey < key && key < maxSelectedKey) {
        selectedKeys = validKeys.filter(value => minSelectedKey <= value && value <= maxSelectedKey)
      }
      else if (key >= maxSelectedKey) {
        selectedKeys = validKeys.filter(value => minSelectedKey <= value && value <= key)
      } else {
        selectedKeys = validKeys.filter(value => maxSelectedKey >= value && value >= key)
      } 

      selectedKeys.map((value, index) => 
        selectedItem.fill(1, value, value+1))
      assignCtx.selectItem(selectedItem);
    } 
    else {
      const selected = selectedItem[key]
      selectedItem.fill(
        selected === 1 ?0 :1, key, key+1);
      selectedItem.fill(
        0, 0, key);
      selectedItem.fill(
        0, key+1, selectedItem.length);
      assignCtx.selectItem(selectedItem);
    }
    return false
  }
  else if (event.target.getAttribute('name') === 'clickableButton') {
    const selectedNumber = selectedItem.filter(value => value === 1).length
    if (selectedNumber === 1) {
      return true
    }
    else {
      return false
    }
    
  }
  return false
}

const onButtonClickHandler = (event, key, assignCtx) => {
  const selectedItem = assignCtx.selected;
  const validKeys = assignCtx.items.map((item, index) =>  
    item.sentence !== '<p><br></p>' && item.setence !== '' ? item.index : ''
    ).filter(String)
  const selectedNumber = selectedItem.filter(value => value === 1).length

  if (selectedNumber === 0) {
    const selected = selectedItem[key]
    selectedItem.fill(
      selected === 1 ?0 :1, key, key+1);
    assignCtx.selectItem(selectedItem);

    return true
  } 
  else {
    const minSelectedKey = selectedItem.findIndex(
      item => item === 1
    )
    const maxSelectedKey = selectedItem.lastIndexOf(1);

    var selectedKeys = []
    if (maxSelectedKey < key) {
      selectedKeys = validKeys.filter(value => minSelectedKey <= value && value <= key)
    } 
    else if (maxSelectedKey === key) {
      selectedItem.fill(
        0, key, key+1);
      assignCtx.selectItem(selectedItem);
    }
    else {
      selectedKeys = validKeys.filter(value => maxSelectedKey >= value && value >= key)
    }
    selectedKeys.map((value, index) => 
      selectedItem.fill(1, value, value+1));
    assignCtx.selectItem(selectedItem);

    return false
  }
}

export {onLineClickHandler, onButtonClickHandler};