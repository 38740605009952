import React, { useState, useRef, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
import AuthContext from "../../store/auth-context";
import { Button, Card, CardBody, FormGroup, Label, CustomInput } from "reactstrap";
import { Eye, EyeOff, Check, ArrowLeft, Home } from 'react-feather';

import { BACKEND } from "../../Constants";

import useInput from "./SignInput";

const is_Email = (value) => value.includes("@");
const is_Password = (value) => value.trim() !== "";
const is_Tempcode = (value) => value.trim() !== "";

const SignIn = () => {
    const history = useHistory();

    const authCtx = useContext(AuthContext);

    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const tempcodeInputRef = useRef(null);

    const [cnt, setCnt] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [email_IsExistence, setEmail_IsExistence] = useState(false);
    const [isRemember, setIsRemember] = useState(false);
    const [userInfo, setUserInfo] = useState({ "email": "", "status": "", "suspected": "" });
    const [clickedHandler, setClickedHandler] = useState(0)

    const {
        value: email_Value,
        isValid: email_IsValid,
        hasError: email_HasError,
        valueChangeHandler: email_ChangeHandler,
        inputBlurHandler: email_BlurHandler,
        reset: reset_Email,
    } = useInput(is_Email);
    const {
        value: password_Value,
        isValid: password_IsValid,
        hasError: password_HasError,
        valueChangeHandler: password_ChangeHandler,
        inputBlurHandler: password_BlurHandler,
        reset: reset_Password,
    } = useInput(is_Password);
    const {
        value: tempcode_Value,
        isValid: tempcode_IsValid,
        hasError: tempcode_HasError,
        valueChangeHandler: tempcode_ChangeHandler,
        inputBlurHandler: tempcode_BlurHandler,
        reset: reset_Tempcode,
    } = useInput(is_Tempcode);

    let form_IsValid = false;
    let localStorage = window.localStorage;

    if (email_IsValid && password_IsValid) {
        form_IsValid = true;
    }

    // save user information
    const RememberHandler = () => {
        setIsRemember(!isRemember)
    }
    // show&hide password
    const [passwordType, setPasswordType] = useState(false);
    const pwdTypeHandler = () => {
        setPasswordType(!passwordType)
    }
    // check temporary code
    const CheckTempCode = () => {
        if (localStorage.getItem("userEmail")) {
            var data = { 'email': localStorage.getItem("userEmail"), 'tempcode': tempcode_Value }
            if (email_Value) {
                var data = { 'email': email_Value, 'tempcode': tempcode_Value }
            }
        } else {
            var data = { 'email': email_Value, 'tempcode': tempcode_Value }
        }
        let url = "/chk-sus";
        Axios.post(BACKEND.SERVER + url, data)
            .then((res) => {
                var check = res.data
                if (check.suspected == 0) {
                    setUserInfo(check)
                    setClickedHandler(1)
                } else {
                    setClickedHandler(2)
                    alert("잘못된 보안코드입니다.")
                }
            })
            .catch((res) => {
                console.log(res)
                // alert("보안코드가 잘못되었습니다.")
            })
    }
    useEffect(() => {
        const identifier = setTimeout(() => {

            if (localStorage.getItem("userEmail")) {
                var data = { 'email': localStorage.getItem("userEmail"), '_type': 'CHK_EMAIL' }
                if (email_Value) {
                    var data = { 'email': email_Value, '_type': 'CHK_EMAIL' }
                }
            } else {
                var data = { 'email': email_Value, '_type': 'CHK_EMAIL' }
            }
            let url = "/chk-email";

            Axios.post(BACKEND.SERVER + url, data).then(
                (res) => {
                    let check = res.data
                    setUserInfo(check)
                    if (check && check['email'] !== null) {
                        setEmail_IsExistence(true)
                    } else {
                        setEmail_IsExistence(false)
                    }

                }
            )
        }, 500);

        return () => {
            clearTimeout(identifier);
        }

    }, [email_Value])


    // signin
    const submitHandler = async (event) => {
        event.preventDefault();

        //       if (!form_IsValid) {
        //            return;
        //        }

        var enteredEmail = emailInputRef.current.value;
        var enteredPassword = passwordInputRef.current.value;
        if (!emailInputRef.current.value && localStorage.getItem('userEmail')) {
            var enteredEmail = localStorage.getItem('userEmail');
        }
        if (userInfo && userInfo.suspected === '1') {
            alert('이메일에서 보안코드를 확인하세요.')
        } else if (userInfo && userInfo.status === '0') {
            alert('이메일에서 인증을 완료하세요.')
        } else {

            let url = `${BACKEND.SERVER}/auth`;
            let options = {
                method: "POST",
                url: url,
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
                data: {
                    username: enteredEmail,
                    password: enteredPassword,
                },
            };

            setIsLoading(true);
            await Axios(options).then((response) => {
                let data = response.data;
                const tomorrow = new Date()
                tomorrow.setDate(tomorrow.getDate() + 1);
                var expirationTime = tomorrow;
                authCtx.login(data.access_token, expirationTime);
                let url1 = `${BACKEND.SERVER}/name`;
                let option1 = {
                    method: "GET",
                    url: url1,
                    headers: {
                        "Authorization": `JWT ${data.access_token}`
                    }
                };
                return Axios(option1);
            }).then((response) => {
                console.log("res", response.data)
                const userInfo = {
                    userName: response.data.username,
                    userEmail: enteredEmail,
                    userUid: response.data.user_uid
                }
                localStorage.setItem("userInfo", JSON.stringify(userInfo));
                localStorage.setItem("useremail", userInfo.userEmail);
                localStorage.setItem("username", userInfo.userName);
                localStorage.removeItem('cnt');
                if (isRemember === true) {
                    localStorage.setItem("userEmail", enteredEmail);

                } else {
                    localStorage.removeItem('userEmail');
                }
                setIsLoading(false);
                // history.replace('/projects');
                // need to change.. 
                history.replace('/');
            }).catch((err) => {
                // alert(err.message);

                if (email_IsExistence === false) {
                    alert("아이디가 존재하지 않습니다.")
                } else {
                    localStorage.setItem('cnt', cnt + 1)
                    setCnt(cnt + 1)
                    if (cnt > 4) {
                        let tempcode = Math.random().toString(36).slice(2)
                        var data = {
                            'email': enteredEmail,
                            'tempcode': tempcode,
                            'site': 'thinkcat.tools'
                        }
                        Axios.post(BACKEND.SERVER + '/send-sus', data)
                            .then(res => console.log(res))
                            .catch(res => console.log(res))
                        alert("비밀번호 오류가 반복되었습니다. 보안코드가 이메일로 전송되었습니다. 확인해주세요")
                        history.replace('/');
                    }
                    alert("비밀번호를 확인해주세요.");
                }
                setIsLoading(false);


            })
            // reset_Email();
            reset_Password();
        }

    };

    const email_Classes = email_HasError ? "form-control invalid" : "form-control";
    const password_Classes = password_HasError ? "form-control invalid" : "form-control";
    const tempcode_Classes = tempcode_HasError ? "form-control invalid" : "form-control";

    return (
        <React.Fragment>
            <div className="text-center mt-4">
                <h2>로그인</h2>
                <p className="lead">당신의 능력을 계속해서 펼치세요.</p>
            </div>
            <Card>
                <CardBody>
                    <div className="m-sm-4">
                        <div className="text-center">
                            <img src="/jpg/hozo_cats.jpg" alt="Cats" className="img-fluid" width="400" />
                        </div>

                        <div style={{ padding: "0px", position: "relative", left: "98px" }}>
                            <small>
                                <Link to="/auth/reset-password">비밀번호를 잊으셨나요? </Link>
                            </small>
                            <small>
                                <Link to="/auth/sign-up"> 회원가입</Link>
                            </small>
                            <div>
                                <CustomInput
                                    type="checkbox"
                                    id="rememberMe"
                                    label="아이디 기억하기"
                                    // defaultChecked
                                    checked={isRemember}
                                    onChange={RememberHandler}
                                />
                            </div>
                        </div>

                        <form onSubmit={submitHandler}>
                            <FormGroup className="text-center mt-3">
                                {/* <Label>Email</Label> */}
                                <input
                                    className={email_Classes}
                                    type="text"
                                    name="email"
                                    value={email_Value}
                                    onChange={email_ChangeHandler}
                                    onBlur={email_BlurHandler}
                                    placeholder="이메일"
                                    ref={emailInputRef}

                                    style={
                                        email_HasError ? {
                                            display: "inline-block",
                                            width: "60%",
                                            boxShadow: "0px 0px 3.5px 0.8px rgba(255,158,150,0.5)"
                                        } :
                                            !email_HasError && email_Value ? {
                                                display: "inline-block",
                                                width: "60%",
                                                boxShadow: "0px 0px 3.5px 0.8px rgba(104,158,100,0.5)"
                                            } : {
                                                    display: "inline-block",
                                                    // float: "left"
                                                    width: "60%",
                                                }
                                    }
                                />
                                {/* {email_HasError && <p className="error-text">Please enter a valid email address.</p>} */}
                            </FormGroup>
                            {userInfo.suspected != '1' && <FormGroup className="text-center">
                                <div
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center", zIndex: "1", opacity: "1" }}
                                >

                                    {/* <Label>Password</Label> */}
                                    {!passwordType ?
                                        <i style={{ display: "flex", position: "absolute", zIndex: "1", opacity: "0.33", left: "69%" }}>
                                            <Eye className="feather" onClick={pwdTypeHandler} />
                                        </i> :
                                        <i style={{ display: "flex", position: "absolute", zIndex: "1", opacity: "0.33", left: "69%" }}>
                                            <EyeOff className="feather" onClick={pwdTypeHandler} />
                                        </i>
                                    }
                                    <input
                                        className={password_Classes}
                                        type={userInfo && userInfo.suspected == '1' ? "hidden"
                                            : userInfo.suspected != '1' && !passwordType ? "password"
                                                : userInfo.suspected != '1' && passwordType && "text"}

                                        name="password"
                                        value={password_Value}
                                        onChange={password_ChangeHandler}
                                        onBlur={password_BlurHandler}
                                        placeholder="패스워드"
                                        ref={passwordInputRef}
                                        style={
                                            password_HasError ? {
                                                display: "inline-block",
                                                // float: "left"
                                                width: "60%",
                                                boxShadow: "0px 0px 3.5px 0.8px rgba(255,158,150,0.5)"
                                            } :
                                                !password_HasError && password_Value ? {
                                                    display: "inline-block",
                                                    // float: "left"
                                                    width: "60%",
                                                    boxShadow: "0px 0px 3.5px 0.8px rgba(104,158,100,0.5)"
                                                } : {
                                                        display: "inline-block",
                                                        // float: "left"
                                                        width: "60%",
                                                    }

                                        }
                                    />
                                    {/* {password_HasError && <p className="error-text">Please enter a password.</p>} */}
                                </div>
                            </FormGroup>}
                            {userInfo.suspected == '1' &&
                                <FormGroup className="text-center">
                                    <div
                                        style={{ display: "flex", justifyContent: "center", alignItems: "center", zIndex: "1", opacity: "1" }}
                                    >
                                        <input
                                            className={tempcode_Classes}
                                            type="text"
                                            name="tempcode"
                                            value={tempcode_Value}
                                            onChange={tempcode_ChangeHandler}
                                            onBlur={tempcode_BlurHandler}
                                            placeholder="보안코드 입력"
                                            ref={tempcodeInputRef}
                                            style={{
                                                display: "inline-block",
                                                // float: "left"
                                                width: "60%"
                                            }}
                                        />
                                    </div>

                                    {email_HasError &&
                                        <p className="error-text">보안코드를 입력하세요.</p>}

                                    <Button className="mt-3" outline color={clickedHandler == 0 ? 'success' : 'danger'} style={clickedHandler == 0 ? { cursor: 'pointer', color: '' } : clickedHandler == 1 ? { cursor: 'pointer', color: 'green' } : { cursor: 'pointer', color: 'tomato' }}
                                        onClick={CheckTempCode}>
                                        <Check

                                        />
                                    </Button>
                                </FormGroup>}


                            <div className="text-center mt-3">
                                {!isLoading && userInfo.suspected != '1' && (
                                    <Button color="primary" size="lg">
                                        로그인하기
                                    </Button>
                                )}
                                {isLoading && <p>요청을 보냈습니다...</p>}
                            </div>
                        </form>

                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default SignIn;
