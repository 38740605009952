import React from 'react';

const DocumentContext = React.createContext({
  items: [],
  LANGUAGEOPTIONS: [],
  
  setItems: (items) => {},
  addItem: (item) => {},
  removeItem: (fileName) => {},
  changeState: (state) => {},
  setValueForKey: (index, key, value) => {},
});

export default DocumentContext;