import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';

// parts
import Editor from './Editor';

// contexts
import GlossaryProvider from '../contexts/GlossaryProvider';

// parts
import ImportGlossary from './ImportGlossary';
import ExportGlossary from './ExportGlossary';

const GlossaryEditor = (props) => {
  const params = useParams();
  const glossaryName = params.name;
  const project_no = params.project_no;
  const direction = params.direction;

  const [importModal, setImportModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  
  return (
    <GlossaryProvider>
      <ImportGlossary 
        importModal = {importModal}
        setImportModal = {setImportModal}

        project_no = {project_no}
        glossaryName = {glossaryName}
      />
      <ExportGlossary 
        exportModal = {exportModal}
        setExportModal = {setExportModal}

        project_no = {project_no}
        glossaryName = {glossaryName}
        direction = {direction}
      />
      <Container fluid className='ml-2 mr-2'>
        <Row className = 'pt-2'>
          <Col>
            <h2>{glossaryName}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Editor 
              setImportModal = {setImportModal}
              importModal = {importModal}

              setExportModal = {setExportModal}
              exportModal = {exportModal}
            />
          </Col>
        </Row>
      </Container>
    </GlossaryProvider>
    
  )
};

export default GlossaryEditor;