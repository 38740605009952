const getDateTime = () => {
  const today = new Date();
  const year = String(today.getFullYear()).slice(2,4);
  const month = String(today.getMonth()+1).length === 1 
    ?`0${String(today.getMonth()+1)}`
    :String(today.getMonth()+1)
  const date = today.getDate();
  const times = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
  return [`${year}/${month}/${date}`, times]
};

export { getDateTime }