import React from "react";

import { LANGUAGENAMES } from '../assets/languageNames';

const TableHead = (props) => {

  const columnName = (key) => {
    if (!props.direction) {
      return 'unknown'
    }
    const [source, target] = props.direction.split('2');
    if (key === 'source'){
      return LANGUAGENAMES[source]
    }
    if (key === 'target'){
      return LANGUAGENAMES[target]
    }
    return 'unknown'
  };

  return (
    <thead>
      <tr>
        <th 
          style = {{
            'width': '3rem'
          }}
        >
          #
        </th>
        <th>
          {columnName('source')}
        </th>
        <th>
          {columnName('target')}
        </th>
        <th>
          작성자
        </th>
        <th
          style = {{
            'width': '4.5rem'
          }}
        >
          작성일자
        </th>
        <th 
          style = {{
            'width': '4.5rem'
          }}
        >
        </th>
      </tr>
    </thead>
  )


};

export default TableHead;