import React, { useState, useContext } from 'react';

import { Plus, DownloadCloud, UploadCloud, Info } from 'react-feather';

// functions
import { addItem } from '../services/glossary.add';

// UI
import { Row, Button } from 'react-bootstrap';

// contexts
import GlossaryContext from '../contexts/glossary-context';

const TopOptions = (props) => {
  const gsCtx = useContext(GlossaryContext);
  
  const onAddItemClicked = (event) => {
    addItem(gsCtx, props.project_no, props.userName, props.userNo, props.name);
  };

  return (
    <Row className='pl-3'>
      <Button 
        size = 'sm' 
        className = 'mt-1 mb-1 pl-1'
        variant="secondary"
        onClick = {onAddItemClicked}
      >
        <Plus width={15} height={15} className = 'pr-1' />
        아이템 추가
      </Button>
      
      <Button
        size = 'sm'
        variant="secondary"
        className = 'mt-1 mb-1 ml-1 pl-1'
        onClick={() => props.setImportModal(true)}
      >
        <UploadCloud width={15} height={15} className = 'pr-1' />
        파일 추가
      </Button>

      <Button
        size = 'sm'
        variant="secondary"
        className = 'mt-1 mb-1 ml-1 pl-1'
        onClick = {() => props.setExportModal(true)}
      >
        <DownloadCloud width={15} height={15} className = 'pr-1' />
        내려 받기
      </Button>

      {/* <Button variant="secondary" size = 'sm' className = 'mt-1 mb-1 ml-1 pl-1'>
        <Info width={15} height={15} className = 'pr-1' />
        설정(개발중)
      </Button> */}
    </Row>
  )
};

export default TopOptions