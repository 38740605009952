import React, { useState, useRef } from 'react';

import axios from 'axios';

import { BACKEND } from '../../../../Constants';

// UI
import { Modal, Row, Button, Form } from 'react-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ExportGlossary = (props) => {
  const fileRef = useRef();
  const [fetching, setFetching] = useState('ready')
  const validExt = ['xlsx'];

  const download = (event) => {
    setFetching('fetching');
    
    const userInfo = localStorage.getItem('userInfo');
    const userName = userInfo ?JSON.parse(userInfo).userName :'';
    const userNo   = userInfo ?JSON.parse(userInfo).userUid :'';

    const fileName = fileRef.current.value;
    const fileNameExt = fileName.split('.').length !== 1
      ? fileName.split('.')
      : `${fileName}.`.split('.');
    const ext = fileNameExt.pop();

    const data = new FormData();
    data.append('file', null);
    data.append('exportFileName', fileName);
    data.append('fileName', props.glossaryName);
    data.append('project_no', props.project_no);
    data.append('user_id', userNo);
    data.append('direction', props.direction);
    data.append('process', 'glossary.export');
    axios.post(`${BACKEND.SERVER}/files`, data, 
      {responseType: 'blob'}
    ).then((response) => {
      const element = document.createElement('a');
      const file = new Blob([response.data]);
      element.href = URL.createObjectURL(file);
      element.download = validExt.includes(ext)
        ? `${fileNameExt.join('.')}.${ext}`
        : `${fileNameExt.join('.')}.${validExt[0]}` 
      element.click();
      setFetching('ready');
    }).catch()
  }

  return (
    <Modal 
      show = {props.exportModal}
      centered
      onHide={()=>props.setExportModal(false)}
    >
      <Modal.Header>용어집 다운로드</Modal.Header>
      <Modal.Body>
        <Row>
          <Form className = 'pl-4 pr-4'
            style = {{width:'100%'}}>
            <Form.Group className="mb-3" controlId="filename">
              <Form.Label>다운로드 파일 이름</Form.Label>
              <Form.Control 
                ref = {fileRef}
                as='input'
                defaultValue = {props.glossaryName}
                style = {{
                  width: '100%'
                }}  
              />
              <Form.Text className="text-muted">
                파일이름을 입력해주세요.
              </Form.Text>
              <Form.Text className="text-muted">
                지원하는 파일: .xlsx
              </Form.Text>
            </Form.Group>
          </Form>
        </Row>

        <Modal.Footer>
          <Button 
            variant='light'
            onClick={()=> props.setExportModal(false)}
          >
            닫기
          </Button>
          <Button
            onClick = {download}
          >
            {fetching === 'fetching'
            ?<FontAwesomeIcon icon = {faSpinner} spin />
            :'다운로드'}
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
  
};

export default ExportGlossary;