import React from "react";

const environmentData = {
  loginInfo: {},
  
  fileName: 'string',
  direction: 'string',
  domain: 'string',
};

const resourceData = {
  sourceSentence: [],
  targetSentence: [],
  firstTargetSentence: [],
  targetSentenceAccessibility: [],

  sourceStyleDict: [],
  targetStyleDict: [],
  
  glossaryMatchedIndex: [[], []],

  tmxMatch: [],
  glossaryMatch: [],

  stsScoreDict: {},
  glossaryData: null,
  
};

const stateData = {
  modifiedOrNotDict: {},
  checkedOrNotDict: {},
  editorStates: {},

  loadedSrcLanguage: false,

  linesNumber: 0,
  progressState: {},
  fetchedLineNumber: 0,
  currentActive: 0,
  glossaryActive: [],
  
};

const initData = {
  loginInfo: {},

  fileName: '',
  direction: '',
  domain: '',

  direction: 'ko2en',
  domain: 'normal',

  sourceSentence: [],
  targetSentence: [],
  firstTargetSentence: [],
  targetSentenceAccessibility: [],

  sourceStyleDict: [],
  targetStyleDict: [],

  glossaryMatchedIndex: [[],[]],

  tmxMatch: [],
  glossaryMatch: [],

  modifiedOrNotDict: {},
  checkedOrNotDict: {},
  editorStates: {},
  stsScoreDict: {},

  loadedSrcLanguage: false,

  linesNumber: 0,
  progressState: 'ready',
  fetchedLineNumber: 0,
  completion: false,
  currentActive: 0,
  glossaryData: null,
  glossaryActive: [],

  currentDragActive: '',

  editorDataUpdate: (action) => {},
  editorDataInit: () => {},
  editorDataListAppend: (action) => {},
  editorDataListExtend: (action) => {},
  editorDataDictAppend: (action) => {},
  edtiorDataFileRead: (action) => {},

  dispatch: () => {},

  editorDataTargetInit: () => {},


 };


const EditorDataContext = React.createContext(initData);
const environmentDataContext = React.createContext(environmentData);
const resourceDataContext = React.createContext(resourceData);
const stateDataContext = React.createContext(stateData);

export { environmentDataContext, resourceDataContext, stateDataContext,
         environmentData, resourceData, stateData};

export default EditorDataContext;
