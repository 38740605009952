import React, { useRef } from "react";
import { useHistory } from "react-router-dom";

import { Button, Card, CardBody, FormGroup, Label } from "reactstrap";

const ResetPassword = () => {
    const history = useHistory();
    const newPasswordInputRef = useRef();

    const submitHandler = (event) => {
        event.preventDefault();

        const enteredNewPassword = newPasswordInputRef.current.value;
        // console.log(enteredNewPassword);
        history.replace("/");
    };

    return (
        <React.Fragment>
            <div className="text-center mt-4">
                <h1 className="h2">Reset password</h1>
                <p className="lead">Enter your email to reset your password.</p>
            </div>

            <Card>
                <CardBody>
                    <div className="m-sm-4">
                        <form onSubmit={submitHandler}>
                            <FormGroup>
                                <Label>새 비밀번호</Label>
                                <input
                                    bssize="lg"
                                    type="password"
                                    name="password"
                                    placeholder="새 비밀번호를 입력하세요"
                                    minLength="4"
                                    ref={newPasswordInputRef}
                                />
                            </FormGroup>
                            <div className="text-center mt-3">
                                <Button color="primary" size="lg">
                                    Reset password
                                </Button>
                            </div>
                        </form>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default ResetPassword;
