import React from "react";

import { Table, Badge, Card } from "reactstrap";

import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ResourceTable = (props) => {
  const getColorText = (status) => {
    if (status === 'on') {
      return ['mediumseagreen', '양호']
    }
    if (status === 'warning') {
      return ['tan', '지연']
    }
    if (status === 'off') {
      return ['red', '불가']
    }
  };

  return (
    <Card className = 'mt-2 mb-2' style ={{width: '100%'}}>
      <p className="pl-4 pt-3">API 상태</p>
      <Table>
        <thead>
          <tr>
            <th className="p-1 pl-4">
              서버
            </th>
            <th className="p-1">
              자동 번역 서비스
            </th>
            <th className="p-1">
              프로세싱 서비스
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.values(props.data).map((value, index) => {
            return (
              <tr key = {index}>
                <td className="p-1 pl-4">
                  {`서버-${index}`}
                </td>
                <td className="p-1">
                  <FontAwesomeIcon 
                    icon = {faCircle}
                    size = 'lg'
                    color = {getColorText(value.status1)[0]}
                    className = 'pr-1'
                  />
                  {getColorText(value.status1)[1]}
                </td>
                <td className="p-1">
                  <FontAwesomeIcon 
                    icon = {faCircle}
                    size = 'lg'
                    color = {getColorText(value.status2)[0]}
                    className = 'pr-1'
                  />
                  {getColorText(value.status2)[1]}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Card>
    
  )
}

export default ResourceTable;