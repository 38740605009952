import { combineReducers } from "redux";

import navbar from "./topNavBarReducers"
import sidebar from "./sidebarReducers";
import layout from "./layoutReducer";
import theme from "./themeReducer";

import { reducer as toastr } from "react-redux-toastr";

// add editor data reducer
//import editorData from '../../components/WebEditor/store/editorDataReducer';
import editorData from '../../pages/WebEditor/store/editorDataReducer';

// add global state reducer
import globalStates from "./statesReducer";

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: []
    //whitelist: ['editorDataReducer']
}

export const rootReducer = combineReducers({
    navbar,
    sidebar,
    layout,
    theme,
    toastr,
    editorData,
    globalStates
})

export default persistReducer(persistConfig, rootReducer);

// export default combineReducers({
//   sidebar,
//   layout,
//   theme,
//   toastr
// });
