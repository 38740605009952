import React, { useState, useEffect } from "react";

import "../../assets/scss/3-components/_toast.scss";
function ToastNotification(props) {

    return (
        <div className="toast-alert">
            <p><b>{props.text}</b></p>
        </div>
    );
}

export default ToastNotification;