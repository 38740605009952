import React, { useState, useEffect, useRef } from "react";
import UploadService from "../services/FileUploadService";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";

import { BACKEND } from "../Constants";

import {
  Button
} from "reactstrap";

import TranslateFile from './TranslateFile';

// WebEditor/UI
//import DropdownList from "./WebEditor/UI/DropdownList";
import DropdownList from "../pages/WebEditor/UI/DropdownList";


const baseURL = BACKEND.SERVER;

const UploadFile = (props) => {
  //const {project_no, parentUpdate} = props;
	
  const history = useHistory();

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");

  const [direction, setDirection] = useState('ko2en');

  const availableDirection = ['ko -> en', 'en -> ko'];

  const directionSelect = (item) => {
    if (item === availableDirection[0]) {
        setDirection('ko2en');
    }
    else if (item === availableDirection[1]) {
        setDirection('en2ko');
    }
    else {
        console.log('direction error');
    }
    //props.stateHandler({type: 'ready'});
  };

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };
	const inputEl = useRef(null);

  const setStates = (filename, direction) => {
    if (props.parentUpdate === undefined ) { // 바로 번역
      history.replace('/editor/'+filename+'/'+direction);
      return <TranslateFile filename={filename} direction = {direction} />;
    } else { // 프로젝트로 관리
      setCurrentFile(undefined);
      inputEl.current.value = '';
      const timer = setTimeout(() => setMessage(""), 2000);
    }
  }

  const upload = () => { 
    let currentFile = selectedFiles[0];
    const email = JSON.parse(localStorage.getItem('userInfo')).userEmail;

    setProgress(0);
    // console.log(props.project_no);
    UploadService.upload(currentFile, email, props.project_no, false, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));

      // if (Math.round((100 * event.loaded) / event.total) == 100) {
      //   const timer = setTimeout(() => setStates(currentFile.name, direction), 1000);
	    //   }
      }).then((response) => {
        //console.log(response.data.filename);
        if (response.data.filename === 'duplicateFile') {
          setMessage(response.data.message);
          alert(response.data.message);
        }
        else {
          setCurrentFile(response.data.filename);
          setMessage(response.data.message);
        
          if (props.parentUpdate === undefined ) { // 바로 번역
            setStates(response.data.filename, direction)
          } else {
            let newfile = { 
              "project_no": props.project_no, 
              "filename" : currentFile.name,
              "filesize" : currentFile.size,
              "uploaded" : new Date().toLocaleString() + '',
              "trans_percent": 0.0,
              "postEdit_percent": 0.0,
              "randomCreateName": response.data.filename
            }
            // console.log(newfile);
            props.parentUpdate(newfile);
          }
        }
    }).catch((err) => {
	      inputEl.current.value = ''
        setProgress(0);
        setMessage("파일을 업로드할 수 없었습니다!");
        console.log(err);
        setCurrentFile(undefined);
      });
    
    // setCurrentFile(undefined);
    // setSelectedFiles(undefined);
  };

return (
    <div>
      <input type="file" ref={inputEl} onChange={selectFile} />
      <button
        className="btn btn-success"
        disabled={!selectedFiles}
        onClick={upload}
      >
        업로드
      </button>

      {!props.disableDropList && <DropdownList 
        items = {availableDirection}
        directionSelect = {directionSelect} />}

      {(currentFile && progress < 100) && (
        <div className="progress">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}
      {(currentFile && progress === 100) && <div className="alert alert-success" role="alert">
        {message}
      </div>}
    </div>
  );
};

export default UploadFile;
