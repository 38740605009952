import React, { useState, useContext } from "react";
import classnames from "classnames";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// document tab add by kimhh 22.05.06
// tmx tab add by kimhh 22.01.27
// team tab kimhh 22.05.04
import DocumentTab from "../../DocumentTab/parts/DocumentTab";
import TmxTab from "../../TmxTab/parts/TmxTab"
import TeamTab from "../../TeamTab/parts/TeamTab";
import GlossaryTab from "../../GlossaryTab/parts/GlossaryTab";

// Project Provider
import ProjectProvider from "../../Provider/ProjectProvider";
import ProjectInfoContext from "../contexts/ProjectInfo-context";

// redux
import { useSelector, useDispatch } from 'react-redux';

const ProjectTabs = (props) => {
    const projectCtx = useContext(ProjectInfoContext);
    
    const sourceLang = projectCtx.project.sourceLang;
    const targetLang = projectCtx.project.targetLang;

    const activeTab = useSelector((state) => state.globalStates.projectTabIndex);
    
    const dispatch = useDispatch();
    const setActiveTab = (activeTab) => {
        dispatch({
            type: 'globalState.setProjectActiveTab',
            activeTab: activeTab
        })
    }

    const toggle = (tab) => {
        setActiveTab(tab);
    };

    // console.log(activeTab)
    // console.log(props.acceptance)
    return (
        <div className="tab">
            <Nav tabs>
                {props.projectType === 'translation' && <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {toggle("1");}}
                    >
                        문서
                    </NavLink>
                </NavItem>}
                {props.projectType === 'translation' && <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                            props.acceptance === 'accepted' &&
                            toggle("2");}}
                        style = {{cursor: props.acceptance === 'accepted'? 'pointer':'not-allowed'}}
                    >
                        용어집
                    </NavLink>
                </NavItem>}
                <NavItem>
                    <NavLink
                        className={classnames({
                            active: props.projectType === 'translation'
                            ? activeTab === "3" 
                            : activeTab === "1"
                        })}
                        onClick={() => {
                            props.acceptance === 'accepted' &&
                            toggle(props.projectType === 'translation'? "3" : "1");}}
                        style = {{cursor: props.acceptance === 'accepted'? 'pointer':'not-allowed'}}
                    >
                        번역 메모리
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({
                            active: props.projectType === 'translation'
                            ? activeTab === "4" 
                            : activeTab === "2"
                        })}
                        onClick={() => {
                            toggle(props.projectType === 'translation'? "4" : "2");}}
                    >
                        TEAM
                    </NavLink>
                </NavItem>
            </Nav>
            <ProjectProvider>
                <TabContent activeTab={activeTab}>
                <TabPane tabId={props.projectType === 'translation'? "1" : null}>
                        <DocumentTab 
                            setDocuments = {props.setDocuments}
                            removeDocument = {props.removeDocument}
                            parentUpdate = {props.parentUpdate}
                            project_no = {props.project_no}
                            sourceLang = {sourceLang}
                            targetLang = {targetLang}
                            availableEdit = {props.availableEdit}
                            acceptance = {props.acceptance}
                            isActive = {props.projectType === 'translation'
                                ?activeTab === '1'
                                :false}
                        />
                    </TabPane>
                    <TabPane tabId={props.projectType === 'translation'? "2" : null}>
                        <GlossaryTab 
                            project_no = {props.project_no}
                            sourceLang = {sourceLang}
                            targetLang = {targetLang}
                            setGlossary = {props.setGlossary}
                            availableEdit = {props.availableEdit}
                            acceptance = {props.acceptance}
                            isActive = {props.projectType === 'translation'
                                ?activeTab === '2'
                                :false}
                        />
                    </TabPane>
                    <TabPane tabId={props.projectType === 'translation'? "3" : "1"}>
                        <TmxTab 
                            project_no = {props.project_no}
                            sourceLang = {sourceLang}
                            targetLang = {targetLang}
                            setTmxFiles = {props.setTmxFiles}
                            availableEdit = {props.availableEdit}
                            acceptance = {props.acceptance}
                            isActive = {props.projectType === 'translation'
                                ?activeTab === '3'
                                :activeTab === '1'}
                            projectType = {props.projectType}
                        />
                    </TabPane>
                    <TabPane tabId={props.projectType === 'translation'? "4" : "2"}>
                        <TeamTab 
                            project_no={props.project_no} 
                            availableEdit = {props.availableEdit}
                            currentUserRole = {props.currentUserRole}
                            acceptance = {props.acceptance}
                            isActive = {props.projectType === 'translation'
                                ?activeTab === '4'
                                :activeTab === '2'}
                        />
                    </TabPane>
                </TabContent>
            </ProjectProvider>
        </div>
    );
};

export default ProjectTabs;
