import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Axios from 'axios';
import { BACKEND } from "../../Constants";

import {
    Button,
    Col,
    Container,
    Row,
    Pagination,
    PaginationItem,
} from "reactstrap";

import SupportList from "./SupportList";

var isEmpty = function (value) {
    if (value == "" || value == null || value == undefined || (value != null && typeof value == "object" && !Object.keys(value).length)) { return true } else { return false }
};

function isInt(value) {
    return !isNaN(value) && (function (x) { return (x | 0) === x; })(parseFloat(value))
};

const Support = ({ history }) => {
    const num_per_page = 10;
    const page_per_block = 10;

    const params = useParams();
    const query = params.query;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    var page = parseInt(queryParams.get('page'));
    const isPaging = !isEmpty(page) && isInt(page);
    if (!isPaging) page = 1;

    var prev_page = page - 1;
    var next_page = page + 1;
    var now = num_per_page * page;

    const [supportLists, setSupportLists] = useState([]);
    const [name, setName] = useState("");

    const handleSubmit = () => {
        history.push("/form/add/" + query);
    }

    useEffect(() => {
        let url = BACKEND.SERVER + "/supports";
        if (typeof (query) !== 'undefined')
            url = url + "/" + query;
        url = url + "?page=" + page;

        Axios.get(url).then((response) => {
            const gets = response.data;
            if (gets && gets.length > 0 && gets[0].total_records > 0)
                setSupportLists(gets);
            if (typeof (query) !== 'undefined')
                setName(gets[0].member_no);
            else
                setName("모든 게시글");
        });
    }, [query, page]);

    var IsNext = 0;
    var total_page = 1;
    var total_block = 1;
    var block = 1;
    var first_page = 1;
    var last_page = 1;
    var end_page = 1;
    var total = 0;
    if (supportLists.length > 0) {
        total = supportLists[0].total_records;
    }

    if (typeof (supportLists) !== 'undefined') {
        total_page = Math.ceil(total / num_per_page);
        IsNext = total - now;
        total_block = Math.ceil(total_page / page_per_block);
        block = Math.ceil(page / page_per_block);
        first_page = (block - 1) * page_per_block;
        last_page = block * page_per_block;
        if (total_block <= block) last_page = total_page;
        end_page = last_page + 1;
        var pages = [];
        for (var direct_page = first_page + 1; direct_page <= last_page; direct_page++) {
            pages.push(direct_page);
        }
    }

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">게시판</h1>
            {supportLists.length > 0 && <SupportList query={query} setSupportLists={setSupportLists} name={name} list={supportLists} />}
            <Row>
                <Col>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {typeof (supportLists) !== 'undefined' && total > num_per_page ? (<Pagination>
                            <PaginationItem disabled={page === 1}>
                                {page > 1 ? (<Link to={window.location.pathname + '?page=' + prev_page} className="page-link" tabIndex="-1">&lt;</Link>) : <Link to="#" className="page-link">&lt;</Link>}
                            </PaginationItem>
                            { block > 1 ? <PaginationItem><Link to={window.location.pathname + '?page=' + 1} className="page-link">1</Link></PaginationItem> : ''}
                            { block > 1 ? <PaginationItem><Link to={window.location.pathname + '?page=' + first_page} className="page-link">&laquo;</Link></PaginationItem> : ''}

                            { typeof (pages) !== 'undefined' ?
                                pages.map((it, index) => <PaginationItem key={index} active={page == it}><Link to={window.location.pathname + '?page=' + it} className="page-link">{it}</Link></PaginationItem>)
                                : ''
                            }
                            { block < total_block ?
                                <PaginationItem><Link to={window.location.pathname + '?page=' + end_page} className="page-link">&raquo;</Link></PaginationItem> : ''}
                            { block < total_block ?
                                <PaginationItem><Link to={window.location.pathname + '?page=' + total_page} className="page-link">End</Link></PaginationItem> : ''}
                            <PaginationItem disabled={IsNext == 0}>
                                {IsNext > 0 ? (<Link to={window.location.pathname + '?page=' + next_page} className="page-link" tabIndex="-1">&gt;</Link>) : <Link to="#" className="page-link">&gt;</Link>}
                            </PaginationItem>
                        </Pagination>
                        ) : ''}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="10">
                    <div
                        className="float-right mt-1"
                        type="button"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        뒤로가기
                    </div>
                </Col>
                <Col>
                    <Button type="submit" color="primary" className="mr-4 float-right" onClick={handleSubmit}>
                        새 글 쓰기
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default Support;
