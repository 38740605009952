//TranslateFile.js by kimhh at 2022.01.18
//WebEditor from components/WebEditor
import React, { useState, useEffect } from "react";

//WebEditor
//import MainPart from "./WebEditor/parts/Main";
import WebEditor from "../pages/WebEditor/parts/Main";

const TranslateFile = (props) => {
	//const {currentFile, setIsloading, direction} = props;
	return (
		<WebEditor 
			currentFile = {props.currentFile}
			direction = {props.direction}
			project_no = {props.project_no || null}
		/>
	)

    
};

export default TranslateFile;
