import axios from "axios";

import { getDateTime } from "./glossary.utils";

import { BACKEND } from "../../../../Constants";

const addItem = (gsCtx, project_no, userName, userNo, name) => {
  const [date, times] = getDateTime();
  const item = {
    sourceGlossary:[''],
    targetGlossary:[''],
    auther:userName,
    date:date
  }

  const data = new FormData();
  const maxIndex = gsCtx.items.length !== 0
    ?gsCtx.items[gsCtx.items.length-1].index
    :0;
  
  data.append('project_no', project_no);
  data.append('user_no', userNo);
  data.append('owner', userNo);
  data.append('fid', '');
  data.append('fileName', name);
  data.append('lineNumber', maxIndex+1);
  data.append('modifiedDate', `${date} ${times}`);
  data.append('process', 'glossary.addLine');

  axios.put(`${BACKEND.SERVER}/files`, data
    ).then((response) => {
      // console.log(response)
    })
  gsCtx.addItem(item);
}

export { addItem };