
const initGlobalState = () => {
  return {
    type: 'globalState.initialize'
  }
}

const setProjectActiveTab = (activeTab) => {
    return {
      type: 'globalState.setProjectActiveTab',
      activeTab: activeTab,
    };
};

export {
  initGlobalState, 
  setProjectActiveTab, 
};
