import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

// reactstrap
import { Table, Button, Progress } from "reactstrap";

//utils
import utils from '../../DocumentTab/utils/functions'

// contexts
import TmxContext from "../contexts/tmx-context";

import { useMediaQuery } from "react-responsive";

const TmxFileList = (props) => {
  const tmxCtx = useContext(TmxContext);
  const [tmxList, setTmxList] = useState([]);
  const history = useHistory();
  const [direction, setDirection] = useState("");
  const [validDirection, setValidDirection] = useState(false);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const tmxItemRemoveHandler = (event) => {
    event.preventDefault();
    console.log(event.currentTarget.id);
    //props.removeItem(event)
  };

  const checkValidDirection = () => {
    if (props.sourceLang !== props.targetLang) {
      if (props.sourceLang == "한국어" && props.targetLang == "영어") {
        setDirection("ko2en");
        setValidDirection(true);
        return;
      } else if (props.sourceLang == "영어" && props.targetLang == "한국어") {
        setDirection("en2ko");
        setValidDirection(true);
        return;
      }
      setValidDirection(false);
      return;
    } else {
      setValidDirection(false);
      return;
    }
  };

  useEffect(()=> {
    checkValidDirection();
  }, [props.sourceLang, props.targetLang])

  useEffect(() => {
    tmxCtx.items && setTmxList(tmxCtx.items);
  }, [tmxCtx.items])

  useEffect(() => {
    if (tmxCtx.searchKeyword != ''){
      const searchItems = tmxCtx.items.filter((item) => 
        item.filename.includes(tmxCtx.searchKeyword))
      setTmxList(searchItems);
    }
    else {
      setTmxList(tmxCtx.items);
    }
  }, [tmxCtx.searchKeyword])

  const historyPush = (event, filename) => {
    const region = event.target.getAttribute('name');
        
    if (region === 'clickable' && validDirection) {
      history.push(
        {pathname: `/inspector/${props.project_no}/${filename}/${direction}`,
        state: {acceptance: props.acceptance}}
      )
    }
  };

  const CheckValidDirection = (props) => {
    if (props.sourceLang !== props.targetLang) {
      let direction;
      if (props.sourceLang == "한국어" && props.targetLang == "영어") {
        direction = "ko2en";
        return (
          <Link
            to={`/inspector/${props.project_no}/${props.filename}/${direction}`}
          >
            {props.filename}
          </Link>
        );
      } else if (props.sourceLang == "영어" && props.targetLang == "한국어") {
        direction = "en2ko";
      }
      return (
        <Link
          to={`/inspector/${props.project_no}/${props.filename}/${direction}`}
        >
          {props.filename}
        </Link>
      );
    } else {
      return <Link to={"#"}>{props.filename}</Link>;
    }
  };

  const TablePC = (props) => {
    return (
      <Table hover>
        <thead>
            <tr>
              <th>번호</th>
              <th>파일 이름</th>
              {props.projectType === 'post-editing' && <th>검수율</th>}
              <th>업로드일</th>
              <th style = {{'width':'2px'}}></th>
            </tr>
        </thead>
        <tbody>
        {tmxList.map((item, index) => (
          <tr 
            key={index}
            style = {{cursor: validDirection ?'pointer' :'not-allowed'}}
            onClick = {(event) => historyPush(event, item.filename)}
          >
            <td name = 'clickable'>{index + 1}</td>
            <td name = 'clickable'>
              {item.filename}
              ({utils.humanFileSize(item.filesize)})
            </td>
            {props.projectType === 'post-editing' && <td name = 'clickable'>
              <Progress
                name = 'clickable'
                className="mb-2"
                value={item.postEdit_percent}
              >
                {`${item.postEdit_percent.toFixed()}%`}
              </Progress>  
            </td>}
            <td name = 'clickable'>{item.uploaded}</td>
            <td style={{ width: "auto" }} align="right">
              <Button
                id={item.index}
                outline
                color="secondary"
                className="ml-10 mr-10"
                style = {{
                  borderColor:'rgba(0,0,0,0)', 
                  cursor: props.availableEdit
                    ?'pointer' :'not-allowed'
                }} 
                onClick={props.removeItem}
                disabled = {!props.availableEdit}
              >
                <FontAwesomeIcon icon={faTrashAlt} size="lg" />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )};

  const TableMobile = (props) => {
    return (
      <div>
        {tmxList.map((item, index) => (
          <div
            key = {index}
            style = {{width: '100%'}}  
          >
            <Table
              className = 'm-1 p-2'
              style = {{
                width: '100%',
                borderBottom: '1px solid #5b5b5b',
                tableLayout: 'fixed'
              }}
            >
              <colgroup>
                <col width='30%' />
                <col width='70%' />
              </colgroup>
              <tbody>
                <tr>
                  <th>번호</th>
                  <td>{index + 1}</td>
                </tr>
                <tr>
                  <th>파일 이름</th>
                  <td
                    name = 'clickable'
                    onClick={(event) => historyPush(event, item.filename)}
                    style = {{wordBreak: 'break-all'}}
                  >
                    <p className = 'font-weight-bolder' name="clickable">
                      {item.filename}
                      ({utils.humanFileSize(item.filesize)})
                    </p>
                  </td>
                </tr>
                
                {props.projectType === 'post-editing' && <tr>
                  <th>검수율</th>
                  <td>
                    <Progress
                      name = 'clickable'
                      className="mb-2"
                      value={item.postEdit_percent}
                    >
                      {`${item.postEdit_percent.toFixed()}%`}
                    </Progress>  
                  </td>
                </tr>}

                <tr>
                  <th>업로드 일</th>
                  <td>{item.uploaded}</td>
                </tr>

                <tr>
                  <th>삭제</th>
                  <td>
                    <Button
                      id={item.index}
                      outline
                      color="secondary"
                      className="ml-10 mr-10"
                      style = {{
                        borderColor:'rgba(0,0,0,0)', 
                        cursor: props.availableEdit
                          ?'pointer' :'not-allowed'
                      }} 
                      onClick={props.removeItem}
                      disabled = {!props.availableEdit}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                    </Button>
                  </td>
                </tr>
              </tbody>
              

            </Table>

          </div>
        ))}
      </div>
    )
  }





  // console.log(tmxList)

  return (
    <React.Fragment>
      {isMobile
        ?<TableMobile {...props} />
        :<TablePC {...props} />
      }
    </React.Fragment>
  );
};

export default TmxFileList;
