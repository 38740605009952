import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

// reactstrap
import { Button, Table, Progress, Alert, Spinner } from "reactstrap";

import DocumentCotext from '../context/file-context';
import FileTranslation from "./FileTranslation";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";

// file service
import FileUploadService from "../../../services/FileUploadService";

// utils
import utils from "../../projects/DocumentTab/utils/functions";

const UploadFileList = (props) => {
  const fileCtx = useContext(DocumentCotext);
  const history = useHistory();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const project_no = userInfo ? 'individual' :'tempFiles';
  const userEmail = userInfo? userInfo.userEmail :null;
  const userUid = userInfo? userInfo.userUid :null;

  const token = localStorage.getItem('token');

  const removeDocument = (event) => {
    const fileName = event.currentTarget.value;
    FileUploadService.deleteDocument(
      project_no, userEmail, fileName, 'documents', token
    ).then(() => {
      fileCtx.removeItem(fileName);
    });
  };
  const addDocument = () => {
    props.modalShow();
  };

  const status2ko = (status, progress, trans_percent) => {
    if (status === 'translation') {
      return <>
        <p className="mb-1 font-weight-bold">
          번역 중... <Spinner color="info" size="sm" />
        </p>
        <Progress
          animated
          color = 'info'
          striped
          value = {progress}
        >
          {`${progress.toFixed()}%`}
        </Progress>
      </>
    }
    else if (status === 'translated') {
      return <>
        <p className="mb-1 font-weight-bold">
          휴먼 확인률
          <span className="float-right">{parseFloat(trans_percent.toFixed(2))}%</span>    
        </p>
        <Progress className="mb-2" value={trans_percent} />
      </>
    }
    else if (status === 'translation done') {
      return '번역 완료'
    }

    else{
      switch (status) {
        default:
          return '대기중'
  
        case ('waiting'):
          return '대기중'
  
        case ('translated'):
          return '번역 완료'
      }
    }
  }

  const historyPush = (event, status, filename, direction) => {
    const region = event.target.getAttribute('name');
    // state: {acceptance: userInfo ? 'accepted': 'limited'}
    if (region) {
      if (region === 'clickable') {
        if (status === 'translated' || status === 'translation done') {
          history.push(
            {pathname: `/translate/${project_no}/${filename}/${direction}`,
             state: {acceptance: 'accepted'}}
          )
        }
        else {
          alert(' 번역 방향을 선택해 주세요.')
        }
      }
    }
  };

  const getLanguages = (direction) => {
    if (!direction) {
      return [null, null]
    }
    const sourceLang = fileCtx.LANGUAGEOPTIONS.find(
      item => item.variableName === direction.slice(0, 2)).name
    const targetLang = fileCtx.LANGUAGEOPTIONS.find(
        item => item.variableName === direction.slice(3, 5)).name
    return [sourceLang, targetLang]
  }
  
  useEffect(() => {
    FileUploadService.getFiles(
      project_no, userUid
    ).then((response) => {
      // console.log(response.data)
      if (response.data.filename === 'duplicateFile') {
        alert(response.message)
      } else{
        // console.log(response.data)
        response.data.map((file) => {
          const languages = getLanguages(file[5]);
          const files = {
            filename: file[0],
            filesize: file[1],
            uploaded: file[2],
            status: file[4],
            direction: file[5],
            trans_percent: file[6],
            postEdit_percent: file[7],
            progress: 0,
            isValid: {
              src: languages[0]? 'valid' :'invalid.init',
              tgt: languages[1]? 'valid' :'invalid.init'
            },
            sourceLang: languages[0],
            targetLang: languages[1]
          }
          fileCtx.addItem(files);
        })
      }
    })
  }, [])

  // console.log(fileCtx)

  return (
    <React.Fragment>
      {fileCtx.items.length > 0 && <Table hover>
        <thead>
          <tr>
            <th>번호</th>
            <th>파일 이름</th>
            <th>번역</th>
            <th>상태</th>
            <th>업로드일</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fileCtx.items.map((data, index) => 
            <tr 
              key = {index}
              style = {{cursor: data.status != 'translation'  ?'pointer' :'not-allowed'}}
              onClick = {(event) => historyPush(
                event,
                data.status, data.filename, data.direction)}
            >
              <td name = 'clickable'>{index + 1}</td>
              <td name = 'clickable'>
                {data.filename}
                ({utils.humanFileSize(data.filesize)})
              </td>
              <td>
                <FileTranslation 
                  filename = {data.filename}
                  index = {index}
                  project_no = {project_no}
                  direction = {data.direction}
                  sourceLang = {data.sourceLang}
                  targetLang = {data.targetLang}
                />
              </td>
              <td name = 'clickable'>
                {status2ko(data.status, data.progress, data.trans_percent)}
              </td>
              <td>
                {data.uploaded}
              </td>
              <td align="right">
                <Button 
                  id = {index}
                  value = {data.filename}
                  outline color = 'secondary' 
                  className = "ml-1"
                  style = {{'borderColor':'rgba(0,0,0,0)'}} 
                  onClick = {removeDocument}>
                  <FontAwesomeIcon icon={faTrashAlt} size ='lg'/>
                </Button>
                {/* <Button 
                  id = {index}
                  outline color = 'success' 
                  className = "mr-1"
                  style = {{'borderColor':'rgba(0,0,0,0)'}} 
                  onClick = {addDocument}>
                  <FontAwesomeIcon icon={faPlus} size ='lg'/>
                </Button> */}
              </td>
            </tr>
            )}

        </tbody>
      </Table>}
    </React.Fragment>
  )
};

export default UploadFileList;