import React, { useContext, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useHistory, NavLink as RRNavLink, useLocation } from "react-router-dom";

import { toggleSidebar } from "../redux/actions/sidebarActions";


import AuthContext from "../store/auth-context";
import {
    Row,
    Button,
    Col,
    Collapse,
    Navbar,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ListGroup,
    ListGroupItem,
    Form,
    Input,
} from "reactstrap";

import LetteredAvatar from 'react-lettered-avatar';
import * as Icon from "react-feather";
import axios from "axios";
import { BACKEND } from "../Constants";
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";

const calDate = (d) => {
    var date = new Date(d * 1000);

    var time = date.getFullYear() + '-' +
        ('00' + (date.getMonth() + 1)).slice(-2) + '-' +
        ('00' + date.getDate()).slice(-2) + ' ' +
        ('00' + date.getHours()).slice(-2) + ':' +
        ('00' + date.getMinutes()).slice(-2) + ':' +
        ('00' + date.getSeconds()).slice(-2);
    return time;
}

const NavbarDropdown = ({ children, count, showBadge, header, footer, icon: Icon }) => {
    let link = '/chats';
    if (footer === '모든 알림 보기') link = '/notifications';
    return (
        <UncontrolledDropdown nav inNavbar className="mr-2">
            <DropdownToggle nav className="nav-icon dropdown-toggle">
                <div className="position-relative">
                    <Icon className="align-middle" size={18} />
                    {showBadge ? <span className="indicator">{count}</span> : null}
                </div>
            </DropdownToggle>
            <DropdownMenu right className="dropdown-menu-lg py-0">
                <div className="dropdown-menu-header position-relative">
                    {count} {header}
                </div>
                <ListGroup>{children}</ListGroup>
                <DropdownItem header className="dropdown-menu-footer">
                    <Link to={link}><span className="text-muted">{footer}</span></Link>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

const NavbarDropdownItem = ({ icon, title, description, time, query }) => {
    const history = useHistory();
    const styles = { cursor: 'pointer' };
    return (
        <ListGroupItem>
            <Row style={styles} noGutters className="align-items-center" onClick={() => {
                { query && history.push("/support/" + query) }
                { !query && history.push("/chats/" + title) }
            }}>
                <Col xs={2}>{icon}</Col>
                <Col xs={10} className={!query ? "pl-2" : null}>
                    <div className="text-dark">{title}</div>
                    <div className="text-muted small mt-1">{description}</div>
                    <div className="text-muted small mt-1">{time}</div>
                </Col>
            </Row>
        </ListGroupItem>
    )
};

const NavbarComponent = ({ dispatch }) => {
    const history = useHistory();
    const authCtx = useContext(AuthContext);

    const isLoggedIn = authCtx.isLoggedIn;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const username = isLoggedIn ? userInfo.userName : null;
    const notifications = authCtx.notifications;
    const messages = authCtx.messages;

    const inputRef = useRef(null);

    const logoutHandler = () => {
        authCtx.logout();
        localStorage.clear();
        history.replace("/");
    };

    const sticky = useSelector((state) => state.navbar.topNavBarSticky)
    const topNavbarSticky = sticky ? 'top' : 'false'

    // Chk' theme
    const [isDark, setIsDark] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
    console.log("window theme :::", isDark);

    const location = useLocation();
    const [searchPjt, setSearchPjt] = useState("");
    const handleChange = (e) => {
        e.preventDefault()
        setSearchPjt(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        window.location.href = `/search_pjt?search=${searchPjt}`;
        // history.push(`/search_pjt/${searchPjt}`);
        // history.push(`/search_pjt?search=${searchPjt}`);
    }
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return (
        <Navbar
            className='pt-2 pb-2'
            sticky={topNavbarSticky} color="white" light expand
        >
            <span
                className="sidebar-toggle d-flex mr-2"
                onClick={() => {
                    dispatch(toggleSidebar());
                }}
            >
                <i className="hamburger align-self-center" style={isDark ? { filter: "invert()" } : {}} />
                {/* <List /> */}
            </span>
            <span className='ml-3 mr-3' type='button' onClick={() => { history.goBack() }}>
                <Icon.ArrowLeft />{!isMobile && '뒤로가기'}
            </span>
            <Form onSubmit={handleSubmit} inline>
                <div className="bg-light">
                    <Input
                        className="form-control-no-border mr-sm-2 bg-light "
                        type="text"
                        innerRef={inputRef}
                        placeholder={!isMobile ? "파일명 또는 프로젝트명 검색" : "검색"}
                        aria-label="Search"
                        onChange={handleChange}
                    />
                    <Button type="submit" variant="" style={isMobile ? { border: "none", backgroundColor: "transparent", position: "absolute", top: "10px", right: "140px" } : { border: "none", backgroundColor: "transparent", position: "sticky" }}>
                        <Icon.Search size="21" color="gray" data-toggle="tab-hover" />
                    </Button>
                    {/* <Link
                        className="text-decoration-none"
                        onClick={searchProjectHandler}
                    >
                    </Link> */}

                </div>
            </Form>

            <Collapse navbar>
                <Nav className="ml-auto" navbar>

                    {!isLoggedIn && (
                        <NavItem>
                            <NavLink exact to="/auth/sign-in" active tag={RRNavLink}>
                                로그인
                            </NavLink>
                        </NavItem>
                    )}
                    {isLoggedIn && (
                        <>
                            <NavbarDropdown
                                header="새 메시지"
                                footer="모든 메시지 보기"
                                icon={Icon.MessageCircle}
                                count={messages ? messages.length : 0}
                                showBadge={messages && messages.length > 0 ? true : ''}
                            >

                                {messages && messages.map((item, key) => {
                                    return (
                                        <NavbarDropdownItem
                                            key={key}
                                            icon={
                                                <LetteredAvatar
                                                    size={40}
                                                    name={item.nick}
                                                />
                                            }
                                            title={item.nick}
                                            description={item.message}
                                            time={calDate(item.time)}
                                            query=""
                                        />
                                    );
                                })}
                            </NavbarDropdown>
                            <NavbarDropdown
                                header="새 알림"
                                footer="모든 알림 보기"
                                icon={Icon.Bell}
                                count={notifications ? notifications.length : 0}
                                showBadge={notifications && notifications.length > 0 ? true : ''}
                            >
                                {notifications && notifications.slice(0, 5).map((item, key) => {
                                    let icon = <Icon.MessageSquare size={18} className="text-success" />;
                                    let title = '댓글 알림';
                                    let type = '댓글을 쓰셨습니다.';
                                    if (item.comment == 'Love') {
                                        icon = <Icon.Heart size={18} className="text-danger" />;
                                        title = '추천 알림';
                                        type = '추천을 하셨습니다.';
                                    } else if (item.comment == 'kick out') {
                                        icon = <Icon.MinusCircle size={18} className="text-danger" />;
                                        title = '프로젝트 퇴장';
                                        type = "님이 회원님을 프로젝트에서 내보냈습니다.";
                                    } else if (item.comment == 'invite') {
                                        icon = <Icon.Smile size={18} className="text-success" />;
                                        title = '프로젝트 초대';
                                        type = "님이 회원님을 프로젝트에 초대했습니다."
                                    }
                                    else if (item.thread !== 'A')
                                        icon = <Icon.MessageSquare size={18} className="text-info" />;
                                    return (
                                        <NavbarDropdownItem
                                            icon={icon}
                                            key={key}
                                            title={title}
                                            description={
                                                (item.thread === 'pjt') ? (item.member_name + type) :
                                                    (item.thread === 'dlt') ? (item.member_name + type) :
                                                        (item.thread !== 'pjt' && item.thread !== 'dlt' && item.thread !== 'A' && item.thread !== '0') ? (item.member_name + "님이 회원님의 댓글에 댓글을 쓰셨습니다.") :
                                                            (item.thread !== 'pjt' && item.thread !== 'dlt' && item.thread === 'A' || item.thread === '0') && (item.member_name + "님이 회원님의 글에 " + type)

                                            }
                                            time={item.created_datetime}
                                            query={item.query}
                                        />
                                    );
                                })}
                            </NavbarDropdown>
                        </>)}
                    {/* 
      <NavbarDropdown
        header="New Notifications"
        footer="Show all notifications"
        icon={BellOff}
        count={notifications.length}
      >
        {notifications.map((item, key) => {
          let icon = <Bell size={18} className="text-warning" />;

          if (item.type === "important") {
              icon = <AlertCircle size={18} className="text-danger" />;
          }

          if (item.type === "login") {
              icon = <Home size={18} className="text-primary" />;
          }

          if (item.type === "request") {
              icon = <UserPlus size={18} className="text-success" />;
          }

          return (
            <NavbarDropdownItem
              key={key}
              icon={icon}
              title={item.title}
              description={item.description}
              time={item.time}
            />
          );
          })}
          </NavbarDropdown> */}
                    <UncontrolledDropdown nav inNavbar>
                        <span className="d-inline-block d-sm-none">
                            {isLoggedIn && (
                                <DropdownToggle nav caret>
                                    <Icon.Settings size={18} className="align-middle" />
                                </DropdownToggle>
                            )}
                        </span>
                        <span className="d-none d-sm-inline-block">
                            {isLoggedIn && (
                                <DropdownToggle nav caret>
                                    <span className="text-dark">{username}</span>
                                </DropdownToggle>
                            )}
                        </span>
                        <DropdownMenu right>
                            <DropdownItem>
                                <Icon.User size={18} className="align-middle mr-2" />
                Profile
              </DropdownItem>
                            <DropdownItem>
                                <Icon.PieChart size={18} className="align-middle mr-2" />
                  Analytics
                </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>Settings & Privacy</DropdownItem>
                            <DropdownItem>Help</DropdownItem>
                            {isLoggedIn && (
                                <Link to="/auth/reset-password">
                                    <DropdownItem>비밀번호 변경</DropdownItem>
                                </Link>
                            )}
                            {isLoggedIn && (
                                <Link to="/auth/withdrawal">
                                    <DropdownItem>회원탈퇴</DropdownItem>
                                </Link>
                            )}
                            {!isLoggedIn && (
                                <Link to="/auth/sign-in">
                                    <DropdownItem>로그인</DropdownItem>
                                </Link>
                            )}
                            {isLoggedIn && <DropdownItem onClick={logoutHandler}>로그아웃</DropdownItem>}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Collapse>
        </Navbar >
    );
};

//export default NavbarComponent;

export default connect((store) => ({
    app: store.app,
}))(NavbarComponent);
