import React from 'react';

import ToggleButton from '../UI/ToggleButton';

import { Check } from 'react-feather';

const LineCheck = (props) => {
  return (
    <ToggleButton 
      id = {props.id}
      variant = 'light'
      className = {props.checkOrNot
        ?'mb-1 b-0'
        :'mb-1 border rounded-sm'}
      disabled = {props.disabled}
      style = {{cursor: props.disabled ?'not-allowed' :'pointer'}}
      name = {
        <Check 
          color = 
            {props.checkOrNot
              ?'blueviolet'
              :'lightgray'}
          height = {15}
          width = {15}
        />}
          
      onClick = {props.onCheckHandler}
    />
  )

}

export default LineCheck;