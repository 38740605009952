import React from "react";

import LoadingModal from "../progress/LoadingModal";
import translationGIF from "../progress/Translation.gif";
import calculateGIF from "../progress/backTranslation.gif";

const PopupModalList = (props) => {
  const getModalAttributes = (progressState) => {
    // case 1. 'export tmx'
    if (progressState['processing'] && 
        progressState['etc processing'] === 'export tmx') {
      return {
        message: '적용하는중...',
        gif: translationGIF,
        show: true,
        progress: true
      }
    };
    // case 2. 'save file(first time)'
    if (progressState['processing'] &&
        progressState['etc processing'] === 'save file') {
      return {
        message: '적용하는중...',
        gif: calculateGIF,
        show: true,
        progress: true
      }
    };
    // case 3. translation on the edtior inside
    if (progressState['processing'] &&
        progressState['translation'] &&
        progressState['etc processing'] === 're translation') {
      return {
        message: '번역하는중...',
        gif: translationGIF,
        show: true,
        progress: true
      }
    }


    return {
      message: null, 
      gif: null, 
      show: false, 
      progress: false}
  }


  const popupAttributes = {
    'translation': {
      children:'불러오는중...',
      gif: translationGIF,
      progress: false
    },
    // 'file read..': {
    //   children:'불러오는중...',
    //   gif: translationGIF,
    //   progress: false
    // },
    're-translation': {
      children:'번역하는중...',
      gif: translationGIF,
      progress: true
    },
    'saveFile': {
      children:'적용하는중...',
      gif: translationGIF,
      progress: true
    },
    // 'calculate STS': {
    //   children:'번역 검수중...',
    //   gif: calculateGIF,
    //   progress: true
    // }
  }

  return (
    <LoadingModal 
      children={getModalAttributes(props.progressState).message}
      gif={getModalAttributes(props.progressState).gif}
      show = {getModalAttributes(props.progressState).show}
      progress = {getModalAttributes(props.progressState).progress && props.progress
        ?props.progress 
        :null
      }
    />
  )
};

export default PopupModalList;