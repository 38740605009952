import React from 'react';

// UI
import { Modal, Row, Table } from 'react-bootstrap';

const ImportedFiles = (props) => {
  return (
    <Row>
      <Modal.Footer></Modal.Footer>
      <Table>
        <thead>
          <tr>
            <th>
              추가된 파일
            </th>
          </tr>
        </thead>
        <tbody>
          {props.files.map((file, index)=>(
          <tr key = {index}>
            <td>
              {file.name}
            </td>
          </tr>
          ))}
        </tbody>
      </Table>
    </Row>
  )
};

export default ImportedFiles;