import React, { useContext } from 'react';

import { Dropdown, ListGroup } from 'react-bootstrap';

import AssignmentContext from '../contexts/assignment-context';

import axios from 'axios';

import { BACKEND } from '../../../../Constants';

// it need a dynamic path
import avatar from '../../../../assets/img/avatars/manDefault.jpg';
// import { faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
// import { useSyncExternalStore } from 'react';


const UserList = (props) => {
  const assignCtx = useContext(AssignmentContext);

  const putAssignmentInfo = (selectedIndexes, user_no) => {
    const uidList = selectedIndexes.map(
      index => assignCtx.items[index].uid
    );

    const putData = new FormData();
    putData.append('user_no', user_no);
    putData.append('uidList', uidList);
    putData.append('filename', props.filename);
    
    axios.put(`${BACKEND.SERVER}/assignment`, putData
    ).then((response) => {
      // console.log(response)
    }).catch((err)=>{
      // console.log(user_no)
      const user = assignCtx.teamMembers.find(
        member => member.no == user_no
      )
      // alert(`Failed to assign user: ${user.name}`)
      console.log(err);
      alert(`할당에 실패하였습니다. : ${user.name}`)
    })
    
  }
  const assignmentOnSelect = (eventKey, event) => {
    const user_no = eventKey;
    // const sentence_no = props.lineIndex;
    var selectedIndexes = assignCtx.selected.map((item, index) => 
      item === 1? index : ''
    ).filter(String);

    if (selectedIndexes.length === 0) {
      selectedIndexes = [props.lineIndex]
    }
    
    assignCtx.assignItem(selectedIndexes, user_no);
    putAssignmentInfo(selectedIndexes, user_no);
  }
  
  return (
    <Dropdown onSelect={assignmentOnSelect}>
      <Dropdown.Toggle variant="light" size='lg' id="dropdown-basic">
        {`${props.assignUser.name} `}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {assignCtx.teamMembers.map((user) =>
          <Dropdown.Item 
            active = {user.name === props.assignUser.name}
            eventKey = {user.no}
            key = {user.no}
          > 
            <img
              src={avatar}
              width="12"
              height="12"
              className="rounded-circle me-2"
              alt="Avatar"
            />
            {` ${user.name}`}
          </Dropdown.Item>
      )}
      
    </Dropdown.Menu>
    </Dropdown>
  )
  
}

export default UserList;