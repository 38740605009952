import React, { useState, useEffect } from "react";

// wrapper
import Wrapper from "../helpers/Wrapper";

// react-bootstrap
import { Dropdown,
         DropdownButton } from "react-bootstrap";

const DropdownList = (props) => {
    const [item, setItem] = useState(props.items[0]);

    const itemSelectHandler = (direction) => {
        // console.log(direction);
        props.directionSelect(direction);
        setItem(direction);
    };
    const dropdownList = ['normal', 'patent', 'law'];

    return (
        <Wrapper>
            <Dropdown onSelect = {itemSelectHandler}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {item}
                </Dropdown.Toggle>
                <Dropdown.Menu align = 'sm'>
                    {props.items.map((name) => (
                        <React.Fragment key = {name}>
                            <Dropdown.Item eventKey = {name}>
                                {name}
                            </Dropdown.Item>
                        </React.Fragment>)
                    )}
                </Dropdown.Menu>
            </Dropdown> 
        </Wrapper>
    )
    
};

export default DropdownList;