import React, { useReducer } from "react";

import ProjectInfoContext from "./ProjectInfo-context";

import DateTime from "react-datetime";

const defaultProjectInfoState = {
    project: {
      title: "",
      description: "",
      sourceLang: "",
      targetLang: "",
      deadLine: DateTime.moment(),
      currentUserRole: "",
      acceptance: "",
      projectType: 'translation',
      isValid: {
        title: 'invalid.init',
        src:'invalid.init', 
        tgt:'invalid.init',
      }
    },
    LANGUAGEOPTIONS: [
      {key: 0, variableName: 'ko', name: '한국어'},
      {key: 1, variableName: 'en', name: '영어'},
      {key: 2, variableName: 'cn', name: '중국어'},
      {key: 3, variableName: 'jp', name: '일본어'}
    ]
};

const ProjectReducer = (state, action) => {
  if (action.type === 'setProject') {
    return {...state, project: action.project}
  }
  else if (action.type == 'setValueForKey') {
    const project = {...state.project, [action.key]:action.value}
    return {...state, project: project}
  }
  else if (action.type == 'initProject') {
    const project = defaultProjectInfoState.project;
    return {...state, project: project}
  }

};

const ProjectInfoProvider = (props) =>{
  const [projectState, dispatchProjectState] = useReducer(
    ProjectReducer, defaultProjectInfoState);
  
  const initProject = () => {
    dispatchProjectState({type: 'initProject'})
  };

  const setProject = (project) => {
    dispatchProjectState({type: 'setProject', project: project});
  };
  const setValueForKey = (key, value) => {
    dispatchProjectState({type: 'setValueForKey', key: key, value: value})
  };

  
  const projectInfoContext = {
    project: projectState.project,
    LANGUAGEOPTIONS: projectState.LANGUAGEOPTIONS,

    initProject: initProject,
    setProject: setProject,
    setValueForKey: setValueForKey,

  };

  return (
    <ProjectInfoContext.Provider value = {projectInfoContext}>
      {props.children}
    </ProjectInfoContext.Provider>
  )
}

export default ProjectInfoProvider;
export { defaultProjectInfoState };
