import * as types from "../constants";

export function toggleTopNavBar() {
  return {
    type: types.TOPNAVBAR_SITCKY_TOGGLE
  };
}

export function toggleEditorNavBar() {
  return {
    type: types.EDITORNAVBAR_STICKY_TOGGLE
  };
}