import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Col, Container, FormGroup, Input, Row } from "reactstrap";

import TeamContext from "../contexts/TeamContext";
import MemberCard from './MemberCard';

import { BACKEND } from "../../../../Constants";

const TeamList = (props) => {
    const [memberList, setMemberList] = useState([]);
    const teamContext = useContext(TeamContext);
    const getMembers = async () => {
        props.setLoaded(false);
        const token = localStorage.getItem('token');
        const results = await axios.get(
            `${BACKEND.SERVER}/team/getTeamList/${teamContext.project_no}`, {
            headers: {
                "Authorization": `JWT ${token}`
            },
        }
        ).then((response) => {
            if (response.data.length !== 0) {
                //console.log(results.data)
                teamContext.initMember(response.data);
                setMemberList(response.data);
            }
            props.setLoaded(true);
        });
        // console.log(results.data);
        
    };

    useEffect(() => {
        if (teamContext.project_no) {
            props.isActive && getMembers();
        }
    }, [teamContext.project_no, props.isActive]);

    // useEffect(() => {
    //   setMemberList(teamContext.team);
    // }, [teamContext.team])

    return (
        <Row>
            {teamContext.team.map((data) => {
                return (
                    <Col key={data.email}>
                        <MemberCard
                            name={data.name}
                            email={data.email}
                            color={data.color}
                            role={data.role}
                            acceptance={data.acceptance}
                            currentUserRole={props.currentUserRole}

                        />
                    </Col>
                );
            })}
        </Row>
    )
};

export default TeamList;