import React, { useRef, useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import AuthContext from '../../store/auth-context';

import { Button, Card, CardBody, CardHeader, CardTitle, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, InputGroup, Table, UncontrolledDropdown } from "reactstrap";

import { Search } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


const sortList = (list, descending) => {
    return list.sort((discA, discB) => {
        if (descending) {
            return discA.created_datetime >= discB.created_datetime ? 1 : -1;
        } else {
            return discA.created_datetime < discB.created_datetime ? 1 : -1;
        }
    });

};

const SupportList = (props) => {
    const authCtx = useContext(AuthContext);
    // const username = authCtx.username;
    // const useremail = authCtx.useremail;
    const isLoggedIn = authCtx.isLoggedIn;
    const { searchkey, query, showSearch, name, list, setSuppportList, member_no } = props;
    const inputRef = useRef(null);
    const [search, setSearch] = useState("");
    const [chkLoggedIn, setChkLoggedIn] = useState(false)

    useEffect(() => {
        setChkLoggedIn(isLoggedIn)
    }, [isLoggedIn])

    const history = useHistory();
    const location = useLocation();

    // const queryParams = new URLSearchParams(location.search);

    const total = list[0].total_records;
    const styles = { width: "8%" };
    const stylem = { width: "15%" };
    const styleb = { "borderBottom": "hidden" };
    const stylet = { "borderTop": "hidden" };

    const handleChange = (event) => {
        setSearch(event.target.value);
    }

    const handleSubmit = (event) => {
        if (typeof (query) !== 'undefined')
            history.push('/support-search/' + query + '/?searchkey=' + search);
        else history.push('/support-search?searchkey=' + search);
    }

    var url = '';
    if (typeof (query) !== 'undefined') {
        url = '/support-search/' + query;
    } else { url = '/support-search'; }

    return (
        <Card>
            <CardHeader>
                <div className="card-actions float-right">
                    <Form onSubmit={handleSubmit} className="d-sm-inline-block">
                        <InputGroup className="input-group-navbar">
                            {/* <Input type="hidden" name="member_no" value={member_no} /> */}
                            <Input type="text" name="searchkey"
                                innerRef={inputRef}
                                onChange={handleChange}
                                placeholder={searchkey ? searchkey : "게시판 검색"}
                                aria-label="Search" />
                            <Button type="submit" variant="">
                                <Search className="feather" />
                            </Button>
                        </InputGroup>
                    </Form>
                </div>
                <CardTitle tag="h5" className="mb-0">
                    <span>
                        <div>
                            <b>
                                {!showSearch ? name : (<span><FontAwesomeIcon className="mr-4" id="left" icon={faArrowLeft} onClick={() => {
                                    history.push('/support');
                                }} />검색결과: {name}</span>)}</b>{" "}

                            {(member_no && list[0].member_name) ? ` (작성자: ${list[0].member_name})` : ''}
                        </div>

                    </span>
                </CardTitle>
            </CardHeader>
            <CardBody>
                {showSearch && total == 0 && <div className="mb-2 card-actions float-right">검색결과가 없습니다.</div>}
                {showSearch && total > 0 && <div className="mb-2 card-actions float-right">검색결과 {total}</div>}
                <Table>
                    <thead>
                        <tr>
                            <th className="d-none d-sm-table-cell m-0 p-2 text-center" style={styles}>번호</th>
                            <th className="d-none d-sm-table-cell m-0 p-2 text-center" style={stylem}>글쓴이</th>
                            <th className="d-none d-sm-table-cell m-0 p-2 text-center">제목</th>
                            <th className="d-none d-sm-table-cell m-0 p-2 text-center">날짜</th>
                            <th className="d-none d-sm-table-cell m-0 p-2 text-center" style={styles}>조회</th>
                            <th className="d-none d-sm-table-cell m-0 p-2 text-center" style={styles}>추천</th>
                        </tr>
                        <tr style={styleb}>
                            <th className="d-table-cell d-sm-none m-0 pt-2 pb-0 text-center" colSpan={name == '모든 회사' ? "6" : "5"}>제목</th>
                        </tr>
                        <tr style={stylet}>
                            <th className="d-table-cell d-sm-none m-0 pt-0 pb-2 text-center" >번호</th>
                            <th className="d-table-cell d-sm-none m-0 pt-0 pb-2 text-center" >글쓴이</th>
                            <th className="d-table-cell d-sm-none m-0 pt-0 pb-2 text-center" style={stylem}>날짜</th>
                            <th className="d-table-cell d-sm-none m-0 pt-0 pb-2 text-center" style={styles}>조회</th>
                            <th className="d-table-cell d-sm-none m-0 pt-0 pb-2 text-center" style={styles}>추천</th>
                        </tr>
                    </thead>
                    <tbody>
                        {total > 0 && list.map((it, index) =>
                            <React.Fragment key={index}><tr>
                                <td className="d-none d-sm-table-cell text-center" style={styles}>{it.no}</td>

                                <td className="d-none d-sm-table-cell text-center">
                                    <UncontrolledDropdown inNavbar>
                                        <DropdownToggle tag="a">
                                            {it.member_name}
                                        </DropdownToggle>
                                        {!query && <DropdownMenu right>
                                            <Link to={url + `/${it.member_no}`}><DropdownItem>검색</DropdownItem></Link>

                                        </DropdownMenu>}
                                    </UncontrolledDropdown>
                                </td>

                                <td className="d-none d-sm-table-cell text-center"><Link to={`/support/${it.no}`}>
                                    {it.category == 'Q' ? it.title : it.comment}</Link></td>
                                <td className="d-none d-sm-table-cell text-center" style={stylem}>{it.created_datetime}</td>
                                <td className="d-none d-sm-table-cell text-center" style={styles}>{it.category == 'Q' && it.ref}</td>
                                <td className="d-none d-sm-table-cell text-center" style={styles}>{it.category == 'Q' && it.loves}</td></tr>



                                <tr style={styleb}>
                                    <td className="d-table-cell d-sm-none pt-2 pb-0 text-center" colSpan={name == '모든 게시글' ? "6" : "5"}>
                                        <Link to={`/support/${it.no}`}>
                                            {it.category == 'Q' ? it.title : it.comment}
                                        </Link>
                                    </td>
                                </tr>
                                <tr style={stylet}>
                                    <td className="d-table-cell d-sm-none pt-0 pb-2 text-center">{it.no}</td>
                                    <td className="d-table-cell d-sm-none pt-0 pb-2 text-center">
                                        <Link to={`/support/${it.member_no}`}>{it.member_name}</Link>
                                    </td>
                                    <td className="d-table-cell d-sm-none pt-0 pb-2 text-center" style={stylem}>{it.created_datetime}</td>
                                    <td className="d-table-cell d-sm-none pt-0 pb-2 text-center" style={styles}>{it.category == 'Q' && it.ref}</td>
                                    <td className="d-table-cell d-sm-none pt-0 pb-2 text-center" style={styles}>{it.category == 'Q' && it.loves}</td>
                                </tr>
                            </React.Fragment>

                        )}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    )
};

export default SupportList;
