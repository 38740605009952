import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Home, ArrowLeft } from "react-feather";

const Intro = () => {
    const styles = { padding: "10px", border: "solid 1px", borderRadius: "5px" };
    const history = useHistory();

    return (
        <div className="float-center">
            <div>
                <Link to={`/`} className="float-left">
                    <Home />
                </Link>
                <Link onClick={() => { history.go(-1) }} className="float-left">
                    <ArrowLeft />
                </Link>
            </div>
            <br /><br />
            <div>
                <h2>개인정보취급방침</h2>
            </div>
            <br />
            <div >
                <section>
                    <h3>총칙</h3>
                    <div >
                        {/* <h4>1.</h4> */}
                        <p>
                            - 씽캣툴즈(이하 '회사')은 개인정보를 소중히 생각합니다.
              <br />
              - 회사의 개인정보보호정책이란 온라인상에서 개인정보를 보호하여
              회원님들이 안심하고 서비스를 받으실 수 있도록 본사가 준수해야 할
              지침을 말합니다.
              <br />
              - 회사의 개인정보보호정책은 '정보통신망이용촉진등에 관한 법률'상의
              개인정보보호 규정 및 정보통신부가 제정한 '개인정보보호지침'을
              준수합니다.
              <br />
              - 회사는 회원님이 제공하시는 개인정보가 어떻게 보호되며 어떠한
              용도로 이용되는지 회원님들이 쉽게 알 수 있도록 개인정보보호정책
              내용들을 모든 페이지 하단에 공개하고 있습니다.
              <br />- 본 개인정보보호정책은 관련법률 및 정부지침 그리고 회사의
              방침의 변경 등에 의해 수시로 변경될 수 있으므로 자주 확인하시기
              바랍니다
            </p>
                    </div>
                    <br />
                    <h3>본 개인정보 보호정책은 다음과 같은 내용을 담고 있습니다</h3>
                    <br />
                    <div >
                        <h4>회사는 개인정보를 개인의 동의없이 수집하지 않습니다.</h4>
                        <p>
                            회사는 개인정보를 개인의 동의없이 수집하지 않습니다. 회사의
                            개인정보보호정책 또는 이용약관의 내용에 대해 상대방인
                            개인이「동의합니다」버튼 또는 「동의하지 않습니다」버튼을 클릭할
                            수 있는 절차를 제공하여, 개인이「동의합니다」버튼을 클릭하여야
                            비로서 회사의 개인정보수집에 대해 동의한 것으로 봅니다.
            </p>
                    </div>
                    <br />

                    <div >
                        <h4>수집하는 개인정보 항목</h4>
                        <p>
                            회사는 회원 식별 및 최적의 서비스 제공을 위하여 아래와 같은 정보를
                            수집합니다.
            </p>
                        <ol
                            style={{
                                listStyle: "none",
                                listStylePosition: "inside",
                                textIndent: "-20px",
                            }}
                        >
                            <li>
                                - <b>필수항목</b> : 이름, 이메일 주소
              </li>
                            <li>
                                - <b>선택항목</b> : 휴대전화번호(인증), 신용카드번호(인증)
                그리고 회원의 서비스 이용 및 운영과정에서 생성된 아래와 같은
                정보들을 수집할 수 있습니다.
              </li>
                            <li>- 서비스 이용 기록, 접속 로그, 쿠키 등</li>
                            <br />
                        </ol>
                        <p>개인정보 수집방법 : 홈페이지</p>
                    </div>
                    <br />

                    <div >
                        <h4>개인정보의 수집 및 이용</h4>
                        <p>
                            회사는 수집한 개인정보를 다음의 목적을 위해 이용하며 법률에 의한
                            경우가 아니고서는 다른 목적으로 이용되지 않습니다.
            </p>
                        <ol
                            style={{
                                listStyle: "none",
                                listStylePosition: "inside",
                                textIndent: "-20px",
                            }}
                        >
                            <li>
                                <b>- 서비스 제공에 관한 계약 이행 및 요금정산</b>
                                <br />
                콘텐츠 제공, 물품배송 또는 청구서 등 발송, 금융거래 본인 인증 및
                금융 서비스, 구매 및 요금 결제
                <br />
                            </li>

                            <li>
                                <b>- 회원 관리</b>
                                <br />
                개인의 회원가입의사 확인, 서비스이용을 위한 본인확인, 서비스
                이용에 대한 필요사항 고지, 서비스 부정이용 방지, 기타
                서비스이용에 대한 회원의 불만 등 민원처리
              </li>
                            <li>
                                <b>- 개인정보의 이용</b>
                                <br />
                                <b>(마케팅 및 광고에 활용)</b>&nbsp; 이벤트 등 광고성 정보 전달,
                인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도
                파악 또는 회원의 서비스 이용에 대한 통계
              </li>
                        </ol>
                    </div>
                    <br />

                    <div >
                        <h4>개인정보의 보유 및 이용기간</h4>
                        <p>
                            다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
            </p>
                        <ol
                            style={{
                                listStyle: "none",
                                listStylePosition: "inside",
                                textIndent: "-20px",
                            }}
                        >
                            <li>
                                - <b>서비스 이용 관련 개인 정보</b>
                                <br />
                1. 보존 항목 : 서비스 이용기록, 접속로그, 접속 IP 정보
                <br />
                2. 보존 근거 : 통신비밀보호법
                <br />
                3. 보존 기간 : 회원탈퇴 후 3개월
              </li>

                            <li>
                                - <b>대금결제 관련 보존 개인 정보</b>
                                <br />
                1. 보존 항목 : 대금결제 이력
                <br />
                2. 보존 근거 : 전자상거래등에서의 소비자 보호에 관한 법률
                <br />
                3. 보존 기간 : 회원탈퇴 후 5년
              </li>

                            <li>
                                - <b>회원 탈퇴시 보존 개인 정보</b>
                                <br />
                1. 보존 항목 : 이름, 별칭, 로그인 ID(이메일), 비밀번호,
                휴대전화번호, 신용카드정보
                <br />
                2. 보존 근거 : 불량이용자의 재가입 방지, 명예훼손 등 권리침해
                분쟁 및 수사 협조(법규상 근거가 필요함)
                <br />
                3. 보존 기간 : 회원탈퇴 후 1개월
                <br />
                                <br />
                표시/광고에 관한 기록 : 6개월 (전자상거래등에서의 소비자보호에
                관한 법률)
                <br />
                계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의
                소비자보호에 관한 법률)
                <br />
                대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의
                소비자보호에 관한 법률)
                <br />
                소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
                (전자상거래등에서의 소비자보호에 관한 법률)
                <br />
                신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (신용정보의
                이용 및 보호에 관한 법률)
              </li>
                        </ol>
                    </div>
                    <br />

                    <div >
                        <h4>개인정보의 파기에 대한 절차 및 방법</h4>
                        <p>
                            원칙적으로 탈퇴한 회원의 모든 개인정보는 즉시 파기되며, 탈퇴하지
                            않은 회원의 개인정보라도 그 이용목적이 달성된 후에는 지체 없이
                            파기 됩니다. 파기 절차 및 방법은 다음과 같습니다.
            </p>
                        <ol
                            style={{
                                listStyle: "none",
                                listStylePosition: "inside",
                                textIndent: "-20px",
                            }}
                        >
                            <li>
                                - <b>파기절차</b>
                                <br />
                회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후
                회원정보 DB에서 내부 방침 기타 관련 법령에 의한 정보보호 사유에
                따라 일정 기간 저장된 후 파기됩니다.
              </li>
                            <li>
                                - <b>파기방법</b>
                                <br />
                전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는
                기술적 방법을 사용하여 삭제합니다. 종이에 출력된 개인정보는
                분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
              </li>
                        </ol>
                    </div>
                    <br />

                    <div >
                        <h4>개인정보 제공</h4>
                        <p>
                            회사는 원칙적으로 개인정보를 "개인 정보의 수집 및 이용목적"에서
                            고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 외부에
                            공개하지 않습니다.단, 아래의 경우에는 예외로 합니다.
            </p>
                        <ol
                            style={{
                                listStyle: "none",
                                listStylePosition: "inside",
                                textIndent: "-20px",
                            }}
                        >
                            <li>
                                - <b>회원님께서 사전에 동의한 경우</b>
                            </li>
                            <li>
                                -{" "}
                                <b>
                                    법령의 규정에 의하거나 수사 목적으로 법령에 정해진 절차와
                                    방법에 따라 수사기관의 요구가 있는 경우
                </b>
                            </li>
                        </ol>
                    </div>
                    <br />

                    <div >
                        <h4>수집한 개인정보의 위탁</h4>
                        <p>
                            회사는 회원님의 동의없이 회원님의 정보를 외부 업체에 위탁하지
                            않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무
                            내용에 대해 회원님에게 통지하고 필요한 경우 사전 동의를 받도록
                            하겠습니다.
            </p>
                    </div>
                    <br />

                    <div >
                        <h4>회원 자신의 개인정보에 대한 회원의 권리</h4>
                        <p>
                            회원(회원의 법정대리인 포함)은 본인 확인을 거치신 후 언제든지
                            등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 회원
                            가입 해지를 요청할 수 있습니다.
              <br />
                            <br />
              개인정보 조회·수정은 본인이 "개인정보변경"(또는 "회원정보수정")을
              클릭하고 가입 해지는 본인이 "회원탈퇴"를 클릭하는 방법으로
              가능하며 회사 개인정보관리책임자에게 서면, 전화 또는 이메일로
              연락하서도 조치가 이루어집니다.
              <br />
                            <br />
              회원이 개인정보의 오류에 대한 정정을 요청하신 경우에는 회사는
              정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지
              않습니다. 또한 잘못된 개인정보가 제3자에게 이미 제공된 경우에는
              제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
              <br />
                            <br />
              해지 또는 삭제된 개인정보는 "회사가 수집하는 개인정보의 보유 및
              이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는
              이용할 수 없도록 처리하고 있습니다.
            </p>
                    </div>
                    <br />

                    <div >
                        <h4>개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</h4>
                        <p>
                            회사는 회원의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
                            운용합니다. 쿠키란 씽캣툴즈의 웹사이트를 운영하는데 이용되는 서버가
                            회원의 브라우저에 보내는 아주 작은 텍스트 파일로서 회원의 컴퓨터
                            하드디스크에 저장됩니다. 회원은 쿠키 설치에 대한 선택권을 가지고
                            있습니다. 따라서, 회원은 웹브라우저에서 옵션을 설정함으로써 모든
                            쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면
                            모든 쿠키의 저장을 거부할 수도 있습니다.
              <br />
                            <br />
              회사가 쿠키를 운용하는 목적과 회원이 회사의 쿠키사용을 거부하는
              방법은 아래와 같습니다.
            </p>
                        <ol
                            style={{
                                listStyle: "none",
                                listStylePosition: "inside",
                                textIndent: "-20px",
                            }}
                        >
                            <li>
                                ▶ <b>쿠키 등 사용 목적</b>
                                <br />
                회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
                관심분야를 파악, 각종 이벤트 참여 정도 및 방문 회수 파악 자취
                추적을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
              </li>
                            <li>
                                ▶ <b>쿠키 설정 거부 방법</b>
                                <br />
                쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹
                브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를
                저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수
                있습니다.
                <br />
                                <br />
                설정방법 예(인터넷 익스플로어의 경우): 웹 브라우저 상단의 도구 →
                인터넷 옵션 → 개인정보 단, 회원께서 쿠키 설치를 거부하였을 경우
                서비스 제공에 어려움이 있을 수 있습니다.
              </li>
                        </ol>
                    </div>
                    <br />

                    <div >
                        <h4>개인정보에 관한 민원서비스</h4>
                        <p>
                            개인정보 관리책임자
                <br />소 속 : 운영팀
                <br />직 급 : ○ ○
                <br />성 명 : ○ ○ ○
                <br />
              연락처 : 0000 - 0000
                <br />
              E-mail : ○○○○@gmail.com
                <br /><br />
              회원께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호
              관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수
              있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을
              드릴 것입니다. 기타 개인정보침해에 대한 신고나 상담이 필요하신
              경우에는 아래 기관에 문의하시기 바랍니다.
            </p>
                        <ol
                            style={{
                                listStyle: "none",
                                listStylePosition: "inside",
                                textIndent: "-20px",
                            }}
                        >
                            <li>개인분쟁조정위원회 (www.kopico.go.kr)</li>
                            <li>정보보호마크인증위원회 (www.eprivacy.or.kr/02-550-9531~2)</li>
                            <li>대검찰청 인터넷범죄수사센터 (www.spo.go.kr/02-3480-2000)</li>
                            <li>경찰청 사이버안전국 (cyberbureau.police.go.kr/182)</li>
                        </ol>
                    </div>
                </section>
            </div>
        </div>
    );
};

const Personal = () => (
    <Container fluid >
        <Row className="m-auto">
            <Col lg={12} xl={8} >
                <Intro />
            </Col>
        </Row>
    </Container>
);

export default Personal;
