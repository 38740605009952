import React, { useContext, useState, useEffect } from "react";

// react-bootstrap
import { 
  Accordion,
  Navbar, 
  Button, 
  ProgressBar,
  Modal,
  Row, Col, Stack } from "react-bootstrap";

// indeterminate progress bar
import classes from './progressBar.module.css';

// part
import TextEditorTaps from "../TextEditor.taps";

// font-awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

// context
import EditorDataContext from "../../contexts/EditorDataContext";

const BottomOptions = (props) => {
  const [open, setOpen] = useState(false);
  const onClick = (event) => {
    props.available && setOpen(!open);
  };

  useEffect(() => {
    props.activeLine !== null && props.available && setOpen(true);
  }, [props.activeLine])

  const edCtx = useContext(EditorDataContext);

  return (
    <Navbar
      bg="white"
      expand
      className="m-0 p-0"
      // style = {{
      //   position: 'sticky',
      //   bottom: '0',
      //   left: '0',
      //   right: '0',
      //   'z-index': 1030
      // }}
      fixed = {'bottom'}
    >
      
      <Stack gap={1} style = {{width:'100%'}}>
        <Row className = 'm-0'>
          <Col className = 'p-0' sm = {12}>
            {props.progressState['processing'] && 
             props.progressState['translation'] && 
             <Modal
              show = {props.progressState['processing']}
              backdrop='static'
              keyboard={false}
              centered
             >
              <ProgressBar 
                animated 
                now={props.progress} 
                style = {{width: '100%', height:'1rem'}}
              />

             </Modal>
            }

            {props.progressState['sts checking'] && <ProgressBar 
              animated 
              now={props.progress} 
              style = {{width: '100%', height:'0.3rem'}}
            />}
            
            {(props.progressState['processing'] &&
              (props.progressState['file reading'] || 
              props.progressState['tm checking'] ||
              props.progressState['etc processing'])
              ) && <div class= {classes.progressBar}>
              <div class= {classes.progressBarValue}></div>
            </div>}
          </Col>
        </Row>

        <Row onClick = {onClick} className = 'm-0' 
          style={{ 
            cursor: props.available? "pointer" :"not-allowed", 
            boxShadow: '0rem 0.1rem 0.1rem 0px #ededed'
          }}
        >
          
          <Col sm = {12} className = 'text-center'>
            <FontAwesomeIcon icon={!open ? faSortUp : faSortDown} />
          </Col>
        </Row>

        {open && <Row className = 'm-1'>
          <TextEditorTaps
            index = {props.activeLine}
            glossaryActive = {edCtx.glossaryActive} 
            onChangeLine = {props.onChangeLine}
            setGlossaryActive = {props.setGlossaryActive}
            saveToDB = {props.saveToDB}
          />
        </Row>}
        
      </Stack>
    </Navbar>
  );
};

export default BottomOptions;
