import React from "react";

import { Col, Container, Row } from "reactstrap";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Auth = ({ children }) => (
    <React.Fragment>
        <Wrapper>
            <Main>
                <Container className="d-flex flex-column h-100">
                    <Row className="h-100">
                        <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">{children}</div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </Main>
        </Wrapper>
    </React.Fragment>
);

export default Auth;
