import React from "react";
import { Link } from "react-router-dom";

import { Button } from "reactstrap";

const ErrorPage = (props) => {
  return (
    <React.Fragment>
      <div className="text-center">
        <h1 className="display-1 font-weight-bold">
          {props.location.state? props.location.state.status :404}
        </h1>
        <p className="h1">{props.location.state? props.location.state.title  :'잘못된 경로 입니다.'}</p>
        <p className="h2 font-weight-normal mt-3 mb-4">
          {props.location.state? props.location.state.description :'페이지를 찾을 수 없습니다.'}  
        </p>
        <Link to={props.location.state
            ? props.location.state.backUrl
              ?props.location.state.backUrl
              :'/'
            :'/'}>
            <Button color="primary" size="lg">
              돌아가기
            </Button>
        </Link>
      </div>
    </React.Fragment>
  )
};

export default ErrorPage;