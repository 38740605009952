import React, { useReducer } from 'react';

import GlossaryContext from './glossary-context';

const defaultState = {
  items: [],
  tempItems: [],
  editing: []
};

const GlossaryReducer = (state, action) => {
  switch(action.type){
    case 'reflesh': {
      return state
    }
    case 'add': {
      const maxIndex = state.items.length !== 0 
        ?state.items[state.items.length-1].index
        :0;
      const item = {
        'index': maxIndex+1,
        ...action.item
      };
      const updatedItems = state.items.concat(item);
      
      const editStatus = {
        index: maxIndex+1,
        status: true
      };
      
      const editing = state.editing.concat(editStatus);
      return {
        ...state,
        items: updatedItems,
        tempItems: updatedItems,
        editing: editing
        
      }
    };      
    case 'remove': {
      const removedItems = state.items.filter(
        item => item.index != action.index
      );
      const editing = state.editing.filter(
        item => item.index != action.index
      )
      return {
        ...state,
        items: removedItems,
        tempItems: removedItems,
        editing: editing
      }
    };
    case 'setItems': {
      const editing = []

      action.items.map((item)=>{
        editing.push({
          index:item.index,
          status:false
        });
      })

      // deep copy
      // const tempItems = JSON.parse(JSON.stringify(action.items));
      // shallow copy
      const tempItems = action.items;

      return {
        items: action.items,
        tempItems: tempItems,
        editing: editing,
      }
    };
    case 'changeItem': {
      const updateIndex = state.tempItems.findIndex(
        item => item.index == action.index[0]
      );
      if (action.value === ''){
        return state
      }
      const updateItem = state.tempItems[updateIndex];
      const values = updateItem[action.key]
      values[action.index[1]] = action.value
      const updatedItem = {
        ...updateItem,
        [action.key]: values,
        date:action.date
      };
      const updateItems = state.tempItems;
      updateItems[updateIndex] = updatedItem;
      return {
        ...state,
        tempItems: updateItems,
      }
    };
    case 'changeEditing': {
      const changeEditing = state.editing;
      const changeIndex = changeEditing.findIndex(
        item => item.index == action.index
      );
      const editStatus = {
        ...changeEditing[changeIndex],
        status: !changeEditing[changeIndex].status
      };
      changeEditing[changeIndex] = editStatus;

      return {
        ...state,
        editing:changeEditing
      }
    };
    case 'storeItem': {
      const updateItems = state.items;
      const updateIndex = updateItems.findIndex(
        item => item.index == action.index
      );
      const changeEditing = state.editing;
      const editStatus = {
        ...changeEditing[updateIndex],
        status: false
      };

      changeEditing[updateIndex] = editStatus;

      const updateItem = state.tempItems[updateIndex];
      
      const sourceGlossary = action.sourceGlossaries.filter(
        item => item !== ''
      );
      const targetGlossary = action.targetGlossaries.filter(
        item => item !== ''
      );

      if (sourceGlossary.length === 0 && targetGlossary.length === 0){
        const removedItems = state.items.filter(
          item => item.index != action.index
        );
        const editing = state.editing.filter(
          item => item.index != action.index
        )
        return {
          ...state,
          items: removedItems,
          tempItems: removedItems,
          editing: editing
        }
      };
      
      updateItems[updateIndex] = {
        ...updateItem,
        sourceGlossary:sourceGlossary,
        targetGlossary:targetGlossary
      };

      return {
        ...state,
        items: updateItems,
        editing:changeEditing
      }
    };
    case 'addElement': {
      const updateItems = state.tempItems;
      
      const updateIndex = updateItems.findIndex(
        item => item.index == action.index[0]
      );

      const item = updateItems[updateIndex][action.key];

      item.push('');
      const updateItem = {
        ...updateItems[updateIndex],
        [action.key]:item        
      };
      updateItems[updateIndex] = updateItem;

      return {
        ...state,
        tempitems: updateItems
      }
    };
    case 'deleteElement': {
      const updateItems = state.tempItems;
      
      const updateIndex = updateItems.findIndex(
        item => item.index == action.index[0]
      );

      const item = updateItems[updateIndex][action.key];
      item.splice(action.index[1], 1)
      
      const updateItem = {
        ...updateItems[updateIndex],
        [action.key]:item        
      };
      updateItems[updateIndex] = updateItem;

      return {
        ...state,
        tempitems: updateItems
      }
    };

  }

};

const GlossaryProvider = (props) => {
  const [state, dispatchState] = useReducer(
    GlossaryReducer, defaultState
  );
  const reflesh = () => {
    dispatchState({
      type: 'reflesh'
    })
  }
  
  const setItems = (items) => {
    dispatchState({
      type: 'setItems',
      items: items
    })
  };
  const addItem = (item) => {
    dispatchState({
      type: 'add',
      item: item
    })
  };
  const removeItem = (index) => {
    dispatchState({
      type: 'remove',
      index: index
    })
  };
  const changeItem = (index, key, value, date) => {
    dispatchState({
      type: 'changeItem',
      index: index.split('-'),
      key: key,
      value: value,
      date: date
    })
  };
  const changeEditing = (index) => {
    dispatchState({
      type: 'changeEditing',
      index: index
    })
  };
  const storeItem = (index, sourceGlossaries, targetGlossaries) => {
    dispatchState({
      type: 'storeItem',
      index: index,
      sourceGlossaries: sourceGlossaries,
      targetGlossaries: targetGlossaries
    })
  };
  const addElement = (index, key) => {
    dispatchState({
      type: 'addElement',
      index: index.split('-'),
      key: key
    })
  };
  const deleteElement = (index, key) => {
    dispatchState({
      type: 'deleteElement',
      index: index.split('-'),
      key: key
    })
  };

  const glossaryContext = {
    items: state.items,
    tempItems: state.tempItems,
    editing: state.editing,

    setItems,
    addItem,
    removeItem,
    changeItem,
    changeEditing,
    storeItem,
    addElement,
    deleteElement,
    reflesh,
  };

  return (
    <GlossaryContext.Provider value = {glossaryContext} >
      {props.children}
    </GlossaryContext.Provider>
  )
}

export default GlossaryProvider;