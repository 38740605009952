import axios from "axios";
import React, { useState } from "react";
import {
    Button,
    Input,
    Card,
    CardBody,
} from "reactstrap";
import { BACKEND } from "../../Constants";

function ElasticSearch() {
    const [search, setSearch] = useState();
    const [result, setResult] = useState();

    const changeHandler = (event) => {
        event.preventDefault();
        setSearch(event.target.value);
    }
    const clickHandler = () => {
        let url = BACKEND.SERVER + `/search/${search}`
        axios.get(url)
            .then(res => {
                setResult(res.data);
            })
            .catch(err => {
                console.log(err.data);
            })
    }

    return (
        <CardBody>
            <Card>
                <Input placeHolder="회사정보를 검색하세요" onChange={e => { changeHandler(e) }} />
                <Button
                    onClick={clickHandler}
                >
                    검색
                </Button>
            </Card>
            <Card>
                결과: {result}
            </Card>
        </CardBody>
    );
}

export default ElasticSearch;