import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';

import { BACKEND } from '../../../Constants';

const Translate = (data) => {
    const promise = new Promise(async (resolve, reject) => {
        await axios.post(
            `${BACKEND.SERVER}/translate`, data
            ).then((response) => {
                resolve(response.data);
            })
    });
    return promise; 
    
};
export default Translate;