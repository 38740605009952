
const initialState = {
    projectTabIndex: '1'
  };

const statesReducer = (state = initialState, actions) => {
    switch (actions.type) {
      case 'globalState.initialize':
        return initialState
      case 'globalState.setProjectActiveTab':
        return {...state, projectTabIndex: actions.activeTab}
      default:
        return state;
    }
};

export default statesReducer;
