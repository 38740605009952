import React, { useRef, useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import LetteredAvatar from 'react-lettered-avatar';
import AuthContext from "../../store/auth-context";
import NewForm from "./NewForm";

import { Card, CardBody, CardHeader, CardTitle, DropdownItem, DropdownMenu, DropdownToggle, Media, UncontrolledDropdown } from "reactstrap";

import { Trash, MessageSquare, CornerDownRight } from "react-feather";

var pad = function (num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

const humanTime = (input) => {
    var t = input.split(/[- :]/);
    var date = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
    //	var date = new Date(dt);
    var today = Math.round((new Date()).getTime() / 1000);
    var signdate = Math.floor(date.getTime() / 1000);

    const diff = today - signdate;
    var yesterday = today - 86400;
    var year = (new Date(signdate * 1000)).getFullYear().toString();
    var month = ((new Date(signdate * 1000)).getMonth() + 1).toString();
    var date = (new Date(signdate * 1000)).getDate().toString();
    var yester_date = (new Date(yesterday * 1000)).getDate().toString();
    var hour = (new Date(signdate * 1000)).getHours();
    var min = (new Date(signdate * 1000)).getMinutes().toString();
    var time = '';

    if (diff < 60 * 60 * 24) {
        if (date == yester_date) {
            time = '어제' + " " + hour.toString() + ":" + pad(min, 2);
        }
        else if (hour < 12) time = '오전 ';
        else {
            time = '오후 ';
            if (hour > 12) hour -= 12;
        }
        if (date !== yester_date) time += hour.toString() + ":" + pad(min, 2);
    }
    else if (diff < 60 * 60 * 24 * 2 && date == yester_date) {
        time = '어제' + " " + hour.toString() + ":" + pad(min, 2);
    }
    else {
        time = year + "." + pad(month, 2) + "." + pad(date, 2)
        time += " " + hour.toString() + ":" + pad(min, 2);
    }
    return time
}
const CommentList = (props) => {
    const messagesRef = useRef([]);

    const authCtx = useContext(AuthContext);
    const isLoggedIn = authCtx.isLoggedIn;
    const theemail = authCtx.useremail;

    const { setCommentList, list, query } = props;
    const history = useHistory();
    const location = useLocation();
    const [forms, setForms] = useState([]);
    const [comment, setComment] = useState("");

    const commentChange = (data) => {
        setComment(data);
    };

    const insertComment = (fid, index, comment) => {
        if (fid > 0) {
            for (var i = 0; i < list.length; i++) {
                if (list[i]['fid'] === fid) {
                    index = i;
                    break;
                }
            }
        }

        let newarray = [
            ...list.slice(0, index + 1),
            comment,
            ...list.slice(index + 1)
        ];
        //	console.log(index, newarray);
        setCommentList(newarray);
        messagesRef.current[index - 1].scrollIntoView();
        setTimeout(() => {
            comment.style = "";
            let newarray1 = [
                ...list.slice(0, index + 1),
                comment,
                ...list.slice(index + 1)
            ];
            setCommentList(newarray1);

        }, 2000);
    }

    useEffect(() => {
        messagesRef.current = messagesRef.current.slice(0, list.length);
    }, [list]);

    const queryParams = new URLSearchParams(location.search);

    const appendMessage = (id, who, pid, thread, query) => {
        let updatedItems = [...forms];
        list.forEach((it, index) => {
            if (index == id) {
                updatedItems[index] = <NewForm who={who} forms={forms} setForms={setForms} insertComment={insertComment} id={id} fid={pid} thread={thread} query={query} commentChange={(e) => {
                    commentChange(e);
                }} />;
                return;
            }
        });
        setForms(updatedItems);
    }

    function createElements(thread) {
        let number = thread.length - 1;
        var elements = [];
        for (let i = 0; i < number; i++) {
            elements.push(<span key={i} className="ml-4">{" "}</span>);
        }
        return elements;
    }
    // const total = list.length;
    // const stylea = { width: "50%" };
    // const style5 = { width: "50px" };
    // const styleb = { width: "80%" };
    // const stylec = { width: "20%" };
    const stylep = { cursor: "pointer" };
    console.log("check", list)
    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h5" className="mb-0">
                    댓글 {list[0].total_records}
                </CardTitle>
            </CardHeader>
            <CardBody>
                {
                    list.map((it, index) =>
                        <span key={index}><Media className={it.style}>
                            {it.thread.length > 1 && <span>{createElements(it.thread)}</span>}
                            {it.thread.length > 1 && <CornerDownRight className="mr-2" />}

                            <span className="mr-2">
                                <LetteredAvatar
                                    size={40}
                                    name={it.member_name}
                                />
                            </span>
                            <Media body>
                                <small className="float-right text-navy">{humanTime(it.created_datetime)}<br />
                                    <MessageSquare style={stylep} className="mr-4" size={18}
                                        onClick={() => {
                                            appendMessage(index, it.member_email, it.pid, it.thread, query)
                                        }} />
                                    <Trash style={stylep} className="float-right align-middle" size={18}
                                        onClick={() => {
                                            if (!isLoggedIn) alert('먼저 로그인해주세요!');
                                            else if (it.member_email == theemail) {
                                                history.push("/form/delete_comment/" + it.no);
                                            } else {
                                                alert("자신이 작성한 댓글만 삭제할 수 있습니다");
                                            }
                                        }} /></small>
                                <UncontrolledDropdown>
                                    <DropdownToggle tag="a">
                                        <strong>{it.member_name}</strong>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>검색</DropdownItem>
                                        <Link to={`/chats/${it.member_name}`}><DropdownItem>채팅</DropdownItem></Link>
                                    </DropdownMenu>
                                </UncontrolledDropdown><br />
                                <div className="text-muted">{it.comment}</div>
                                <div className="form-inline" ref={el => messagesRef.current[index] = el}>
                                    {forms[index]}
                                </div>
                            </Media>
                        </Media>
                            <hr /></span>
                    )}
            </CardBody>
        </Card>
    )
};

export default CommentList;
