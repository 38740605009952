import React, { useState } from 'react';

// UI
import { Row, Button } from 'react-bootstrap';
import { Circle, X } from 'react-feather';

const CheckButtonItem = (props) => {
  // const [checked, setChecked] = useState(props.checked);

  // const onClick = (event) => {
  //   setChecked(!checked);
  // }
  
  return (
    <Row className = 'ml-3 mb-2 align-middle'>
      <div className='align-middle align-self-center p-1'>
        <Button
          id = {`Button-${props.id}`}
          name = 'clickableButton'
          className = 'p-0 rounded-circle'
          variant="outline-primary"
          size = 'sm'
          style = {{
            height:'12px',
            verticalAlign:'middle',
            outline: 'solid 0.11rem'
          }}
          onClick = {props.onClick}
        >
          {props.available
          ?<Circle 
            fill = {props.checked 
              ?'rgba(102, 102, 255, 1)'
              :'rgba(102, 102, 255, 0)'} 
            size = '10'
            color = {props.checked 
              ?'rgba(102, 102, 255, 1)'
              :'rgba(102, 102, 255, 0)'}
            style = {{
              verticalAlign:'super',
              padding:'0.05rem'
            }}
          />
          :<X 
            size = '10'
            style = {{
              verticalAlign:'super',
              padding:'0.05rem'
            }}
          />}
        </Button>
      </div>
      <div 
        className = 'pl-1 pt-1'
      >
        {props.text}
      </div>
    </Row>
  )
};

export default CheckButtonItem;