import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const Paginator = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <Pagination aria-label="Page navigation example" className="text-center" size="lg">
            <PaginationItem disabled>
                <PaginationLink first href="#" />
            </PaginationItem>
            <PaginationItem disabled>
                <PaginationLink previous href="#" />
            </PaginationItem>
            {pageNumbers.map((number) => {
                let isActive = number === currentPage ? true : false;
                return (
                    <PaginationItem key={number} active={isActive}>
                        <PaginationLink
                            href="#"
                            onClick={() => {
                                paginate(number);
                            }}
                        >
                            {number}
                        </PaginationLink>
                    </PaginationItem>
                );
            })}

            <PaginationItem>
                <PaginationLink next href="#" />
            </PaginationItem>
            <PaginationItem>
                <PaginationLink last href="#" />
            </PaginationItem>
        </Pagination>
    );
};

export default Paginator;
