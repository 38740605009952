import React from "react";

// document tab add by kimhh 22.05.06
import DocumentProvider from "../DocumentTab/contexts/DocumentProvider";

// tmx tab add by kimhh 22.01.27
import TmxProvider from "../TmxTab/contexts/TmxProvider";

// team tab kimhh 22.05.04
import TeamProvider from "../TeamTab/contexts/TeamProvider";

// glossary tab kimhh 22.06.09
import GlossaryProvider from "../GlossaryTab/contexts/GlossaryProvider";


const ProjectProvider = (props) => {
  return (
    <DocumentProvider>
      <TmxProvider>
        <TeamProvider>
          <GlossaryProvider>
            {props.children}
          </GlossaryProvider>
        </TeamProvider>
      </TmxProvider>
    </DocumentProvider>
  )
}

export default ProjectProvider;