import React, { useState, useContext, useEffect } from "react";

// wrapper
import Wrapper from "../helpers/Wrapper"

// parts
import TMCheckList from "./TMCheckList";

// react-bootstrap
import {Button} from 'react-bootstrap';

// redux
// import { useSelector } from 'react-redux';

// context
import EditorDataContext from "../contexts/EditorDataContext";

const TmCheck = (props) => {
    const [buttonVariant, setButtonVariant] = useState('primary');
    const [showModal, setShowModal] = useState(false);
    const [getedScore, setGetedScore] = useState(false);
    const edCtx = useContext(EditorDataContext);

    // const targetSentence = useSelector((state) => state.editorData.firstTargetSentence);
    const targetSentence = edCtx.firstTargetSentence;

    const getHighScoreMatch = (tmxmatch) => {
        if (tmxmatch.length > 0){
            props.setMatchScore(tmxmatch[0].score[0]);
            setGetedScore(true);
        }
    };

    useEffect(() => {
        if (props.tmxmatch !== undefined && props.tmxmatch !== 'empty'){
            getHighScoreMatch(props.tmxmatch);
        }
    }, [props.tmxmatch]);

    useEffect(() => {
        if (props.highMatchScore > 90) {
            setButtonVariant('primary');
        }
        else if (props.highMatchScore > 80) {
            setButtonVariant('success');
        }
        else if (props.highMatchScore > 70) {
            setButtonVariant('warning');
        }
    }, [props.highMatchScore]);

    const getTMScore = (score) => {
        const text = (score === -1) ?'기계 번역' :`번역 메모리 ${score}%`
        return text
    };

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const selectList = (id, isTmx) => {
        if (!props.disabled) {
            const target = isTmx ?props.tmxmatch[id].tmx_target[0] :targetSentence[id]
            const score  = isTmx ?props.tmxmatch[id].score[0] :-1
            // if (target[0,2] !== <p>)
            
            props.changeLine(props.index, target);
            props.setMatchScore(score);
        }
    };
    return (
        <Wrapper>
            {(props.tmxmatch !== 'empty') && <Button
                id = {props.index}
                className = 'p-0'
                label = 'TM'
                variant = {buttonVariant}
                size = 'sm'
                onClick = {handleShow}
                style = {{width: '100%'}}
            >
                    {getTMScore(props.highMatchScore)}
            </Button>}
            {getedScore && <TMCheckList
                index = {props.index}
                tmxmatch = {props.tmxmatch}
                srcLine = {props.srcLine}
                tgtLine = {props.tgtLine}
                
                show = {showModal}
                selectList = {selectList}
                handleClose = {handleClose} 
                disabled = {props.disabled}    
            />
            }
        </Wrapper>
    )
};

export default TmCheck;
