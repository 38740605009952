import axios from "axios";
import { getDateTime } from "./glossary.utils";

import { BACKEND } from "../../../../Constants";


const modifyItem = (sourceGlossaries, targetGlossaries, project_no, userNo, name, index) => {
  const [date, times] = getDateTime();
  
  const data = new FormData();
  data.append('project_no', project_no);
  data.append('user_no', userNo);
  data.append('owner', userNo);
  data.append('fileName', name);
  data.append('lineNumber', index);
  data.append('src', JSON.stringify(sourceGlossaries));
  data.append('tgt', JSON.stringify(targetGlossaries));
  data.append('modifiedDate', `${date} ${times}`);
  data.append('process', 'glossary.modifyLine');

  axios.put(`${BACKEND.SERVER}/files`, data
    ).then((response) => {
      // console.log(response)
    })
};

export { modifyItem };