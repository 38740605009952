import React, {
  useState,
  useReducer,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import Wrapper from "../helpers/Wrapper";

// files
import Read from "../files/Read";
// import Save from "../files/Save";
// import Export2Tmx from "../files/Export2Tmx";

// parts
import TextEditors from "./TextEditors";
import TopOptions from "./TopOptions";
import BottomOptions from "./bottomOptions/BottomOptions.navbar";

// translate
import BackTranslate from "../translate/BackTranslate";

// modal list
import PopupModalList from "./PopupModal.List";

// service
import service from "../../../services/FileUploadService"

// context
import EditorDataContext from "../contexts/EditorDataContext";
import reducerActions from "../contexts/reducerActions";

//mainFuctions
import MainFunctions from "./MainFunctions";

// get number of valid lines
const linesNumberReducer = (state, action) => {
  return state + 1;
};

const OnlyInspection = (props) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const ownerId = userInfo ? userInfo.userUid : "nonMember";
  const userEmail = userInfo ? userInfo.userEmail : null;

  const observerTarget = useRef(null);
  const history = useHistory();
  const edCtx = useContext(EditorDataContext);

  const fileName          = edCtx.fileName;
  const sourceSentence    = edCtx.sourceSentence;
  const targetSentence    = edCtx.targetSentence;
  const modifiedOrNotDict = edCtx.modifiedOrNotDict;
  const checkedOrNotDict  = edCtx.checkedOrNotDict;
  const editorStates      = edCtx.editorStates;
  const loadedSrcLanguage = edCtx.loadedSrcLanguage;
  const direction         = edCtx.direction;
  const domain            = edCtx.domain;
  const progressState     = edCtx.progressState;
  const fetchedLineNumber = edCtx.fetchedLineNumber;
  const accumulatedNumber = edCtx.accumulatedNumber;
  const dispatch          = edCtx.dispatch;

  // progress state
  // const [progressState, setProgressState] = useState('ready');
  // const [progressState, dispatchProgressState] = useReducer(
  //     MainFunctions.progressReducer, {state: 'ready'}
  // );
  const [progressBar, setProgressBar] = useState(0);
  const [waiting, setWaiting] = useState(false);
  const [completion, setCompletion] = useState(false);

  // editor state (textarea or web editor)
  const onEditorStateHandler = (event) => {
    let id = "";
    if (event.target.id) {
      id = event.target.id;
    } else if (event.currentTarget.id) {
      id = event.currentTarget.id;
    } else {
      id = event.currentTarget.htmlFor;
    }
    //console.log(id);
    const stateDict = MainFunctions.setEditorState(editorStates, {
      key: id,
      formState: "change form",
    });
    reducerActions.setEditorStates(dispatch, stateDict);
  };

  // check to modified or not
  const onModifedOrNotHandler = (props) => {
    const modifiedOrNot = MainFunctions.setModifedOrNot(
      modifiedOrNotDict,
      props
    );
    //console.log(modifiedOrNot);
    reducerActions.setModifiedOrNotDict(dispatch, modifiedOrNot);
  };

  const [linesNumber, increaseLinesNumber] = useReducer(linesNumberReducer, 0);

  // check to user checking or not
  const onCheckedOrNotHandler = (event) => {
    const id = event.currentTarget.id;
    const checkedOrNot = MainFunctions.setCheckedOrNot(checkedOrNotDict, {
      key: id,
    });
    reducerActions.setCheckedOrNotDict(dispatch, checkedOrNot);
  };

  // file read
  const readHandler = (fileName) => {
    reducerActions.setProgressState(dispatch, {
      'processing': true, 'file reading': true});
    reducerActions.setFileName(dispatch, fileName);
    const process = `tmx.fetched-${fetchedLineNumber}`;
    Read(fileName, ownerId, props.project_no, process)
      .then((response) => {
        // console.log(response)
        const data = MainFunctions.intializeState(
          response.data.source,
          response.data.source.length,
          fetchedLineNumber
        );
        const [stateDict, modifiedOrNot, checkedOrNot, number] = data;
        reducerActions.setLinesNumber(dispatch, response.data.count);
        const item = {
          modifiedOrNotDict: modifiedOrNot,
          checkedOrNotDict: checkedOrNot,
          editorStates: stateDict,
          sourceSentence: response.data.source,
          maxLinesNumber: response.data.count,
          accumulatedNumber: number,
          postEdit_count: response.data.postEdit_count
        };
        reducerActions.extendTargetSentence(dispatch, response.data.target);
        reducerActions.fileReadReducer(dispatch, item);
        // reducerActions.extendGlossaryItems(dispatch, []);
        // reducerActions.extendGlossaryMatchedIndex(dispatch, []);
        reducerActions.extendTargetSentenceAccessibility(
          dispatch,
          response.data.accessibility
        );
        response.data.scores.map((value) => {
          reducerActions.assignItemStsScoreDict(dispatch, value);
        })
        // console.log(accumulatedNumber, number)
        setCompletion(response.data.count <= accumulatedNumber + number);
      })
      .then(() => {
        reducerActions.setLoadedSrcLanguage(dispatch, true);
        reducerActions.setProgressState(dispatch, {
          'file reading': false});
        setWaiting(false);
      })
      .catch((err) => {
        console.log(err);
        setWaiting(false);
        if (err.response) {
          switch (err.response.status) {
            case 401:
              history.push({pathname: '/error/401', state: {
                status: 401, title: '로그인이 필요 합니다.'}});
              break;
            case 404:
              history.push({pathname: '/error/404', state: {
                status: 404, title: '권한이 없거나 접근 할 수 없습니다.'}});
              break;
            case 500:
              history.push({pathname: '/error/500', state: {
                  status: 500, title: '내부 서버 에러'}});
              break;
            default:
              history.push({pathname: '/error/404', state: {
                  status: 404, title: '권한이 없거나 접근 할 수 없습니다.'}});
            }
        }
        else {
          history.push('/unauthorized');
        }
        reducerActions.setLoadedSrcLanguage(dispatch, false);
      });
  };

  // get sts score
  const getSTSScore = (src, tgt, indice = null) => {
    reducerActions.setProgressState(dispatch, {
      'processing': true,
      'sts checking': !indice? true : false,
      'etc processing': !indice? null : 'post-editing one'
    });
    setProgressBar(1);
          
    const backTranslateData = new FormData();

    if (src === null || tgt === null) {
      src = sourceSentence;
      tgt = targetSentence;
    } else {
      src = [src];
      tgt = [tgt];
    }
    backTranslateData.append("source", !indice? null : JSON.stringify(src.slice()));
    // backTranslateData.append("target", JSON.stringify(tgt.slice()));
    backTranslateData.append("target", !indice? null : JSON.stringify(tgt.slice()));
    backTranslateData.append("filename", fileName);
    backTranslateData.append("domain", domain);
    backTranslateData.append("direction", direction);
    backTranslateData.append("project_no", props.project_no ? props.project_no : "tempFiles");
    backTranslateData.append("ownerEmail", userEmail);
    backTranslateData.append("ownerId", ownerId);
    backTranslateData.append("process", !indice? 'get job count' : 'post-editing one');
    backTranslateData.append("indices", indice);

    BackTranslate(backTranslateData).then(async (response) => {
      if (response.status === 'got job count') {
        backTranslateData.set("process", 'post-editing part');
        const jobCount = response.data.length;
        for (const [iter_seq, iter] of response.data.entries()) {
        // Promise.all(response.data.map(async (iter, iter_seq) => {
          // backTranslateData.set("source", JSON.stringify(src.slice(iter[1], iter[2])));
          // backTranslateData.set("target", JSON.stringify(tgt.slice(iter[1], iter[2])));
          backTranslateData.set("indices", iter);
          await BackTranslate(backTranslateData).then((response) => {
            if (response.status === 'got post-editing part') {
              response.data[0].map((score, index) => {
                reducerActions.assignItemStsScoreDict(dispatch, [(iter[1] + index).toString(), score]);
              })
              reducerActions.setPostEditCount(dispatch, response.data[1]);
              setProgressBar(((iter[0]+1) / jobCount) * 100);
            }
          })
        }
        // )).then(() => {
        //   reducerActions.setProgressState(dispatch, "translated");
        // })
        reducerActions.setProgressState(dispatch, {
          'sts checking': false
        });
      }
      else if (response.status === 'got post-editing one') {
        reducerActions.assignItemStsScoreDict(dispatch, [indice.toString(), response.data[0][0]]);
        reducerActions.setPostEditCount(dispatch, response.data[1]);
        reducerActions.setProgressState(dispatch, {
          'etc processing': null
        });
        
      }
      else {
        alert('일시적으로 기능을 사용 할 수 없습니다. 문제에 대해 문의 주시길 바랍니다.')
      }
    });
  };

  const saveToDB = (text) => {
    if (text.line !== targetSentence[text.key]){
      service.put(
        fileName,  
        props.project_no ? props.project_no : "tempFiles",
        userEmail, 
        ownerId,
        checkedOrNotDict[text.key] === false ? 0 : 1,
        text.line,
        text.key
      ).then((response) => {
        reducerActions.setPostEditCount(dispatch, response.data.postEdit_count)
      }).catch(()=>{})
      reducerActions.assignItemStsScoreDict(dispatch, [text.key, -1]);
    }
  };

  // text modify
  const onChangeLines = (props) => {
    const { key, line } = props;
    const lines = targetSentence.slice();

    if (lines[key] !== line) {
      lines[key] = line;
      reducerActions.setTargetSentence(dispatch, lines);
      onModifedOrNotHandler({ key });
    }
  };

  // text modify for one line
  const onChangeLine = (key, line, defaultSize = 20) => {
    const lines = targetSentence.slice();
    const setLine = `<p style=\"text-align: left;\"><span style="font-size: ${defaultSize}px;">${line}</span></p>`;
    lines[key] = setLine;
    
    reducerActions.setTargetSentence(dispatch, lines);
    onModifedOrNotHandler({ key });
  };

  const onIntersection = ([entry], observer) => {
    if (!waiting && entry.isIntersecting && !progressState['file reading']) {
      setWaiting(true);
      !completion && readHandler(props.currentFile);
    }
  };

  const emptyfunction = () => {};
  const asnycEmptyfunction = () => {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  };

  // -- use Effect start --
  // editor data initialize
  useEffect(() => {
    reducerActions.initialize(dispatch);
    setWaiting(false);
  }, []);

  // useEffect(() => {
  //     progressState.state == 'translated' && getSTSScore(
  //         sourceSentence, targetSentence);
  // }, [targetSentence])

  // file read & translation (get src, tgt lines)
  useEffect(() => {
    reducerActions.setProgressState(dispatch, {
      'processing': false,
      'file reading': false,
      'translation': false,
      'sts checking': false,
      'tm checking': false,
      'etc processing': null,
      'point': 0
    });
    reducerActions.setDirection(dispatch, props.direction);
    readHandler(props.currentFile);
    reducerActions.setLoadedSrcLanguage(dispatch, true);
  }, [dispatch])

  // observing
  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection, {
      threshold: 0.5,
    });
    observerTarget.current && observer.observe(observerTarget.current);

    return () => observer && observer.disconnect();
  }, [onIntersection]);

  useEffect(() => {
    if (
      progressState['processing'] &&
      !progressState['file reading'] &&
      !progressState['translation'] &&
      !progressState['sts checking'] &&
      !progressState['tm checking'] &&
      !progressState['etc processing'] &&
      progressState['point'] === 0
    ) {
      reducerActions.setProgressState(dispatch, {
        'processing': false,
      });
    }
  }, [progressState])

  // console.log(sourceSentence)
  // console.log(fetchedLineNumber)
  // console.log(accumulatedNumber)
  // console.log(completion, waiting)
  // console.log(props.acceptance)
  // console.log(progressState)

  return (
    <Wrapper>
      <TopOptions
        stsCheck={getSTSScore}
        project_no={props.project_no}
        translation={emptyfunction}
        onSaveButton={asnycEmptyfunction}
        currentFile={fileName}
        limitedOptions={true}
        acceptance={
          props.acceptance
            ? props.acceptance === "accepted"
              ? true
              : false
            : true
        }
        waiting={waiting}
        editorType = '검수'
      />
      <PopupModalList 
        progressState = {progressState}
        progress = {progressBar}
      />
      <TextEditors
        changeLines={onChangeLines}
        changeLine={onChangeLine}
        onLineSelect = {emptyfunction}
        onEditorStateHandler={onEditorStateHandler}
        onModifedOrNotHandler={onModifedOrNotHandler}
        onCheckedOrNotHandler={null}
        getSTSScore={getSTSScore}
        saveToDB={saveToDB}
        onlyInspection={true}
        ownerId={ownerId}
        observerTarget={observerTarget}
        completion={completion}
        editorType = '검수'
      />
      <BottomOptions 
        progressState = {progressState}
        progress = {progressBar}
      />
      
    </Wrapper>
  );
};

export default OnlyInspection;
