import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import UploadService from "../../../../services/FileUploadService";
import { useHistory } from "react-router-dom";

import {
  Card, Button, Row
} from "react-bootstrap";
import { FilePlus } from 'react-feather';

import { useDropzone } from 'react-dropzone';

// utils
import checktoValidFile from '../../../upload/utils/checkValidExt';
import { direction2code } from '../../../../services/utils';
// context
import DocumentContext from "../contexts/document-context";

// service
import Translate from "../../../WebEditor/translate/Translate";
// upload options
import UploadOptionsRow from "./UploadOptionsRow";


const UploadFile = (props) => {
  //const {project_no, parentUpdate} = props;
  const history = useHistory();
  
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const email = userInfo ? userInfo.userEmail : null;
  const owner_id = userInfo ? userInfo.userUid : "nonMember";

  const docCtx = useContext(DocumentContext);
  const domain = docCtx.translationDomain;
  const autoTL = docCtx.autoTranslation;
  
  const [assembleProgress, setAssembleProgress] = useState(80);
  const [directionCode, setDirectionCode] = useState('');
  const [project_no, setProject_no] = useState();

  const translation_part = async (sequence, fileData, translateData, jobCount) => {
    translateData.append('returnData', false);
    translateData.append('lastJob', false);
    for (const [index, indices] of sequence.entries()) {
      translateData.set("indices", indices);
      if (index === sequence.length-1) {
        translateData.set('lastJob', true);
      }
      await Translate(translateData).then((response) => {
        // console.log(response)
        const fileState = {
          "filename" : fileData.filename,
          "status": 'translation',
          'progress': ((response.process + 1) / jobCount) * 100
        }
        docCtx.changeState(fileState)

      }).catch((err) => {
        console.log(err);
      });
    }
  };

  const getExpectedProcessTime = (jobCount,
    batch = 32, jobforSecond = 3, startProcess = 80, endProcess = 95) => {
    const expectedTime = (jobCount * batch)/jobforSecond;
    const increasePoint = (endProcess - startProcess)/expectedTime;

    return [expectedTime, increasePoint, endProcess]
  };

  const increaseProcess = (current, increasePoint, endProcess) => {
    const processLimit = endProcess;
    const process = current >= processLimit? processLimit :current += increasePoint;

    return process
  };

  const uploadAndTranslation = (acceptedFiles) => {
    const t0 = performance.now();
    const files = checktoValidFile(acceptedFiles);
    
    files.map((file) => {
      const newfile = { 
        "project_no": project_no,
        "filename" : file.name,
        "filesize" : file.size,
        "uploaded" : new Date().toLocaleString() + '',
        "trans_percent": 0.0,
        "postEdit_percent": 0.0,
        "randomCreateName": '',
        "status": 'translation',
        'progress': 0,
        'direction': directionCode,
      };

      docCtx.addItem(newfile);

      UploadService.upload(
        file, email, props.project_no, true, ()=> {}
      ).then((response) => {
        if (response.data.status === 'duplicateFile') {
          alert(response.data.message);
        }
        else if (response.data.status === 'waiting for translation') {
          const fileState = {
            "filename" : newfile.filename,
            "randomCreateName": response.data.filename,
          }
          docCtx.changeState(fileState);

          const translationData = new FormData();
          translationData.append('filename', newfile.filename);
          translationData.append('domain', domain);
          translationData.append('direction', directionCode);
          translationData.append('project_no', project_no);
          translationData.append('ownerEmail', email);
          translationData.append('ownerId', owner_id);
          translationData.append('force', 'true');
          translationData.append('process', autoTL?'getJobCount' :'manual_translation');
          translationData.append("nextProcess", false);

          Translate(translationData
            ).then((response) => {
              if (response.status === 'manual translation') {
                const fileState = {
                  "filename": newfile.filename,
                  'status': 'translated',
                  'progress': 100
                };
                docCtx.changeState(fileState);
              }
              else {
                translationData.set('process', 'translation_part');
                translationData.append('indices', "");
                translationData.append('dataPath', response.data[1]);
                const jobCount = response.data[0].length;

                // const [expectedTime, increasePoint, endProcess] = getExpectedProcessTime(jobCount);
                
                translation_part(response.data[0], newfile, translationData, jobCount
                  ).then(() => {
                    const fileState = {
                      "filename" : newfile.filename,
                      "status": 'translation done',
                      'progress': 100
                    };
                    docCtx.changeState(fileState);

                    const t1 = performance.now();
                    // for test
                    // console.log('execution time(s): ')
                    // console.log((t1 - t0)/1000)
                  }).catch((error) => {
                    console.log(error);
                  })

              }
            }).catch((error) => {
              console.log(error);
            })
        }
      }).catch((error) => {
        console.log(error);
      });
    });
  };

  useEffect(() => {
    setDirectionCode(direction2code(
      props.directionSrc, props.directionTgt));
    setProject_no(props.project_no);
  }, [props.project_no, props.directionSrc, props.directionTgt])
  
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    uploadAndTranslation(acceptedFiles);
  }, [directionCode, project_no, domain, autoTL])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
    <Card style = {{width:'100%'}} className = 'mb-2'>
        <div 
          {...getRootProps()}
          style={{
            'border': '2px dashed',
            'textAlign': 'center'}}>
        <input {...getInputProps()} />
        {isDragActive 
          ? 
          <>
            <FilePlus size = '40' className="mt-4 mb-4" />
            <h5>드롭하여 파일 업로드</h5>
          </> 
          : 
          <>
            <FilePlus size = '40' className="mt-4 mb-4" />
            <h6>지원되는 파일: docx, pdf, srt, smi, txt</h6>
            <h5>클릭 또는 드래그 앤 드롭하여 파일 업로드</h5>
          </> 
        }
        </div>
        <UploadOptionsRow />
    </Card>
  );
};

export default UploadFile;
