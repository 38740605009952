import React, { useState, useEffect } from "react";
import axios from "axios";


import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Progress,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";

import { MoreHorizontal } from "react-feather";

import { useHistory } from "react-router";

import { BACKEND } from "../../../../Constants";

const Project = ({
  no,
  title,
  trans_percentage,
  postEdit_percentage,
  participants,
  description,
  sourceLang,
  targetLang,
  deadline,
  deleteProject,
  currentUserRole,
  acceptance,
  projectType,
  }) => {
  const [translationState, setTranslationState] = useState({
    word: '진행중', 
    color: 'warning'});
  const [postEditingState, setPostEditingState] = useState({
    word: '진행중',
    color: 'warning'
  });
  const [mouseOver, setMouseOver] = useState(false);
  const [optionHighlight, setOptionHighlight] = useState(false);
  const history = useHistory();
  
  
  // console.log(currentUserRole, acceptance)
  // 프로젝트 삭제 요청
  const handleDelete = async (no) => {
    setMouseOver(false);
    setOptionHighlight(false);

    const token = localStorage.getItem('token');
    const results = await axios.delete(
      `${BACKEND.SERVER}/project/${no}`, {
        headers: {
          "Authorization": `JWT ${token}`
        },
      }
    );
    if (results.data.message === 'Project deleted') {
      alert('프로젝트가 삭제되었습니다.');
      deleteProject();
    } else {
      alert(results.data.message)
    }
    // console.log(results)
  };

  const handleStar = async () => {
    setMouseOver(false);
    setOptionHighlight(false);
  };

  const editProject = (event) => {
    const region = event.target.getAttribute('name');
    
    region === 'link2edit' && history.push({
      pathname: `/projects/edit/${no}`,
      state: {
        no: no,
        title: title,
        description: description,
        sourceLang: sourceLang,
        targetLang: targetLang,
        deadline: deadline,
        currentUserRole: currentUserRole,
        acceptance: acceptance,
        projectType: projectType
      },
    })
    setMouseOver(false);
    setOptionHighlight(false);
  };

  
  useEffect(() => {
    if (trans_percentage === 100) {
      setTranslationState({word: '완료', color: 'success'})
    } else if (trans_percentage === 0) {
      setTranslationState({word: '대기중', color: 'secondary'})
    } else {
      setTranslationState({word: '진행중', color: 'warning'})
    }

    if (postEdit_percentage === 100) {
      setPostEditingState({word: '완료', color: 'success'})
    } else if (postEdit_percentage === 0) {
      setPostEditingState({word: '대기중', color: 'secondary'})
    } else {
      setPostEditingState({word: '진행중', color: 'warning'})
    }

  }, [trans_percentage, postEdit_percentage])

  return (
    <Card
      onMouseOver = {() => {setMouseOver(true)}}
      onMouseOut = {() => {setMouseOver(false)}}
      style = {mouseOver
        ? {borderWidth:'thin', borderColor:'#b3cdf5', cursor: 'pointer'} 
        :{borderWidth:'thin', cursor: 'pointer'}}
      className = 'm-1'
      onClick={editProject}
    >
      <CardHeader className="px-4 pt-4" name = 'link2edit'>
        <div className="card-actions float-right pl-2">
          <UncontrolledDropdown 
            onMouseOver = {() => {setOptionHighlight(true)}}
            onMouseOut = {() => {setOptionHighlight(false)}}
          >
            <DropdownToggle tag="a">
              <MoreHorizontal style = {
                optionHighlight
                ?{stroke:'#0059ff'}
                :{}}
              />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick = {() => {
                  handleStar()
                }}
              >
                즐겨찾기 추가
              </DropdownItem>
              {/* <DropdownItem>프로젝트 설정</DropdownItem> */}
              {currentUserRole === 'PM' &&
                <DropdownItem 
                onClick={() => {
                  handleDelete(no)
                }}>
                프로젝트 삭제
              </DropdownItem>}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <CardTitle className="mb-0 font-weight-bolder text-truncate" name = 'link2edit'>
          <h4 name = 'link2edit'>{title}</h4>
        </CardTitle>
        <Row className = 'mr-1 ml-1' name = 'link2edit'>
          <Badge 
            className = 'pt-1 mr-1' 
            color = {currentUserRole === 'PM'? 'primary' :'info'}
            name = 'link2edit'
          >
            {currentUserRole === 'PM'? '프로젝트 매니저' :'멤버'}
          </Badge>
          
          {currentUserRole !== 'PM' &&
            <Badge 
            className = 'pt-1 mr-1' 
            color = {acceptance === 'accepted'? 'success' :'warning'}
            name = 'link2edit'
          >
            {acceptance === 'accepted'? '수락함' :'수락 대기'}
          </Badge>}
        </Row>
        <Row className = 'mr-1 ml-1' name = 'link2edit'>
          {projectType === 'translation' && <Badge className="my-2 mr-1" color={translationState.color} name = 'link2edit'>
            {translationState.word}
          </Badge>}
          {projectType === 'post-editing' && <Badge className="my-2" color={postEditingState.color} name = 'link2edit'>
            {postEditingState.word}
          </Badge>}
        </Row>
      </CardHeader>
      <CardBody className="px-4 pt-2" name = 'link2edit' >
        <p className="text-truncate" name = 'link2edit'>{description}</p>
        <div className="float-right" name = 'link2edit'>
          {participants[0]} 외 {participants.length - 1}명
        </div>
      </CardBody>
      <CardFooter className = 'p-0' name = 'link2edit'>
        <ListGroup flush name = 'link2edit'>
          {projectType === 'translation' && <ListGroupItem className="px-4 pb-4" name = 'link2edit'>
            <p className="mb-1 font-weight-bold" name = 'link2edit'>
              번역<span className="float-right" name = 'link2edit'>{trans_percentage.toFixed(2)}%</span>
            </p>
            <Progress className="mb-2" value={trans_percentage} name = 'link2edit'/>
          </ListGroupItem>}
          
          {projectType === 'post-editing' && <ListGroupItem className="px-4 pb-4" name = 'link2edit'>
            <p className="mb-1 font-weight-bold" name = 'link2edit'> 
              검수<span className="float-right" name = 'link2edit'>{postEdit_percentage.toFixed(2)}%</span>
            </p>
            <Progress color="warning" value={postEdit_percentage} name = 'link2edit'/>
          </ListGroupItem>}
        </ListGroup>
      </CardFooter>
    </Card>
  );
};

export default Project;
