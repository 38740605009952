// data reducer 
// It is not used by 22.10.12. but instead, use to useContext.



const initData = {
  loginInfo: {},

  fileName: '',
  direction: '',
  domain: '',

  direction: 'ko2en',
  domain: 'normal',
  
  sourceSentence: [],
  targetSentence: [],
  firstTargetSentence: [],
  targetSentenceAccessibility: [],

  sourceStyleDict: [],
  targetStyleDict: [],

  glossaryMatchedIndex: [],

  tmxMatch: [],
  glossaryMatch: [],
  
  modifiedOrNotDict: {},
  checkedOrNotDict: {},
  editorStates: {},
  stsScoreDict: {},

  loadedSrcLanguage: false,

  linesNumber: 0,
  progressState: {
    'processing': false,
    'file reading': false,
    'translation': false,
    'sts checking': false,
    'tm checking': false,
    
    'etc processing': null,
    'point': 0},
  fetchedLineNumber: 0
};

const data = {
  loginInfo: {},

  fileName: '',
  direction: '',
  domain: '',

  direction: 'ko2en',
  domain: 'normal',
  
  sourceSentence: [],
  targetSentence: [],
  firstTargetSentence: [],
  targetSentenceAccessibility: [],

  sourceStyleDict: [],
  targetStyleDict: [],

  glossaryMatchedIndex: [],

  tmxMatch: [],
  glossaryMatch: [],
  
  modifiedOrNotDict: {},
  checkedOrNotDict: {},
  editorStates: {},
  stsScoreDict: {},

  loadedSrcLanguage: false,

  linesNumber: 0,
  progressState: {
    'processing': false,
    'file reading': false,
    'translation': false,
    'sts checking': false,
    'tm checking': false,
    
    'etc processing': null,
    'point': 0},
  fetchedLineNumber: 0
};

const reducer = (state = data, action) => {
  if (action.type === 'EDITOR DATA UPDATE') {
    return {
      ...state,
      [action.key]: action.payload
    }
  };

  if (action.type === 'EDITOR DATA INITIALIZE') {
    return initData;
  };

  if (action.type === 'EDITOR DATA LIST APPEND') {
    const item = action.payload;
    const items = state[action.key];
    items.push(item);
    return {
      ...state,
      [action.key]: items
    }
  };

  if (action.type === 'EDITOR DATA LIST EXTEND') {
    const item = action.payload;
    const items = state[action.key];
    items.push(...item);
    return {
      ...state,
      [action.key]: items
    }
  };

  if (action.type === 'EDTIOR DATA DICT APPEND') {
    const item = action.payload;
    const items = {
      ...state[action.key],
      ...item};
    return {
      ...state,
      [action.key]: items
    }
  };

  // file read
  if (action.type === 'EDITOR DATA FILE READ') {
    const item = action.payload;
    const modifiedOrNotDict = {
      ...state['modifiedOrNotDict'],
      ...item.modifiedOrNotDict
    };
    const checkedOrNotDict = {
      ...state['checkedOrNotDict'],
      ...item.checkedOrNotDict
    };
    const editorStates = {
      ...state['editorStates'],
      ...item.editorStates
    };
    
    const sourceSentence = state['sourceSentence'];
    sourceSentence.push(...item.sourceSentence);
    
    return {
      ...state,
      modifiedOrNotDict: modifiedOrNotDict,
      checkedOrNotDict: checkedOrNotDict,
      editorStates: editorStates,
      sourceSentence: sourceSentence,
      fetchedLineNumber: state.fetchedLineNumber + item.sourceSentence.length
    }
  };

  return state;
};

export default reducer;