import React, { useState, useContext } from 'react';
// import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

// translation
import Translate from '../../WebEditor/translate/Translate';

// contexts
import DocumentContext from '../context/file-context';

// parts
import SelectDirectionModal from './SelectDirectionModal';

const FileTranslation = (props) => {
  const DIRECTION = {
    'ko2en': '한글 -> 영문  ',
    'en2ko': '영문 -> 한글  ',
    '번역 방향  ': '번역 방향  ',
  }
  
  const [direction, setDirection] = useState(
    props.direction === 'unknown'? '번역 방향  ' :props.direction);
  

  const [modalShow, setModalShow] = useState(false);
  const docCtx = useContext(DocumentContext);
  const project_no = props.project_no === 'unknown'? 'tempFiles' :props.project_no

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const email = userInfo ? userInfo.userEmail : null;
  const owner_id = userInfo ? userInfo.userUid : "nonMember";

  const dropdownOnSelect = (eventKey, event) => {
    setDirection(eventKey);
  };

  const getDirection = (key) => {
    if (!key) {
      return '번역 방향  '
    }
    return DIRECTION[key];
  };

  const translation_part = async (sequence, filename, translateData, jobCount) => {
    translateData.append('returnData', false);
    translateData.append('lastJob', false);
    for (const [index, indices] of sequence.entries()) {
      translateData.set("indices", indices);
      if (index === sequence.length-1) {
        translateData.set('lastJob', true);
      }
      await Translate(translateData).then((response) => {
        // console.log(response)
        const fileState = {
          "filename" : filename,
          "status": 'translation',
          'progress': ((response.process + 1) / jobCount) * 100
        }
        docCtx.changeState(fileState)

      }).catch((err) => {
        console.log(err);
      });
    }
  };

  const selectDirection = (direction) => {
    // translation, similar function is there, src\pages\projects\DocumentTab\parts\UploadFile.js
    const translationData = new FormData();
    translationData.append('filename', props.filename);
    translationData.append('domain', 'normal');
    translationData.append('direction', direction);
    translationData.append('project_no', project_no);
    translationData.append('ownerEmail', email);
    translationData.append('ownerId', owner_id);
    translationData.append('force', 'true');
    translationData.append('process', 'getJobCount');
    translationData.append('nextProcess', false);

    const fileState = {
      "filename" : props.filename,
      "status": 'translation',
      'progress': 0
    };
    docCtx.changeState(fileState);
    
    Translate(translationData
      ).then((response) => {
        // console.log(response)
        translationData.set('process', 'translation_part');
        translationData.append('indices', "");
        translationData.append('dataPath', response.data[1]);
        const jobCount = response.data[0].length;

        translation_part(response.data[0], props.filename, translationData, jobCount
          ).then(() => {
            const fileState = {
              'filename': props.filename,
              'status': 'translation done',
              'progress': 100
            };
            docCtx.changeState(fileState);
          }).catch((error) => {
            console.log(error);
          })
      }).catch((error) => {
        console.log(error);
      })
  };

  const DirectionButton = (props) => {
    if (docCtx.items[props.index].isValid.src === 'valid' && 
        docCtx.items[props.index].isValid.tgt === 'valid'){
      return (
        <Button 
          variant="secondary" 
          size = 'sm'
          onClick={() => setModalShow(true)}
        >
          {`${props.sourceLang} -> ${props.targetLang}`}
        </Button>
      )
    } else {
      return (
        <Button 
          variant="primary" 
          size = 'sm'
          onClick={() => setModalShow(true)}
        >
          번역 방향
        </Button>
      )
    }
  };
  
  return (
    <React.Fragment>
      <DirectionButton {...props} />
      <SelectDirectionModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        selectDirection = {selectDirection}

        index={props.index}
        filename={props.filename}
        sourceLang={props.sourceLang}
        targetLang={props.targetLang}
      /> 
    </React.Fragment>
    


  // <Dropdown onSelect={dropdownOnSelect}>
  //   <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
  //     {getDirection(direction)}
  //   </Dropdown.Toggle>
  //   <Dropdown.Menu>
  //     <Dropdown.Item
  //       onClick = {() => selectDirection('ko2en')}
  //       // href={`/translate/${project_no}/${props.filename}/ko2en`} 
  //       eventKey = 'ko2en'
  //     >{'한글 -> 영문  '}
  //     </Dropdown.Item>
  //     <Dropdown.Item 
  //       onClick = {() => selectDirection('en2ko')}
  //       // href={`/translate/${project_no}/${props.filename}/en2ko`}
  //       eventKey = 'en2ko'
  //     >{'영문 -> 한글  '}
  //     </Dropdown.Item>
  //   </Dropdown.Menu>
  // </Dropdown>
  )
  
}

export default FileTranslation;