import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Container } from "reactstrap";

import { useParams } from "react-router-dom";

// components
import ProjectTabs from "./ProjectTabs";
import InputForm from "./InputForm";
// import DateTime from "react-datetime";

// service
import FileUploadService from "../../../../services/FileUploadService";

// context
import ProjectInfoProvider from "../contexts/ProjectInfoProvider";
import ProjectInfoContext from "../contexts/ProjectInfo-context";

// error
import ErrorPage from "../../../../components/ErrorPage";

// resource monitor
import Monitor from "../../../dashboard/monitor/Index";

import { BACKEND } from "../../../../Constants";

const EditProjectPage = ({ location, history }) => {
  // parameters
  const params = useParams();
  const no = location.state ? location.state.no : params.projectNo;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const email = userInfo ? userInfo.userEmail : null;
  const userId = userInfo ? userInfo.userUid : false;

  const projectCtx = useContext(ProjectInfoContext);

  const [availableEdit, setAvailableEdit] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [glossary, setGlossary] = useState([]);
  const [tmxFiles, setTmxFiles] = useState([]);
  const [team, setTeam] = useState([]);

  // functions
  const updateProject = (data, project_no) => {
    const newFile = {
      project_no: project_no,
      filename: data.filename,
      filesize: data.filesize,
      uploaded: data.uploaded,
      trans_percent: data.trans_percent,
      inspec_percent: data.inspec_percent,
      randomCreateName: data.randomCreateName,
    };
    //console.log(newFile);
    const token = localStorage.getItem("token");
    axios
      .post(`${BACKEND.SERVER}/document`, newFile, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        if (res.statusText !== "OK") {
          alert(res.data.message);
        } else if (res.statusText === "OK") {
          if (res.data.document) {
            // console.log("File", documents, newFile);
          }
          // else {
          //     alert(res.data.message);
          // }
          // history.push("/projects");
        }
        // console.log("success: ", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addFilelist = (newFile) => {
    const newFiles = [...documents, newFile];
    setDocuments(newFiles);
    // if (newFiles.length !== 0) {
    //   newFiles.map((data, index) => {
    //     updateProject(data, no);
    //   })
    // }
  };

  const removeDocument = (event) => {
    const removedDoc = documents.filter(
      (item) => item.filename != event.currentTarget.value
    );

    FileUploadService.deleteDocument(
      no,
      email,
      documents[event.currentTarget.id]["filename"],
      "documents"
    );
    setDocuments(removedDoc);
  };

  const onModifyButton = () => {
    // const token = localStorage.getItem('token');
    if (
      projectCtx.project.isValid.title === "valid" &&
      projectCtx.project.isValid.src === "valid" &&
      projectCtx.project.isValid.tgt === "valid"
    ) {
      const data = {
        // headers: {
        //     "Authorization": `JWT ${token}`
        // },

        title: projectCtx.project.title,
        description: projectCtx.project.description,
        source_language: projectCtx.project.sourceLang,
        target_language: projectCtx.project.targetLang,
        deadline: projectCtx.project.deadLine,
        projectType: projectCtx.project.projectType,
      };
      // console.log(data);
      axios
        .put(`${BACKEND.SERVER}/project/${no}`, data)
        .then((res) => {
          // console.log(res)
          if (res.statusText !== "OK") {
            alert(res.status);
          } else {
            alert("프로젝트 설정이 변경되었습니다.");
          }
        })
        .catch((err) => {
          // console.log(err)
          alert("알수없는 오류입니다.");
        });
    } else {
      alert("필수 입력 항목이 올바르지 않습니다.");
    }
  };

  const testFunc = async (event) => {
    event.preventDefault();
    const response = await axios
      .post(`${BACKEND.SERVER}/Test`, null, {
        onDownloadProgress: (event) => {
          console.log(event);
        },
      })
      .then((res) => {
        console.log(res);
      });
    const data = await response.json();
  };

  // effection
  useEffect(() => {
    if (location.state) {
      projectCtx.setProject({
        title: location.state.title,
        description: location.state.description,
        sourceLang: location.state.sourceLang,
        targetLang: location.state.targetLang,
        deadLine: location.state.deadline.split(" ")[0],
        currentUserRole: location.state.currentUserRole,
        acceptance: location.state.acceptance,
        projectType: location.state.projectType,
        isValid: {
          title: "valid",
          src: "valid",
          tgt: "valid",
        },
      });
      if (
        location.state.currentUserRole === "PM" &&
        location.state.acceptance === "accepted"
      ) {
        setAvailableEdit(true);
      }
    } else {
      // direct connection
      if (userId) {
        axios
          .get(`${BACKEND.SERVER}/project/${no}/${userId}`)
          .then((response) => {
            projectCtx.setProject({
              title: response.data.title,
              description: response.data.description,
              sourceLang: response.data.source_language,
              targetLang: response.data.target_language,
              deadLine: response.data.deadline,
              currentUserRole: response.data.currentUserRole,
              acceptance: response.data.acceptance,
              isValid: {
                title: "valid",
                src: "valid",
                tgt: "valid",
              },
            });

            if (
              response.data.currentUserRole === "PM" &&
              response.data.acceptance === "accepted"
            ) {
              setAvailableEdit(true);
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response) {
              switch (err.response.status) {
                case 401:
                  history.push({
                    pathname: "/error/401",
                    state: {
                      status: 401,
                      title: "로그인이 필요 합니다.",
                    },
                  });
                  break;
                case 404:
                  history.push({
                    pathname: "/error/404",
                    state: {
                      status: 404,
                      title: "권한이 없거나 접근 할 수 없습니다.",
                    },
                  });
                  break;
                case 500:
                  history.push({
                    pathname: "/error/500",
                    state: {
                      status: 500,
                      title: "내부 서버 에러",
                    },
                  });
                  break;
                default:
                  history.push({
                    pathname: "/error/404",
                    state: {
                      status: 404,
                      title: "권한이 없거나 접근 할 수 없습니다.",
                    },
                  });
              }
            } else {
              history.push("/unauthorized");
            }
          });
      } else {
        history.push("/unauthorized");
      }
    }
  }, []);

  useEffect(() => {
    !userInfo && history.push("/unauthorized");
  }, [userInfo]);

  //console.log(documents);
  //console.log(tmxFiles);

  return (
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">프로젝트 설정</h1>
      <InputForm
        isEdit={true}
        onModifyButton={onModifyButton}
        availableEdit={availableEdit}
        projectType={projectCtx.project.projectType}
      />
      <Monitor />
      <ProjectTabs
        project_no={no}
        documents={documents}
        glossary={glossary}
        tmxFiles={tmxFiles}
        team={team}
        setDocuments={setDocuments}
        setGlossary={setGlossary}
        setTmxFiles={setTmxFiles}
        setTeam={setTeam}
        removeDocument={removeDocument}
        parentUpdate={addFilelist}
        availableEdit={availableEdit}
        currentUserRole={projectCtx.project.currentUserRole}
        acceptance={projectCtx.project.acceptance}
        projectType={projectCtx.project.projectType}
      />
    </Container>
  );
};

const EditProject = (props) => {
  return (
    <ProjectInfoProvider>
      <EditProjectPage {...props} />
    </ProjectInfoProvider>
  );
};

export default EditProject;
