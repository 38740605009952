import React, { useState } from 'react';
import { ChromePicker } from 'react-color';

const ColorPicker = (props) => {
  const [defaultColor, setDefaultColor] = useState(props.color);
  
  const popover = {
    position: 'absolute',
    zIndex: '2',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  const onChangeComplete = (color) => {
    // console.log(color);
    // console.log(color.rgb);
    
    const colorRgb = `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`
    // console.log(colorRgb);
    props.onColorPicker(colorRgb);
    setDefaultColor(colorRgb);
  }


  return (
    <div style={ popover }>
      <div style={ cover } onClick = {props.onClickColorPicker} />
      <ChromePicker 
        color = {defaultColor}
        onChangeComplete = {onChangeComplete}
        disableAlpha = {true}
      />
    </div>
  )
};

export default ColorPicker;
