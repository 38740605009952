import React, { useReducer } from "react";

import TmxContext from "./tmx-context";

const defaultTmxState = {
    items: [],
    searchKeyword: '',
};

const TmxReducer = (state, action) => {
    if (action.type === 'add') {
        var fileIndex = state.items.length;
        const item = {
            'key': fileIndex+1,
            'index': fileIndex+1,
            ...action.item
        }
        
        var updatedItems = state.items.concat(item);
        //console.log(action.item);
        return {
            ...state,
            items: updatedItems
        }   
    }
    else if (action.type === 'remove') {
        const removedItems = state.items.filter(
            item => item.index != action.index
            
        );
        return {
            ...state,
            items: removedItems
        }
    }
    else if (action.type === 'setItems') {
        return {...state, items: action.items}
    }
    else if (action.type === 'search') {
        return {...state, searchKeyword: action.keyword}
    }
};

const TmxProvider = (props) =>{
    const [tmxState, dispatchTmxState] = useReducer(
        TmxReducer, defaultTmxState);
    
    const setTmxItems = (items) => {
        dispatchTmxState({type: 'setItems', items: items});
    };
    const addTmxFile = (item) => {
        dispatchTmxState({type: 'add', item: item});
    };
    const removeTmxFile = (index) => {
        dispatchTmxState({type: 'remove', index: index});
    };
    const searchTmx = (keyword) => {
        dispatchTmxState({type: 'search', keyword: keyword});
    }
   
    const tmxContext = {
        items: tmxState.items,
        searchKeyword: tmxState.searchKeyword,

        setItems: setTmxItems,
        addItem: addTmxFile,
        removeItem: removeTmxFile,
        search: searchTmx,
    };

    return (
        <TmxContext.Provider value = {tmxContext}>
            {props.children}
        </TmxContext.Provider>
    )
}

export default TmxProvider;
