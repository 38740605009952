import React from "react";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

import { tableData, tableColumns } from "./tm.js";

const RowSelectionTable = () => {
    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        bgColor: "#f8f9fa",
    };

    return (
        <Card>
            <CardHeader>
                <Button color="primary" className="float-left mt-n1">
                    <FontAwesomeIcon icon={faPlus} /> TM 추가
                </Button>
                <FontAwesomeIcon icon={faTrash} pull="right" size="lg" />
                <FontAwesomeIcon icon={faPen} pull="right" size="lg" transform="left-6" />
            </CardHeader>
            <CardBody>
                <BootstrapTable
                    bootstrap4
                    bordered={false}
                    keyField="name"
                    data={tableData}
                    columns={tableColumns}
                    selectRow={selectRow}
                    // pagination={paginationFactory({
                    //     sizePerPage: 5,
                    //     sizePerPageList: [5, 10, 25, 50],
                    // })}
                />
            </CardBody>
        </Card>
    );
};

const Clients = () => (
    <Container fluid className="p-0">
        <h1 className="h3 mb-3">번역 메모리</h1>
        <RowSelectionTable />
    </Container>
);

export default Clients;
