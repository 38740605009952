import React, { useState, useEffect, useCallback } from "react";
import io from "socket.io-client";
import axios from "axios";
import { BACKEND } from "../Constants";

let logoutTimer;

const AuthContext = React.createContext({
    token: "",
    isLoggedIn: false,
    login: (token) => { },
    logout: () => { },
    //>>>
    socket: null,
    username: '',
    useremail: '',
    messages: null,
    notifications: null,
    setMessages: () => { },
    setNotifications: () => { },
    //<<<
});

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime);

    const remainingDuration = adjExpirationTime - currentTime;

    return remainingDuration;
};

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem("token");
    const storedExpirationDate = localStorage.getItem("expirationTime");

    //>>>
    const user = localStorage.getItem('username');
    const email = localStorage.getItem('useremail');
    var storedMessages = [];
    var storedNotifications = [];
    const savedm = localStorage.getItem('messages');
    const savedn = localStorage.getItem('notifications');

    if (savedm !== null) storedMessages = JSON.parse(savedm);
    if (savedn !== null) storedNotifications = JSON.parse(savedn);
    //<<<

    const remainingTime = calculateRemainingTime(storedExpirationDate);

    if (remainingTime <= 60000) {
        localStorage.removeItem("token");
        localStorage.removeItem("expirationTime");
        localStorage.removeItem('userInfo');

        return null;
    }

    return {
        token: storedToken,
        duration: remainingTime,
        // >>>
        username: user,
        useremail: email,
        messages: storedMessages,
        notifications: storedNotifications,
        // <<<
    };
};

export const AuthContextProvider = (props) => {
    const tokenData = retrieveStoredToken();
    let initialToken;
    //>>>
    let initialUser;
    let initialEmail;
    let initialMessages;
    let initialNotifications;
    //<<<
    if (tokenData) {
        initialToken = tokenData.token;
        //>>>
        initialUser = tokenData.username;
        initialEmail = tokenData.useremail;
        initialMessages = tokenData.messages;
        initialNotifications = tokenData.notifications;
        //<<<
    }

    const [token, setToken] = useState(initialToken);
    //>>>
    const [username, setUsername] = useState(initialUser);
    const [useremail, setUseremail] = useState(initialEmail);
    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState(initialMessages);
    const [notifications, setNotifications] = useState(initialNotifications);
    //<<<
    const userIsLoggedIn = !!token;

    const logoutHandler = useCallback(() => {
        setToken(null);
        //>>>
        setMessages([]);
        setNotifications([]);
        //<<<
        localStorage.removeItem("token");
        localStorage.removeItem("expirationTime");
        localStorage.removeItem('userInfo');
        //>>>
        localStorage.removeItem("username");
        localStorage.removeItem("useremail");
        localStorage.removeItem("messages");
        localStorage.removeItem("notifications");
        //<<<

        if (logoutTimer) {
            clearTimeout(logoutTimer);
        }
    }, []);

    const loginHandler = (token, expirationTime) => {
        setToken(token);
        localStorage.setItem("token", token);
        localStorage.setItem("expirationTime", expirationTime);

        const remainingTime = calculateRemainingTime(expirationTime);
        logoutTimer = setTimeout(logoutHandler, remainingTime);
    };
    const nameHandler = (user, email) => {
        setUsername(user);
        setUseremail(email);
        localStorage.setItem("username", user);
        localStorage.setItem("useremail", email);

        const token = localStorage.getItem('token');

        const data = {
            member: email
        };

        axios
            .post(BACKEND.SERVER + "/notifications", data, {
                headers: {
                    "Authorization": `JWT ${token}`
                }
            })
            .then((res) => {
                if (res.statusText !== "OK") {
                    alert(res.data.message);
                } else if (res.statusText === "OK") {
                    setNotifications(res.data);
                    localStorage.setItem("notifications", JSON.stringify(res.data));
                    console.log("success: ", res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .post(BACKEND.SERVER + "/messages", data, {
                headers: {
                    "Authorization": `JWT ${token}`
                }
            })
            .then((res) => {
                if (res.statusText !== "OK") {
                    alert(res.data.message);
                } else if (res.statusText === "OK") {
                    setMessages(res.data);
                    localStorage.setItem("messages", JSON.stringify(res.data));
                    console.log("success: ", res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });

    };

    useEffect(() => {
        if (tokenData) {
            // console.log(tokenData.duration);
            logoutTimer = setTimeout(logoutHandler, tokenData.duration);
        }
    }, [tokenData, logoutHandler]);

    useEffect(() => {
        // if (socket) socket.emit("newUser", useremail);
        var theemail = localStorage.getItem("useremail")
        if (theemail && socket) socket.emit("newUser", theemail)
        //    }, [socket, username]);
    }, [socket]);

    useEffect(() => {
        if (socket) {
            socket.on("getNotification", (data) => {
                setMessages((prev) => [data, ...prev])
            });
            socket.on("getAlert", (data) => {
                console.log(data);
                setNotifications((prev) => [data, ...prev])
            });
        }
    }, [socket]);

    useEffect(() => {
        if (messages) localStorage.setItem("messages", JSON.stringify(messages));
    }, [messages]);

    useEffect(() => {
        if (notifications) localStorage.setItem("notifications", JSON.stringify(notifications));
    }, [notifications]);

    useEffect(() => {
        setSocket(io.connect("https://tools.gabia.io"));
    }, []);

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
        // >>>
        name: nameHandler,
        socket: socket,
        username: username,
        useremail: useremail,
        messages: messages,
        notifications: notifications,
        setMessages: setMessages,
        setNotifications: setNotifications,
        // <<<
    };

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;