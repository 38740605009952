import axios from "axios";

import { BACKEND } from "../Constants";

export default axios.create({
  baseURL: BACKEND.SERVER,
//  baseURL: "http://localhost:5001",
  headers: {
    "Content-type": "application/json",
  },
});
