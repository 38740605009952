import http from "./http-common";

import { BACKEND } from "../Constants";

const upload = (file, owner, project_no, translate, onUploadProgress, kwargs = null) => {
    let formData = new FormData();

    // if (project_no !== 'unknown') {
    //   console.log(`created project_${project_no}`);
    //   console.log(file);
    // }

    formData.append("file", file);
    formData.append('filesize', file.size)
    const owner_ = owner ? owner : null
    formData.append('owner', owner_);
    if (project_no === undefined) {
        formData.append('project_no', 'non-project');
    }
    else {
        formData.append('project_no', project_no);
    }
    formData.append('translate', translate);

    if (kwargs) {
        for (const [key, value] of Object.entries(kwargs)) {
            formData.append(key, value);
        }
    }
    return http.post("/upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    });
};

const save = (
    { file, textarray, humanChecked,
        domain, project_no, user_email,
        process, indices, lastJob, src_path, tgt_path }) => {
    const formData = new FormData();

    formData.append("fileName", file);
    formData.append("lines", JSON.stringify(textarray));
    // formData.append("lines", textarray);
    formData.append('humanChecked', JSON.stringify(humanChecked));
    formData.append('domain', domain);
    formData.append('project_no', project_no);
    formData.append('user_email', user_email);
    formData.append('process', process);
    formData.append('indices', indices);
    formData.append('lastJob', lastJob);

    return http.post("/files", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
};

const getFiles = (project_no, owner_id) => {
    return http.get(`/getFiles/${project_no}/${owner_id}`);
};

const read = (file) => {
    return http.get("/files/" + file);
};

const download = (file) => {
    return http.get("/download/" + file);
};

const put = (fileName, project_no, userEmail, ownerId, humanChecked,
    text, index, process = 'document.put_one_line') => {
    const formData = new FormData();
    formData.append('fileName', fileName);
    formData.append('project_no', project_no);
    formData.append('userEmail', userEmail);
    formData.append('owner', ownerId);
    formData.append('humanChecked', humanChecked);
    formData.append('line', text);
    formData.append('index', index);

    formData.append('process', process);


    return http.put('/files', formData)
};

const deleteDocument = (project_no, owner, filename, table, token) => {
    const data = new FormData();
    const owner_ = owner ? owner : null
    data.append('project_no', project_no);
    data.append('owner', owner_);
    data.append('filename', filename);
    data.append('table', table)
    const header = {
        data: data,
        headers: {
            Authorization: `JWT ${token}`
        },
    }
    return http.delete(`${BACKEND.SERVER}/document`, header);
};

export default {
    read,
    upload,
    put,
    download,
    save,
    getFiles,
    deleteDocument,
};
