import React,  { useState, useEffect } from 'react';
import {
  useParams, useHistory
} from "react-router-dom";

import { useDispatch } from 'react-redux';
import { toggleTopNavBar } from '../../redux/actions/tobNavBarActions'
import { hideSidebar } from '../../redux/actions/sidebarActions';

// import TranslateFile from '../../components/TranslateFile';
// import Loader from './Loader';

import WebEditor from '../WebEditor/parts/Main';
import EditorDataProvider from '../WebEditor/contexts/EditorDataProvider';

// twin component: src\pages\editor\Inspector.js
const Translate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isBlocking, setIsBlocking] = useState(false);

  const params = useParams();
  const direction = params.direction;
  const filename = params.filename;
  const project_no = params.project_no;

  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    dispatch(toggleTopNavBar());
    dispatch(hideSidebar());
  },[])

  useEffect(() => {
    const unblock = history.block((loc, action) => {
      dispatch(toggleTopNavBar());
      return true
    })
    return () => {
      unblock()}
  }, [])

  
  return (
    <EditorDataProvider>
      <WebEditor 
        currentFile={filename} 
        setIsloading={setIsloading}
        direction = {direction} 
        project_no = {project_no}
        acceptance = {history.location.state
          ? history.location.state.acceptance
          : null}
      />
    </EditorDataProvider>
);

}
export default Translate;
