import * as types from "../constants";

const initialState = {
  topNavBarSticky: true,
  editorNavBarSticky: true
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.TOPNAVBAR_SITCKY_TOGGLE:
      return {
        ...state,
        topNavBarSticky: !state.topNavBarSticky
      };
    case types.EDITORNAVBAR_STICKY_TOGGLE:
      return {
        ...state,
        editorNavBarSticky: !state.editorNavBarSticky
      }
    
    default:
      return state;
  }
}
