// import React, { useState, useEffect, useContext } from "react";
import React, { useContext } from "react";
import moment from "moment";

import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback,
  Button,
} from "reactstrap";
import DateTime from "react-datetime";

import ProjectInfoContext from "../contexts/ProjectInfo-context";

// const LANGUAGEOPTIONS = [
//   {key: 0, variableName: 'ko', name: '한국어'},
//   {key: 1, variableName: 'en', name: '영어'},
//   {key: 2, variableName: 'cn', name: '중국어'},
//   {key: 3, variableName: 'jp', name: '일본어'}
// ]

const InputForm = (props) => {
  const projectCtx = useContext(ProjectInfoContext);

  const LANGUAGEOPTIONS = projectCtx.LANGUAGEOPTIONS;
  const yesterday = moment().subtract(1, "day");
  const valid = (current) => {
    return current.isAfter(yesterday);
  };
  const titleChange = (e) => {
    const inputedTitle = e.target.value;
    const titleValid = (
      {...projectCtx.project.isValid, 
      title: inputedTitle === ""? 'invalid' :'valid'})
    projectCtx.setValueForKey('isValid', titleValid);
    projectCtx.setValueForKey('title', inputedTitle);
  };
  const descriptionChange = (e) => {
    projectCtx.setValueForKey('description', e.target.value);
  };
  const sourceLangChange = (e) => {
    const selected = e.target.value;
    projectCtx.setValueForKey('sourceLang', selected);

    if (selected !== '선택') {
      if (selected === projectCtx.project.targetLang) {
        projectCtx.setValueForKey(
          'isValid',
          {...projectCtx.project.isValid, src: 'invalid.Same', tgt: 'invalid.same'}
        )
      }
      else {
        if (selected === '한국어' && projectCtx.project.targetLang === '한국어') {
          projectCtx.setValueForKey(
            'isValid',
            {...projectCtx.project.isValid, src: 'invalid'}
          )
        } 
        else if (selected !== '한국어' && !['한국어', ''].includes(projectCtx.project.targetLang)) {
          projectCtx.setValueForKey(
            'isValid',
            {...projectCtx.project.isValid, src: 'invalid.cannot'}
          )
        }
        else {
          if (projectCtx.project.targetLang !== ''){
            projectCtx.setValueForKey(
              'isValid',
              {...projectCtx.project.isValid, src: 'valid', tgt: 'valid'}
            )  
          }
          else {
            projectCtx.setValueForKey(
              'isValid',
              {...projectCtx.project.isValid, src: 'valid'}
            )
          }
        }
      }
    }
    else {
      projectCtx.setValueForKey(
        'isValid', 
        {...projectCtx.project.isValid, src:'invalid'})
    }
  };

  const targetLangChange = (e) => {
    const selected = e.target.value;
    projectCtx.setValueForKey('targetLang', selected);
    if (selected !== '선택') {
      if (selected !== projectCtx.project.sourceLang) {
        let tgtvalid = {...projectCtx.project.isValid, tgt:'valid'};
        if (projectCtx.project.sourceLang !== "" && projectCtx.project.sourceLang !== '선택'){
          tgtvalid = {...tgtvalid, src:'valid'}
        }
        projectCtx.setValueForKey('isValid', tgtvalid);
      } else {
        projectCtx.setValueForKey(
          'isValid',
          {...projectCtx.project.isValid, src:'invalid.Same', tgt:'invalid.Same'})
      }
    } 
    else {
      projectCtx.setValueForKey(
        'isValid',
        {...projectCtx.project.isValid, tgt:'invalid'})
    }
  };

  const deadlineChange = (e) => {
    projectCtx.setValueForKey('deadLine', e.format("YYYY-MM-DD"));
  };

  const projectTypeChange = (e) => {
    e.target.value === '번역' && projectCtx.setValueForKey('projectType', 'translation');
    e.target.value === '검수' && projectCtx.setValueForKey('projectType', 'post-editing');
  };

  const onCreateButton = (e) => {
    props.onCreateButton();
  };
  const LanguageSelectOptions = (props) => {
    const options = props.source
      ? props.targetLang === '한국어' || props.targetLang === ''
        ? projectCtx.LANGUAGEOPTIONS
        : [{key: 0, variableName: 'ko', name: '한국어'}] 
      : props.sourceLang === '한국어' || props.sourceLang === ''
        ? projectCtx.LANGUAGEOPTIONS
        : [{key: 0, variableName: 'ko', name: '한국어'}];
    
    const currentLang = props.source
      ? props.sourceLang
      : props.targetLang;
    return (
      <React.Fragment>
        {options.map((item, index) => 
          (item.name !== currentLang && (
          <option key = {index}>{item.name}</option>)
        ))}
      </React.Fragment>
    )
  };

  const LanguageInputForm = (props) => {
    return (
      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label className="font-weight-bold">
              시작 언어 *
            </Label>
            <Input 
              type="select"
              name="source_language"
              onChange={sourceLangChange}
              invalid={projectCtx.project.isValid.src !== "valid"? true :false}
              valid={projectCtx.project.isValid.src === "valid"? true :false}
              disabled = {props.disabled}
            >
              {projectCtx.project.sourceLang === "" 
              ?(<option defaultValue>선택</option>)
              :(<option defaultValue>{projectCtx.project.sourceLang}</option>)
              }
              
              {/* {LANGUAGEOPTIONS.map((item, index) => 
                (item.name !== projectCtx.project.sourceLang && (
                <option key = {index}>{item.name}</option>)
              ))} */}
              <LanguageSelectOptions 
                sourceLang = {projectCtx.project.sourceLang}
                targetLang = {projectCtx.project.targetLang}
                source = {true}
              />
            </Input>
            {projectCtx.project.isValid.src === "invalid.init" && (
            <FormFeedback
              invalid={"true"}
            >
              번역할 시작언어를 선택하세요.
            </FormFeedback>
            )}
            {projectCtx.project.isValid.src === "invalid" && (
              <FormFeedback
                tooltip
                invalid={"true"}
                style={{ top: "80%", left: "6px" }}
              >
                번역할 시작언어를 선택하세요.
              </FormFeedback>
            )}
            {projectCtx.project.isValid.src === "invalid.cannot" && (
              <FormFeedback
                tooltip
                invalid={"true"}
                style={{ top: "80%", left: "6px" }}
              >
                해당 번역은 지원하지 않습니다.
              </FormFeedback>
            )}
            {projectCtx.project.isValid.src === "invalid.Same" && (
              <FormFeedback
                tooltip
                invalid={"true"}
                style={{ top: "80%", left: "6px" }}
              >
                시작언어와 목표언어가 같습니다.
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label className="font-weight-bold">
              목표 언어 *
            </Label>
            <Input
              type="select"
              name="target_language"
              onChange={targetLangChange}
              invalid={projectCtx.project.isValid.tgt !== "valid" ? true : false}
              valid={projectCtx.project.isValid.tgt === "valid" ? true : false}
              disabled = {props.disabled}
            >
              {projectCtx.project.targetLang === "" 
              ?(<option defaultValue>선택</option>)
              :(<option defaultValue>{projectCtx.project.targetLang}</option>)
              }
              {/* {LANGUAGEOPTIONS.map((item, index) => 
                (item.name !== projectCtx.project.targetLang && (
                <option key = {index}>{item.name}</option>)
              ))} */}
              <LanguageSelectOptions 
                sourceLang = {projectCtx.project.sourceLang}
                targetLang = {projectCtx.project.targetLang}
                source = {false}
              />
            </Input>
            {projectCtx.project.isValid.tgt === "invalid.init" && (
            <FormFeedback
              invalid={"true"}
            >
              번역할 목표언어를 선택하세요.
            </FormFeedback>
            )}
            {projectCtx.project.isValid.tgt === "invalid" && (
            <FormFeedback
              tooltip
              invalid={"true"}
              style={{ top: "80%", left: "6px" }}
            >
              번역할 목표언어를 선택하세요.
            </FormFeedback>
            )}
            {projectCtx.project.isValid.tgt === "invalid.Same" && (
            <FormFeedback
              tooltip
              invalid={"true"}
              style={{ top: "80%", left: "6px" }}
            >
              시작언어와 목표언어가 같습니다.
            </FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>
      )
  };
  
  return (
    <div>
      <Card className="h-100 mb-1">
        <CardBody className = 'p-3'>
          <Form>
            <Row form>
              <Col md="8">
                <FormGroup>
                  <Label className="font-weight-bold">프로젝트 이름 *</Label>
                  <Input
                    type="title"
                    name="title"
                    value={projectCtx.project.title}
                    placeholder="프로젝트 이름을 입력해주세요"
                    onChange={titleChange}
                    invalid={projectCtx.project.isValid.title !== "valid" ? true : false}
                    valid={projectCtx.project.isValid.title === "valid" ? true : false}
                    disabled = {!props.availableEdit}

                  />
                  {projectCtx.project.isValid.title === "invalid" && (
                  <FormFeedback
                    tooltip
                    invalid={"true"}
                    style={{ top: "18%", left: "6px" }}
                  >
                    프로젝트 이름은 필수로 입력해야합니다.
                  </FormFeedback>
                  )}
                </FormGroup>
                <LanguageInputForm 
                  disabled = {!props.availableEdit}
                  isEdit = {props.isEdit || false}
                />
                <FormGroup>
                  <Label className="font-weight-bold">프로젝트 설명</Label>
                  <Input
                    type="textarea"
                    name="description"
                    value={projectCtx.project.description}
                    placeholder="프로젝트 관련 간략한 설명을 작성해주세요"
                    onChange={descriptionChange}
                    disabled = {!props.availableEdit}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label className="font-weight-bold">마감일</Label>
                  <div className="align-self-center w-100">
                    <DateTime
                      input={true}
                      defaultValue={
                        projectCtx.project.deadLine === undefined
                          ? DateTime.moment()
                          : projectCtx.project.deadLine
                      }
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      onChange={deadlineChange}
                      isValidDate={valid}
                      inputProps = {{
                        disabled : !props.availableEdit}}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label className="font-weight-bold">
                    프로젝트 타입
                  </Label>
                  <Input 
                    type="select"
                    name="projectType"
                    onChange={projectTypeChange}
                    disabled = {true}
                  >
                    <option 
                      key = {0}
                    >
                      {props.projectType === 'translation' && '번역'}
                      {props.projectType === 'post-editing' && '검수'}
                    </option>
                    {/* <option 
                      selected = {props.projectType === 'translation'} 
                      key = {0}
                    >
                      번역
                    </option>
                    <option 
                      key = {1}
                      selected = {props.projectType === 'post-editing'}
                    >
                      검수
                    </option> */}
                  </Input>
                </FormGroup>

              </Col>
            </Row>
          </Form>
          * 필수 입력 항목
        </CardBody>
      </Card>
      <CardFooter className = 'p-0'>
        <Row>
          <Col className="text-right">
            {!props.isEdit && <Button
              color="warning"
              className="mr-1"
              type="button"
              onClick = {props.onCancelButton}
            >
              취소
            </Button>}
            {props.isEdit
            ?<Button
                type="submit"
                color="info"
                className="mr-3"
                onClick = {props.onModifyButton}
                disabled = {!props.availableEdit}
                style = {{cursor: props.availableEdit? 'pointer':'not-allowed'}}
              >
                수정하기
            </Button> 
            :<Button
              type="submit"
              color="primary"
              className="mr-3"
              onClick = {props.onCreateButton}
            >
              생성하기
            </Button>}
          </Col>
        </Row>
      </CardFooter>
    </div>
  );
};
export default InputForm;
