import React, { useState, useContext } from "react";

import { Form, Col, Row, Button, Modal, Alert } from 'react-bootstrap';

// contexts
import DocumentContext from '../context/file-context';

const SelectDirectionModal = (props) => {
  const [alertShow, setAlertShow] = useState(false);
  const docCtx = useContext(DocumentContext);

  const sourceLangChange = (e) => {
    const selected = e.target.value;
    docCtx.setValueForKey(
      props.index, 'sourceLang', selected);

    if (selected === props.targetLang) {
      docCtx.setValueForKey(
        props.index, 
        'isValid', 
        {src: 'invalid.Same', tgt: 'invalid.Same'})
    }
    else {
      if (selected === '한국어' && props.targetLang ==='한국어'){
        docCtx.setValueForKey(props.index,
          'isValid', 
          {...docCtx.items[props.index].isValid, src: 'invalid'})
      }
      else if (selected !== '한국어' && !['한국어', ''].includes(props.targetLang)) {
        docCtx.setValueForKey(props.index,
          'isValid',
          {...docCtx.items[props.index].isValid, src: 'invalid.cannot'})
      }
      else {
        if (props.targetLang) {
          docCtx.setValueForKey(props.index,
            'isValid',
            {...docCtx.items[props.index].isValid, src: 'valid', tgt: 'valid'})
          setAlertShow(false);
        }
        else {
          docCtx.setValueForKey(props.index,
            'isValid',
            {...docCtx.items[props.index].isValid, src: 'valid'})
        }
      }
    }
  };

  const targetLangChange = (e) => {
    const selected = e.target.value;
    docCtx.setValueForKey(props.index,
      'targetLang', selected)
    if (selected === props.sourceLang) {
      docCtx.setValueForKey(
        props.index, 
        'isValid', 
        {src: 'invalid.Same', tgt: 'invalid.Same'})
    }
    else {
      docCtx.setValueForKey(props.index,
        'isValid',
        {...docCtx.items[props.index].isValid, src: 'valid', tgt: 'valid'})
      setAlertShow(false);
    }
  };

  const langugeSelectAlert = (valid, language = '시작') => {
    switch(valid) {
      case 'invalid':
        return `번역할 ${language}언어를 선택하세요.`
      case 'invalid.cannot':
        return '해당 번역은 지원하지 않습니다.'
      case 'invalid.Same':
        return '시작언어와 목표언어가 같습니다.'
      default:
        return `번역할 ${language}언어를 선택하세요.`
    }
  };

  const LanguageSelectOptions = (props) => {
    const options = props.source
      ? props.targetLang === '한국어' || props.targetLang === null
        ? docCtx.LANGUAGEOPTIONS
        : [{key: 0, variableName: 'ko', name: '한국어'}] 
      : props.sourceLang === '한국어' || props.sourceLang === null
        ? docCtx.LANGUAGEOPTIONS
        : [{key: 0, variableName: 'ko', name: '한국어'}];
    
    const currentLang = props.source
      ? props.sourceLang
      : props.targetLang;
    return (
      <React.Fragment>
        {options.map((item, index) => 
          (item.name !== currentLang && (
          <option key = {index}>{item.name}</option>)
        ))}
      </React.Fragment>
    )
  };

  const getDirectionCode = (sourceLang, targetLang) => {
    const sourceLangCode = docCtx.LANGUAGEOPTIONS.find(
      item => item.name === sourceLang).variableName;
    const targetLangCode = docCtx.LANGUAGEOPTIONS.find(
      item => item.name === targetLang).variableName;
    
    return `${sourceLangCode}2${targetLangCode}`
  }

  const checkButtonOnHandler = (e) => {
    if (docCtx.items[props.index].isValid.src === 'valid' && 
        docCtx.items[props.index].isValid.tgt === 'valid') {
      setAlertShow(false);
      props.onHide()
      props.selectDirection(getDirectionCode(props.sourceLang, props.targetLang))
      // console.log()
    }
    else {
      setAlertShow(true);
    }
  };

  const cancelButtonOnHandler = (e) => {
    setAlertShow(false);
    props.onHide();
  }
  
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="SelectDirectionModal"
      centered
    >
      <Modal.Body className='pb-1'>
        <h4>번역 방향</h4>
        <Row>
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label 
              className="font-weight-bold mb-0"
            >
              시작 언어
            </Form.Label>
            <Form.Select 
              className='m-1'
              style = {{width: 'inherit'}}
              onChange = {sourceLangChange}
              isInvalid = {docCtx.items[props.index].isValid.src !== 'valid'}
            >
              {props.sourceLang
              ?<option defaultValue>{props.sourceLang}</option>
              :<option defaultValue>선택</option>
              }
              <LanguageSelectOptions 
                source = {true}
                sourceLang = {props.sourceLang}
                targetLang = {props.targetLang}
              />
            </Form.Select>
            <Form.Control.Feedback type ='invalid'>
              {langugeSelectAlert(docCtx.items[props.index].isValid.src)}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label 
              className="font-weight-bold mb-0"
            >
              목표 언어
            </Form.Label>
            <Form.Select 
              className='m-1'
              style = {{width: 'inherit'}}
              onChange = {targetLangChange}
              isInvalid = {docCtx.items[props.index].isValid.tgt !== 'valid'}
            >
              {props.targetLang
              ?<option defaultValue>{props.targetLang}</option>
              :<option defaultValue>선택</option>
              }
              <LanguageSelectOptions 
                source = {false}
                sourceLang = {props.sourceLang}
                targetLang = {props.targetLang}
              />
            </Form.Select>
            <Form.Control.Feedback type ='invalid'>
              {langugeSelectAlert(docCtx.items[props.index].isValid.tgt, '목표')}
            </Form.Control.Feedback>
          </Form.Group>

        </Row>
      </Modal.Body>
      <Modal.Footer className="pl-1 pt-1 pb-1 pr-2">
        <Alert
          variant="danger"
          show = {alertShow}
        >
          번역 방향이 적절하지 않습니다.
        </Alert>
        <Button 
          variant="success"
          size = 'sm' 
          onClick={checkButtonOnHandler}>
          번역
        </Button>
        <Button 
          variant="secondary"
          size = 'sm' 
          onClick={props.onHide}>
          취소
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SelectDirectionModal;