import React from 'react';

import {
  Card, Button, Row, Col
} from "react-bootstrap";
import { UserPlus, Users } from 'react-feather';

const AddTeamArea = (props) => {
  return (
    <div 
      style = {{'textAlign': 'center'}}
      className = 'mb-5'>
    <Row className="pl-5 pr-5 pt-3 pb-3">
      <Col>
        <Users size = '30' className="mt-1 mb-1" />
      </Col>
    </Row>
    <Row>
      <Col className="ml-1 ">
        <h5 className="mb-3">이메일 전송</h5>
        <h6 className="card-subtitle text-muted">프로젝트 참여자를 초대하세요</h6>
      </Col>
    </Row>
      
      <Col>
        <Button
          className='mt-2' 
          variant='primary'
          style = {{
            borderColor:'rgba(0,0,0,0)', 
            cursor: props.currentUserRole === 'PM'
              ?'pointer' :'not-allowed'
          }}
          disabled = {props.currentUserRole !== 'PM'}
          onClick={() => props.setShowAddModal(true)}
        >
          <UserPlus size = '20' className="mt-1 mb-1" />
            &nbsp;&nbsp;멤버 검색
        </Button>
      </Col>
    </div>
  )
};

export default AddTeamArea;

